import React, { useState, useEffect } from "react";
import { ReactComponent as ArrowAccoor } from "../../../assests/images/icons/accordionArrow.svg";
import "../BudgetSummary.css";
import IncomeExpenseBudgetModal from "./IncomeExpenseBudget/IncomeExpenseBudgetModal";
import { useNavigate } from "react-router-dom";
import { ReactComponent as OverBudget } from "../../../assests/images/over_budget.svg";
import {
  emojiBackgroundColor,
  lightBackgroundColor,
  progressbarColor,
} from "../../../utilities/Statics";

const OneOffBudget = ({
  oneOffBudgetsData = [],
  mainHeading,
  showFields,
  uuid,
  fetchBudgetData
}) => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const navigate = useNavigate();
  const toggleModal = () => {
    setIsModalOpen(!isModalOpen);
  };

  const handleCardClick = (budgetId) => {
    navigate(
      `/BudgetSummary/${uuid}/budget-details/oneoff/${budgetId}?period=monthly`
    );
  };

  return (
    <div className="col-md-8">
      <div className="budget-breakdown-header">
        <h4>{mainHeading} budgets</h4>
        <div className="col-md-6 right-section">
          <i
            className="fa-solid fa-plus"
            style={{
              marginLeft: "264px",
              width: "40px",
              fontSize: "20px",
              cursor: "pointer",
            }}
            onClick={toggleModal}
          ></i>
        </div>
      </div>

      <IncomeExpenseBudgetModal
        isOpen={isModalOpen}
        toggleModal={toggleModal}
        mainHead={mainHeading}
        fetchBudgetData={fetchBudgetData}
        showFields={showFields}
      />

      {oneOffBudgetsData.monthly && (
        <div className="budget-item-card">
          {oneOffBudgetsData?.monthly?.map((budget, index) => {
            let progress;
            const adjustedAmount =
              budget.amount - budget.total_transaction_amount;
            if (adjustedAmount < 0) {
              progress = 100;
            } else {
              progress = (adjustedAmount / budget.amount) * 100;
              progress = progress > 100 ? 100 : progress;
            }

            return (
              <div
                key={index}
                className="budget-item"
                onClick={() => handleCardClick(budget.uuid)}
                style={{ cursor: "pointer" }}
              >
                <div className="budget-label">
                  <div className="pay-budget-sec">
                    <div
                      className={`budget-icon ${emojiBackgroundColor(
                        budget.category_colour
                      )}`}
                      style={
                        {
                          // backgroundColor: "#D3D3D3",
                        }
                      }
                    >
                      {budget.emoji}
                    </div>
                    <div>
                      <h6>{budget.name}</h6>
                      <h4>${budget.amount}</h4>
                    </div>
                  </div>
                  <p className="header-p" style={{ cursor: "pointer" }}>
                    <div className="account-number inter text-16px">
                      <div className="button-blck">
                        <ArrowAccoor />
                      </div>
                    </div>
                  </p>
                </div>

                <div className="budget-remaining">
                  ${Math.max(0, adjustedAmount)} remaining
                </div>

                <div
                  className={`budget-progress-container ${lightBackgroundColor(
                    budget.category_colour
                  )}`}
                  // style={{ backgroundColor: "#D3D3D3" }}
                >
                  <div
                    className={`budget-progress ${progressbarColor(
                      budget.category_colour
                    )}`}
                    style={{
                      width: `${progress}%`,
                      // backgroundColor: `${budget.category_colour}`,
                    }}
                  ></div>
                </div>
              </div>
            );
          })}
        </div>
      )}
    </div>
  );
};

export default OneOffBudget;
