import React, { useEffect, useState } from "react";
import "react-datepicker/dist/react-datepicker.css";
import "../AddIncome/addIncome.css";
import "../ChangePassword/index.css";
import "./index.css";
import moment from "moment";
import { useForm, Controller } from "react-hook-form";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import PopupColorModal from "../../Popup/PopupColorModel";
import EmojiPicker from "emoji-picker-react";

const schema = yup.object().shape({
  name: yup.string().required("Name is required"),
});

const AddCategoriesModal = ({
  modalOpen,
  closeModal,
  addNewCategory,
  transactionType,
}) => {
  const [colour, setColour] = useState("Red");
  const [isPopupOpenColor, setPopupOpenColor] = useState(false);
  const [chosenEmoji, setChosenEmoji] = useState("😁");
  const [showPicker, setShowPicker] = useState(false);

  const togglePopupColor = () => setPopupOpenColor(!isPopupOpenColor);

  const {
    control,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
    defaultValues: {
      name: "",
    },
  });

  const onSubmit = (data) => {
    const formatedData = {
      ...(transactionType === "Expense"
        ? { expenseName: data?.name }
        : { incomeName: data?.name }),
      emoji: chosenEmoji,
      colour: colour,
    };
    addNewCategory(formatedData, transactionType);
    closeModal();
  };

  const handleOptionColorClick = (option) => {
    setColour(option);
    setPopupOpenColor(false);
  };

  const handleEmojiClick = (event) => {
    const { emoji } = event;
    setChosenEmoji(emoji);
    setShowPicker(false);
  };

  const colorCss = (color) => {
    switch (color) {
      case "Dark green":
        return "dark-green-color";
      case "Red":
        return "red-color";
      case "Yellow":
        return "yellow-color";
      case "Blue":
        return "blue-color";
      case "Purple":
        return "purple-color-color";
      case "Orange":
        return "orange-color";
      case "Pink":
        return "pink-color";
      case "Light blue":
        return "light-blue-color";
      case "Lime green":
        return "lime-green-color";
      case "Dark red":
        return "dark-red-color";
      case "Green":
        return "green-color";
      case "Dark blue":
        return "dark-blue-color";
      default:
        return "green";
    }
  };

  useEffect(() => {
    if (!modalOpen) {
      reset({
        name: "",
      });
    }
  }, [modalOpen, reset]);

  return (
    <div className={`modal-background ${modalOpen ? "open-modal" : ""} `}>
      <div className="modal-content add-modal-width">
        <button onClick={closeModal} className="close-pass-button">
          <i class="fa fa-times"></i>
        </button>
        <form onSubmit={handleSubmit(onSubmit)}>
          <div className="">
            <>
              <div className="d-flex justify-content-between">
                <div className="fs-3">
                  <span className="bold black password-heading">
                    New {transactionType.toLowerCase()} category
                  </span>
                  <p className="trans-modal-para">
                    Enter your details for your new custom{" "}
                    {transactionType.toLowerCase()} category.
                  </p>
                </div>
              </div>
              <div className="cash-account-box shadow mt-2">
                <div className="d-flex justify-content-between">
                  <div className="fw-bold">Name</div>
                  <div className="d-flex flex-column">
                    <div className="account-input">
                      <Controller
                        name="name"
                        control={control}
                        render={({ field }) => (
                          <input
                            type="text"
                            {...field}
                            style={{ width: "266px" }}
                          />
                        )}
                      />
                    </div>
                    {errors.name && (
                      <span className="error-message mt-1">
                        {errors?.name?.message}
                      </span>
                    )}
                  </div>
                </div>
                <div className="d-flex justify-content-between align-items-center mt-3 mb-3">
                  <div className="fw-bold">Personalised colour</div>
                  <div className="color-option">
                    <div className="dropdown">
                      <button
                        type="button"
                        className={`group-button ${colorCss(
                          colour
                        )} popoup-color popup-btn-width`}
                        onClick={togglePopupColor}
                        aria-haspopup="true"
                        aria-expanded={isPopupOpenColor}
                      >
                        {colour ? colour : "Red"}
                      </button>

                      {isPopupOpenColor && (
                        <PopupColorModal
                          isOpen={isPopupOpenColor}
                          onClose={togglePopupColor}
                          title="Date Range"
                          options={[
                            "Red",
                            "Yellow",
                            "Blue",
                            "Purple",
                            "Orange",
                            "Pink",
                            "Light blue",
                            "Litgh green",
                            "Green",
                          ]}
                          selectedOption={colour}
                          handleOptionClick={handleOptionColorClick}
                        />
                      )}
                    </div>
                  </div>
                </div>
                <div className="d-flex justify-content-between">
                  <div className="fw-bold">Personalised emoji</div>
                  <div className="d-flex flex-column">
                    <div
                      className="py-2 inter add-goal mx-4 show-emoji"
                      onClick={() => setShowPicker(!showPicker)}
                      style={{ width: "150px" }}
                    >
                      {chosenEmoji}
                    </div>
                    {showPicker && (
                      <div className="category-emoji-container">
                        <EmojiPicker onEmojiClick={handleEmojiClick} />
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </>
          </div>
          <div class="modal-popup-btn-wrp">
            <button
              className="change-password-btn cursor-pointer"
              type="submit"
              //   onClick={submitOtp}
            >
              Add category
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default AddCategoriesModal;
