import axios from "axios";
import React, { useCallback, useEffect, useRef, useState } from "react";
import { Link, useParams } from "react-router-dom";
import { toast, ToastContainer } from "react-toastify";
import Profile from "../../assests/images/icons/image.png";
import { ReactComponent as Search } from "../../assests/images/icons/Search.svg";
import Shape from "../../assests/images/icons/shape.png";
import { ReactComponent as PLusIcon } from "../../assests/images/plus.svg";
import MainSidebar from "../MainSidebar";
import PopupInfoModal from "../Popup/PopupInfoModel";
import weburls from "../Weburls/weburls";
import moment from "moment";
import AddTransactionModal from "../ModalForm/TransactionModals/AddTransactionModal";
import {
  groupTransactionsByDate,
  groupTransactionsByMonth,
  parseMerchantsData,
  parseMerchantsTransactions,
  parseYourAccountBalancesData,
} from "../../utilities/helper";
import { TransactionListing } from "../Transactions/TransactionListing";

import OverlayContainer from "../OverlayContainer/OverlayContainer";
import { Loading } from "../Loading/Loading";
import TransactionViewModal from "../ModalForm/TransactionModals/TransactionViewModal";
import PageTitle from "../PageTitle";
import { MerchantListing } from "./MerchantListing";
import MerchantDetail from "../ModalForm/MerchantDetail/MerchantDetail";
import AddCategoriesModal from "../ModalForm/TransactionModals/AddCategoriesModal";
import {
  dateOptions,
  merchantCategories,
  merchantCategoriesOpts,
  mertchantSortOptions,
  mertchantSortOpts,
  moduleInfoPopUpData,
} from "../../utilities/Statics";
import UserImageInfoToggle from "../UserImageInfoToggle";

const Transactions = () => {
  const { uuid } = useParams();
  const sidebarRef = useRef(null);

  const [merchant, setMerchants] = useState([]);
  const [category, setCategory] = useState([]);

  const [merchantTypeFilter, setMerchantTypeFilter] = useState("All");
  const [incomeType, setIcomeType] = useState("All");

  const [categoryFilter, setCategoryFilter] = useState("All");
  const [filterBySpend, setFilterBySpend] = useState("All");
  const [isPopupOpenInfo, setPopupOpenInfo] = useState(false);
  const [merchantCategorized, setMerchantCategorized] = useState(0);
  const [merchantUnCategorized, setMerchantUnCategorized] = useState(0);
  const [selectedExpenseCategories, setSelectedExpenseCategories] = useState(
    []
  );
  const [selectedCategories, setSelectedCategories] = useState([]);
  const [sortedBy, setSortedBy] = useState("");
  const [selectedIncomeCategories, setSelectedIncomeCategories] = useState([]);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [data, setData] = useState([]);
  const togglePopupInfo = () => setPopupOpenInfo(!isPopupOpenInfo);
  const [accountData, setAccountData] = useState([]);
  const [currentMonthGrouped, setCurrentMonthGrouped] = useState({});
  const [remainingMonthsGrouped, setRemainingMonthsGrouped] = useState({});
  const [loading, setLoading] = useState(false);
  const [spliModalData, setSplitModalData] = useState();
  const [selectedMerchant, setSelectedMerchant] = useState({});
  const [modals, setModals] = useState({
    merchantDetails: false,
    viewTransaction: false,
    addCategory: false,
  });
  const [selectedTransaction, setSelectedTransaction] = useState({});

  const [merchantTransactions, setMerchantTransactions] = useState([]);
  const [search, setSearch] = useState("");

  const openModal = (modal) => {
    setModals({ ...modals, [modal]: true });
  };
  const closeModal = (modal) => {
    setModals({ ...modals, [modal]: false });
  };

  const handleTransactionClick = (data) => {
    setSelectedTransaction(data);
    openModal("viewTransaction");
  };

  const handleMerchantDetailModal = (data) => {
    setSelectedMerchant(data);
    fetchMerchantTransactions();
  };

  const getMerchantsByClientBy = useCallback(async () => {
    const data = {
      categoryIds: [...selectedCategories],
      isSort: sortedBy,
      categories: filterBySpend,
    };
    try {
      const response = await axios.post(
        `${weburls.Get_Merchats_By_ClientId}${uuid}?name=${""}`,
        data
      );
      const parsedData = parseMerchantsData(response?.data?.merchants);
      setMerchants(parsedData);
      setMerchantUnCategorized(response?.data?.merchantUncategorized);
      setMerchantCategorized(response?.data?.merchantCategorized);
    } catch (err) {
      toast.error(err);
    }
  }, [uuid, selectedCategories, sortedBy, filterBySpend]);

  const fetchMerchantTransactions = async () => {
    setLoading(true);
    const { merchantId } = selectedMerchant;
    try {
      const response = await axios.get(
        `${weburls.Get_Transaction_By_Merchants_Id}${uuid}/${merchantId}/100/1?name=`
      );
      const { remainingMonths, thisMonth } = response.data.data.transactions;
      const combineTransactionData = [...remainingMonths, ...thisMonth];
      setMerchantTransactions(combineTransactionData);
      openModal("merchantDetails");
      setLoading(false);
    } catch (error) {
      setLoading(false);
    }
  };
  const getCategory = useCallback(async () => {
    try {
      const response = await axios.get(`${weburls.Get_All_Categories}${uuid}`);
      setCategory(response?.data?.data?.Expense);
    } catch (err) {
      toast.error(err);
    }
  }, [uuid]);

  useEffect(() => {
    getMerchantsByClientBy();
    getCategory();

    // fetchCashAccount();
  }, [getMerchantsByClientBy, getCategory]);

  const splitTransaction = async (splits, transactionId, type) => {
    try {
      const response = await axios.post(
        `${weburls.Split_Transaction}${uuid}/${transactionId}/${type}`,
        splits
      );
      if (response?.data?.code === 1) {
        toast.success(`${response?.data?.message}`);
      }
      fetchMerchantTransactions();
      closeModal("viewTransaction");
    } catch (error) {
      toast.error("Something went wrong while spliting transaction");
    }
  };

  const updateMerchantCategory = async (transactionId, categoryId) => {
    setLoading(true);
    const data = {
      merchantUuid: transactionId,
      categoryId: categoryId,
    };
    try {
      const response = await axios.post(
        `${weburls.Update_Merchant_Category}${uuid}`,
        data
      );
      toast.success(`${response?.data?.message}`);
      getMerchantsByClientBy();
      setLoading(false);
    } catch (error) {
      toast.error("Something went wrong while upadating category...");
      setLoading(false);
    }
  };

  const addNewCategory = async (data) => {
    try {
      const response = await axios.post(
        `${weburls.Add_Expense_Category}${uuid}`,
        data
      );
      if (response?.data?.code === 1) {
        toast.success(`${response?.data?.message}`);
      }
      if (response?.data?.code === 0) {
        toast.error(`${response?.data?.message}`);
      }
      getCategory();
    } catch (error) {
      toast.error("Somthing went wronge while adding expense category");
    }
  };

  const handleTagClick = (id, type) => {
    setSelectedCategories((prev) =>
      prev.includes(id) ? prev.filter((tag) => tag !== id) : [...prev, id]
    );
  };

  return (
    <>
      <div className="container-scroller" ref={sidebarRef}>
        <ToastContainer position="top-right" autoClose={5000} closeOnClick />
        <div className="container-fluid">
          <div className="appmain mainsidebar main-wrapper">
            <MainSidebar uuid={uuid} />
            <div className="app-main__outer your-merchants-main-wrapper">
              <div className="row">
                <PageTitle
                  title={"Your Merchants"}
                  description={ 
                    "Group your spending into categories to get an more confidence with your expenses."
                  }
                />
                <UserImageInfoToggle
                  isPopupOpenInfo={isPopupOpenInfo}
                  togglePopupInfo={togglePopupInfo}
                  title={moduleInfoPopUpData.merchants.title}
                  description={moduleInfoPopUpData.merchants.description}
                />
              </div>
              <div className="d-flex gap-4 mt-5">
                <div className="account-detail-left">
                  <div className="d-flex align-items-center justify-content-between">
                    <span className="bold heading-new">Merchants</span>
                    <div className="d-flex gap-1">
                      <span className="transaction-count transaction-count-green d-flex align-items-center justify-content-center">
                        {merchantCategorized}
                      </span>
                      <span className="transaction-count transaction-count-red  d-flex align-items-center justify-content-center">
                        {merchantUnCategorized}
                      </span>
                    </div>
                  </div>
                  <div className="account-detail-left-wrapper">
                    {merchant.map((merchant) => (
                      <MerchantListing
                        key={merchant.merchantsUuid}
                        merchant={merchant}
                        onClick={handleMerchantDetailModal}
                        merchantCategories={category}
                        updateMerchantCategory={updateMerchantCategory}
                        addNewCategory={addNewCategory}
                      />
                    ))}
                  </div>
                </div>
                <div className="account-detail-right">
                  <div
                    className="
                 search-spacing filter-merchants-wrp-side"
                  >
                    <div className="row">
                      <div className="col-md-7 col-lg-12">
                        <div className="bold heading-new">
                          Search for merchants
                        </div>
                        <div>
                          <div className="search-merchant your-merchant-search">
                            <form>
                              <input
                                type="text"
                                value={search}
                                className="search search-color-white"
                                onChange={(e) => setSearch(e.target.value)}
                              />
                              <Search className="search-merchant" />
                            </form>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="d-flex justify-content-between mt-3">
                      <div className="bold heading-new">
                        Filter & sort merchants
                      </div>
                    </div>

                    <div className="merchants-background-color  mt-3">
                      <div className="bold live-connection">
                        Merchant categories
                      </div>
                      <div className="group-btn green-btn">
                        <div className="group-btn green-btn ">
                          <div className="button-blck">
                            <button
                              className={`group-button ${
                                selectedCategories.length <= 0 ? "green" : ""
                              }`}
                              onClick={() => setSelectedCategories([])}
                            >
                              All
                            </button>
                          </div>
                          {category &&
                            category?.length > 0 &&
                            category.map((it) => (
                              <div
                                key={it?.userCategoryId}
                                className="button-blck"
                              >
                                <div
                                  className={`icon-tag ${
                                    selectedCategories.includes(
                                      it.userCategoryId
                                    )
                                      ? "green"
                                      : ""
                                  }`}
                                  onClick={() =>
                                    handleTagClick(it.userCategoryId)
                                  }
                                >
                                  <div
                                    className="icon-transaction-img  "
                                    style={{
                                      backgroundColor: it?.colour,
                                    }}
                                  >
                                    {it?.emoji}
                                  </div>
                                  <div className="content">
                                    <h6
                                      className={`icon-content ${
                                        selectedCategories.includes(
                                          it.userCategoryId
                                        )
                                          ? "icon-white"
                                          : ""
                                      }`}
                                    >
                                      {it?.name}
                                    </h6>
                                  </div>
                                </div>
                              </div>
                            ))}
                        </div>
                      </div>
                      <div className="bold live-connection mt-3">
                        Categories
                      </div>
                      <div className="account-date-group green-btn">
                        {merchantCategoriesOpts.map((option) => (
                          <div className="button-blck" key={option}>
                            <button
                              className={`account-date-btn ${
                                filterBySpend === option ? "green" : ""
                              }`}
                              onClick={() => setFilterBySpend(option)}
                            >
                              {option}
                            </button>
                          </div>
                        ))}
                      </div>
                      <div className="bold live-connection mt-3">
                        Sort Merchants
                      </div>
                      <div className="account-date-group green-btn">
                        {mertchantSortOpts.map((option) => (
                          <div className="button-blck" key={option}>
                            <button
                              className={`account-date-btn ${
                                sortedBy === option ? "green" : ""
                              }`}
                              onClick={() => setSortedBy(option)}
                            >
                              {option}
                            </button>
                          </div>
                        ))}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            {modals.merchantDetails && !loading && (
              <MerchantDetail
                modalOpen={modals.merchantDetails}
                closeModal={closeModal}
                merchant={selectedMerchant}
                merchantTransactions={merchantTransactions}
                handleTransactionClick={handleTransactionClick}
              />
            )}
            {modals.viewTransaction && (
              <TransactionViewModal
                modalOpen={modals.viewTransaction}
                data={selectedTransaction}
                closeModal={closeModal}
                splitTransaction={splitTransaction}
              />
            )}

            {loading && (
              <OverlayContainer>
                <div>
                  <Loading />
                </div>
              </OverlayContainer>
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export default Transactions;
