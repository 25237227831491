import React, { useState } from "react";
import PopOver from "./Popover";
import { categoryBackgroundCss } from "../../utilities/Statics";

const CategoryWithPopover = ({
  emoji,
  name,
  colour,
  style,
  uniqueID,
  amount,
  updateCategory,
  expenseCategories,
  incomeCategories,
  updatingForSplitTransaction=false,
  onCategoryChangeForSplitting,
  showAddCategory,
  handleAddCategory,
  updateCategoriesInBulk,
  isSelectionMode,
  isPendingTransaction=false
}) => {
  const [popoverOpen, setPopoverOpen] = useState(false);

  const togglePopover = () => {
    setPopoverOpen((prev) => !prev);
  };
  const isHexColor = (color) => /^#([0-9A-Fa-f]{3}){1,2}$/.test(color);
  
  return (
    <div>
      <div
        className="button-blck"
        id={`Popover-${uniqueID}`}
        onClick={() => togglePopover(uniqueID)}
      >
        <div className="icon-tag" style={style}>
          <div
             className={`icon-transaction-img ${isHexColor(colour) ? '' : categoryBackgroundCss(colour)}`}
             style={isHexColor(colour) ? { backgroundColor: colour } : {}}
          >
            {emoji}
          </div>
          <div className="content">
            <h6 className="icon-content">{name}</h6>
          </div>
        </div>
      </div>



      {popoverOpen && (
        <PopOver
          categories={amount < 0 ? expenseCategories : incomeCategories}
          transactionId={uniqueID}
          togglePopover={togglePopover}
          popoverOpen={popoverOpen}
          transactionType={amount < 0 ? "Expense" : "Income"}
          updateCategory={updateCategory}
          updatingForSplitTransaction={updatingForSplitTransaction}
          onCategoryChangeForSplitting={onCategoryChangeForSplitting}
        />
      )}
    </div>
  );
};

export default CategoryWithPopover;
