import React, { useEffect, useState } from "react";
import "react-datepicker/dist/react-datepicker.css";
import "../AddIncome/addIncome.css";
import "../ChangePassword/index.css";
import "./index.css";
import Logo from "../../../assests/images/icons/Black Logo 1.png";

import moment from "moment";
import { useForm, Controller } from "react-hook-form";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import Drawer from "../../Drawer";
const schema = yup.object().shape({
  name: yup.string().required("Name is required"),
  date: yup.date().required("Date is required").nullable(),
  amount: yup
    .number()
    .required("Amount is required")
    .typeError("Amount must be a number"),
  account: yup.string().required("Account is required"),
});
const AddTransactionModal = ({
  modalOpen,
  closeModal,
  addTransaction,
  accountData,
}) => {
  const [transactionType, setTransactionType] = useState("Income");
  const [selectedAccountData, setSelectedAccountData] = useState({
    accountName: "",
    avatar: null,
    id: "",
  });

  const [isDrawerOpen, setIsDrawerOpen] = useState(false);

  const openDrawer = () => setIsDrawerOpen(!isDrawerOpen);
  const closeDrawer = () => setIsDrawerOpen(false);
  const {
    control,
    handleSubmit,
    reset,
    setValue,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
    defaultValues: {
      name: "",
      date: moment().format("YYYY-MM-DD"),
      amount: "",
      transactionType: "",
      account: "",
    },
  });

  const onSubmit = (data) => {
    if (!selectedAccountData.id) {
      return;
    }
    addTransaction({ ...data, ...selectedAccountData, transactionType });
    closeModal("addTransaction")
    setIsDrawerOpen(false)
  };

  const handleAccountToggle = (data) => {
    setSelectedAccountData(data);
    setValue("account","mksmlksamlksmdk")
  };

  useEffect(() => {
    if (!modalOpen) {
      reset({
        name: "",
        date: moment().format("YYYY-MM-DD"),
        amount: "",
        transactionType: "",
        account: "",
      });
      setSelectedAccountData({});
      setIsDrawerOpen(false);
    }
  }, [modalOpen, reset]);

  return (
    <div className={`modal-background ${modalOpen ? "open-modal" : ""} `}>
      <div className="modal-content add-modal-width">
        <Drawer
          isOpen={isDrawerOpen}
          onClose={closeDrawer}
          accountData={accountData}
          handleAccountToggle={handleAccountToggle}
          selectedAccount={selectedAccountData?.id}
        />
        <button
          onClick={() => closeModal("addTransaction")}
          className="close-pass-button"
        >
          <i class="fa fa-times"></i>
        </button>
        <form onSubmit={handleSubmit(onSubmit)}>
          <div className="">
            <>
              <div className="d-flex justify-content-between">
                <div className="fs-3">
                  <span className="bold black password-heading">
                    Add&nbsp;transaction
                  </span>
                  <p className="trans-modal-para">
                    Enter in your details for your transaction.
                  </p>
                </div>
                <button
                  type="submit"
                  className="add-transaction-btn cursor-pointer me-4"
                >
                  Add&nbsp;transaction
                </button>
              </div>
              <div className="black account-bal-title mt-4">Enter Details</div>
              <div className="cash-account-box shadow">
                <div className="d-flex justify-content-between">
                  <div className="fw-bold">Name</div>
                  <div className="d-flex flex-column">
                    <div className="account-input">
                      <Controller
                        name="name"
                        control={control}
                        render={({ field }) => (
                          <input
                            type="text"
                            {...field}
                            style={{ width: "266px" }}
                          />
                        )}
                      />
                    </div>
                    {errors.name && (
                      <span className="error-message mt-1">
                        {errors?.name?.message}
                      </span>
                    )}
                  </div>
                </div>
                <div className="d-flex justify-content-between mt-3">
                  <div className="fw-bold">Date</div>
                  <div className="d-flex flex-column">
                    <div className="account-input">
                      <Controller
                        name="date"
                        control={control}
                        render={({ field }) => (
                          <input
                            type="date"
                            {...field}
                            style={{ border: "none", width: "266px" }}
                          />
                        )}
                      />
                    </div>
                  </div>
                  {errors.date && (
                    <span className="error-message mt-1">
                      {errors?.name?.message}
                    </span>
                  )}
                </div>
                <div className="d-flex justify-content-between mt-3">
                  <div className="fw-bold">Amount</div>
                  <div className="d-flex flex-column">
                    <div className="account-input">
                      <Controller
                        name="amount"
                        control={control}
                        render={({ field }) => (
                          <input
                            type="number"
                            {...field}
                            style={{ width: "266px" }}
                          />
                        )}
                      />
                    </div>
                    {errors.amount && (
                      <span className="error-message mt-1">
                        {errors?.amount?.message}
                      </span>
                    )}
                  </div>
                </div>

                <div className="d-flex justify-content-between align-items-center">
                  <div className="fw-bold">Transaction type</div>
                  <div className="account-input">
                    <div
                      className={`account-select mt-3 cursor-pointer ${
                        transactionType === "Expense" && "account-select-income"
                      }`}
                      name="accountType"
                      onClick={() =>
                        setTransactionType(
                          transactionType === "Expense" ? "Income" : "Expense"
                        )
                      }
                    >
                      <span className="d-flex justify-content-center">
                        {transactionType}
                      </span>
                    </div>
                  </div>
                </div>

                <div className="d-flex justify-content-between align-items-center">
                  <div className="fw-bold">Account</div>
                  <div className="d-flex flex-column">
                    <div className="account-input d-flex justify-content-end mt-3 align-items-center">
                      <button
                        type="button"
                        className={`group-button  popoup-color cursor-pointer show-drawer`}
                        style={{ background: "#0477B5" }}
                        aria-haspopup="true"
                        onClick={openDrawer}
                      >
                        {selectedAccountData?.accountName}
                      </button>
                      <img
                        className="goal-account-img"
                        src={selectedAccountData?.avatar ?? Logo}
                        alt=""
                      />
                    </div>
                    {errors.account && (
                      <span className="error-message mt-1">
                        Account is required
                      </span>
                    )}
                  </div>
                </div>
              </div>
            </>
          </div>
        </form>
      </div>
    </div>
  );
};

export default AddTransactionModal;
