import React, { useRef, useState } from "react";
import Profile from "../../assests/images/icons/image.png";
import { useParams } from "react-router-dom";
import MainSidebar from "../MainSidebar";
import { Doughnut } from "react-chartjs-2";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
  ArcElement,
} from "chart.js";
import "./Moneyinsights.css";
import ChartDataLabels from "chartjs-plugin-datalabels";
import BarChartSection from "./BarChartSection";
import CategoryBreakdownSection from "./CategoryBreakdownSection";
import ExpenseHistorySection from "./ExpenseHistorySection";
import Logo from "../../assests/images/icons/Black Logo 1.png";
import { AkahuAccountCard } from "../AkahuAccountCard";
import NoDataFound from "../NoDataFound";
import { AccordionMoneyInsights } from "./AccordionMoneyInsights";

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
  ArcElement,
  ChartDataLabels
);

const centerTextPlugin = {
  id: "centerTextPlugin",
  afterDraw(chart) {
    const {
      ctx,
      chartArea: { width, height },
    } = chart;
    const totalValue = "$4,964";

    ctx.save();
    ctx.font = "16px Arial";
    ctx.fillStyle = "#888";
    ctx.textAlign = "center";
    ctx.fillText("Total Expenses", width / 2, height / 2 - 10);

    ctx.font = "bold 28px Arial";
    ctx.fillStyle = "#000";
    ctx.fillText(totalValue, width / 2, height / 2 + 20);
    ctx.restore();
  },
};

const Moneyinsights = () => {
  const sidebarRef = useRef(null);
  const { uuid } = useParams();
  const [activeTab, setActiveTab] = useState("Expenses");
  const [isPopupOpenMonthly, setPopupOpenMonthly] = useState(false);
  const [selectedMonthlyOption, setSelectedMonthlyOption] =
    useState("Last 12 months");
  const [isPopupOpenTopExpenses, setPopupOpenTopExpenses] = useState(false);
  const [selectedTopExpensesOption, setSelectedTopExpensesOption] =
    useState("This Week");
  const [topFiveExpense, setTopFiveExpense] = useState({
    thisWeek: [],
    lastWeek: [],
    thisQuarter: [],
    lastQuarter: [],
    thisMonth: [],
    lastMonth: [],
    thisYear: [],
  });
  const [topFiveExpenseFilter, setTopFiveExpenseFilter] = useState([]);
  const [popoverOpen, setPopoverOpen] = useState({});
  const [showAddCategory, setShowAddCategory] = useState(false);
  const [openCard, setOpenCard] = useState(null);

  const handleCardToggle = (index) => {
    setOpenCard(openCard === index ? null : index);
  };

  const togglePopupMonthly = () => setPopupOpenMonthly(!isPopupOpenMonthly);
  const togglePopupTopExpenses = () =>
    setPopupOpenTopExpenses(!isPopupOpenTopExpenses);

  const handleToggle = (tab) => {
    setActiveTab(tab);
  };

  const dataBarChart = {
    labels: [
      "Sep",
      "Oct",
      "Nov",
      "Dec",
      "Jan",
      "Feb",
      "Mar",
      "Apr",
      "May",
      "Jun",
      "Jul",
      "Aug",
    ],
    datasets: [
      {
        label: "Expenses",
        data: [500, 1000, 700, 300, 1200, 600, 1500, 400, 800, 200, 600, 1300],
        backgroundColor: activeTab === "Expenses" ? "#d04f4f" : "#479b88",
        borderColor: activeTab === "Expenses" ? "#d04f4f" : "#479b88",
        borderWidth: 1,
        borderRadius: 5,
        barThickness: 40,
        maxBarThickness: 40,
        minBarLength: 2,
      },
    ],
  };

  const options = {
    responsive: true,
    maintainAspectRatio: false,
    plugins: {
      legend: {
        display: false,
      },
      tooltip: {
        enabled: true,
        backgroundColor: "#333",
        titleColor: "#fff",
      },
      datalabels: {
        display: false,
      },
    },
    scales: {
      x: {
        grid: {
          display: false,
        },
        ticks: {
          font: {
            size: 14,
            family: "Arial, sans-serif",
          },
        },
      },
      y: {
        grid: {
          display: false,
        },
        border: {
          display: false,
        },
        beginAtZero: true,
        ticks: {
          display: false,
        },
      },
    },
  };

  const doughnutData = {
    labels: ["Rent", "Insurance", "Appearance", "Car", "Education"],
    datasets: [
      {
        data: [2, 12, 18, 7, 56],
        backgroundColor: [
          "#FAE588",
          "#ACD3A0",
          "#E3C29D",
          "#E79E9A",
          "#DAB2FF",
        ],
        hoverBackgroundColor: [
          "#FAE588",
          "#ACD3A0",
          "#E3C29D",
          "#E79E9A",
          "#DAB2FF",
        ],
        borderWidth: 0,
      },
    ],
  };

  const doughnutOptions = {
    responsive: true,
    cutout: "70%",
    plugins: {
      legend: {
        display: true,
        position: "bottom",
        labels: {
          usePointStyle: true,
          pointStyle: "circle",
          font: {
            size: 14,
          },
          color: "#333",
        },
      },
      tooltip: {
        enabled: true,
        backgroundColor: "#333",
        titleColor: "#fff",
      },
      datalabels: {
        color: "#fff",
        formatter: (value) => `${value}%`,
        font: {
          size: 14,
          weight: "bold",
        },
        align: "center",
        anchor: "center",
      },
    },
  };
  const handleOptionMonthlyClick = (option) => {
    setSelectedMonthlyOption(option);
    setPopupOpenMonthly(false);
  };

  const handleOptionTopExpensesClick = (option) => {
    setSelectedTopExpensesOption(option);
    let data = [];
    if (topFiveExpense) {
      // Ensure topFiveExpense is not undefined
      switch (option) {
        case "This Week":
          data = topFiveExpense.thisWeek ?? [];
          break;
        case "Last Week":
          data = topFiveExpense.lastWeek ?? [];
          break;
        case "This Quarter":
          data = topFiveExpense.thisQuarter ?? [];
          break;
        case "Last Quarter":
          data = topFiveExpense.lastQuarter ?? [];
          break;
        case "This Month":
          data = topFiveExpense.thisMonth ?? [];
          break;
        case "Last Month":
          data = topFiveExpense.lastMonth ?? [];
          break;
        case "This Year":
          data = topFiveExpense.thisYear ?? [];
          break;
        default:
          data = [];
          break;
      }
    }
    setTopFiveExpenseFilter(data);
    setPopupOpenTopExpenses(false);
  };

  const togglePopover = (transactionId) => {
    setPopoverOpen((prevState) => ({
      ...prevState,
      [transactionId]: !prevState[transactionId],
    }));
  };

  const handleAddCategory = (transactionId) => {
    // Close the popover when the modal is triggered
    setPopoverOpen((prevState) => ({
      ...prevState,
      [transactionId]: false,
    }));
    setShowAddCategory(!showAddCategory);
  };

  const updateTransactionCategory = (transactionId, userCategoryId, type) => {
    // Add logic for updating category in transaction
  };

  const transaction = {
    transactionUuid: 1,
    amount: 100,
  }; // Dummy data for transaction

  const incomeCategories = [
    { userCategoryId: 1, name: "Salary", emoji: "💰", colour: "green" },
  ]; // Dummy data
  const expenseCategories = [
    { userCategoryId: 2, name: "Groceries", emoji: "🛒", colour: "red" },
  ]; // Dummy data

  const dummyData = [
    {
      avatar: Logo,
      accountName: "Rent",
      accountNumber: "Up 10% vs last week",
      value: 1200.87, // Remove the "$" and comma, pass number directly
      details: [
        { label: "Attractions", value: 250.87 },
        { label: "Child clothing", value: 220.87 },
        { label: "Clothing repair", value: 200.87 },
        { label: "Apparel stores", value: 195.87 },
      ],
    },
    {
      avatar: Logo,
      accountName: "Insurance",
      accountNumber: "Up 10% vs last week",
      value: 480.9, // No "$" symbol
      details: [
        { label: "Attractions", value: 250.87 },
        { label: "Child clothing", value: 220.87 },
        { label: "Clothing repair", value: 200.87 },
        { label: "Apparel stores", value: 195.87 },
      ],
    },
    {
      avatar: Logo,
      accountName: "Insurance",
      accountNumber: "Up 10% vs last week",
      value: 480.9, // No "$" symbol
      details: [
        { label: "Attractions", value: 250.87 },
        { label: "Child clothing", value: 220.87 },
        { label: "Clothing repair", value: 200.87 },
        { label: "Apparel stores", value: 195.87 },
      ],
    },
    {
      avatar: Logo,
      accountName: "Insurance",
      accountNumber: "Up 10% vs last week",
      value: 480.9, // No "$" symbol
      details: [
        { label: "Attractions", value: 250.87 },
        { label: "Child clothing", value: 220.87 },
        { label: "Clothing repair", value: 200.87 },
        { label: "Apparel stores", value: 195.87 },
      ],
    },
    {
      avatar: Logo,
      accountName: "Insurance",
      accountNumber: "Up 10% vs last week",
      value: 480.9, // No "$" symbol
      details: [
        { label: "Attractions", value: 250.87 },
        { label: "Child clothing", value: 220.87 },
        { label: "Clothing repair", value: 200.87 },
        { label: "Apparel stores", value: 195.87 },
      ],
    },
    {
      avatar: Logo,
      accountName: "Appearance",
      accountNumber: "Up 10% vs last week",
      value: 349.58, // Just the number
      details: [
        { label: "Attractions", value: 250.87 },
        { label: "Child clothing", value: 220.87 },
        { label: "Clothing repair", value: 200.87 },
        { label: "Apparel stores", value: 195.87 },
      ],
    },
  ];

  return (
    <div className="container-scroller" ref={sidebarRef}>
      <div className="container-fluid">
        <div className="appmain mainsidebar main-wrapper">
          <MainSidebar uuid={uuid} />
          <div className="app-main__outer edit-cashflow-container-main">
            <div className="row top-nav">
              <div className="col-md-4 edit-cashflow-title-heading">
                <div className="bold big-head-new">Money insights</div>
                <div className="fst-normal setting_text_color mt-1">
                  Here’s your breakdown of where you spend & earn your money.
                </div>
              </div>
              <div className="col-md-8 right-section back-portal-edit-cashflow">
                <div className="right-section">
                  <div
                    className="modal-actions toggle-button-container"
                    style={{ marginTop: "0px" }}
                  >
                    <button
                      className={`toggle-button ${
                        activeTab === "Expenses" ? "active active-expenses" : ""
                      }`}
                      onClick={() => handleToggle("Expenses")}
                    >
                      Expenses
                    </button>
                    <button
                      className={`toggle-button ${
                        activeTab === "Income" ? "active active-income" : ""
                      }`}
                      onClick={() => handleToggle("Income")}
                    >
                      Income
                    </button>
                  </div>
                  <div
                    className="profile-dashboard"
                    style={{ marginTop: "0px" }}
                  >
                    <div className="profile-img">
                      <img src={Profile} alt="profile" />
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <ExpenseHistorySection
              togglePopupMonthly={togglePopupMonthly}
              isPopupOpenMonthly={isPopupOpenMonthly}
              selectedMonthlyOption={selectedMonthlyOption}
              handleOptionMonthlyClick={handleOptionMonthlyClick}
            />

            <BarChartSection
              activeTab={activeTab}
              transaction={transaction}
              togglePopover={togglePopover}
              expenseCategories={expenseCategories}
              incomeCategories={incomeCategories}
              updateTransactionCategory={updateTransactionCategory}
              popoverOpen={popoverOpen}
              showAddCategory={showAddCategory}
              handleAddCategory={handleAddCategory}
              data={dataBarChart}
              options={options}
            />

            <CategoryBreakdownSection
              togglePopupTopExpenses={togglePopupTopExpenses}
              isPopupOpenTopExpenses={isPopupOpenTopExpenses}
              selectedTopExpensesOption={selectedTopExpensesOption}
              handleOptionTopExpensesClick={handleOptionTopExpensesClick}
              heading={"Category breakdown"}
            />

            <div className="row">
              {/* Income Budgets Section */}
              <div className="col-md-4 doughnut-card">
                <Doughnut
                  data={doughnutData}
                  options={doughnutOptions}
                  plugins={[centerTextPlugin]}
                />
              </div>
              <div className="col-md-8">
                <div className="acccount-balances-wrappper-left-side">
                  {dummyData.length > 0 ? (
                    dummyData.map((item, index) => (
                      <AccordionMoneyInsights
                        key={index}
                        avatar={item.avatar}
                        accountName={item.accountName}
                        value={item.value}
                        accountNumber={item.accountNumber}
                        details={item.details}
                        isOpen={openCard === index}
                        onToggle={() => handleCardToggle(index)}
                      />
                    ))
                  ) : (
                    <NoDataFound />
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Moneyinsights;
