import React from 'react';

const PopupRevokeConnections = ({ isOpen, onClose, title, options, selectedOption, handleRevokeConnections }) => {
  return (
    isOpen && (
      <div className="modal-background open-modal">
        <div className="modal-content popup-width">
          <button  onClick={()=> onClose('revokeModal')} className="close-modal-button">
            <i className="fa fa-times"></i>
          </button>
          <div>
            <div className="fs-3 revoke-title-flex">
              <span className="bold black popup-top-heading">{'Are you sure?'}</span>
              <span className="revoke-desc">This cannot be un-done.</span>
            </div>
          </div>
          <button className="revoke-green-btn">Go back</button>
          <button className="revoke-red-btn" onClick={handleRevokeConnections}>Revoke connection</button>
        </div>
      </div>
    )
  );
};

export default PopupRevokeConnections;
