import React from "react";

export const ToggleSwitch = ({value,onChange}) => {
  return (
    <label className="switch">
      <input type="checkbox" checked={value} onChange={onChange} />
      <span className={`switch-slider round`}></span>
    </label>
  );
};
