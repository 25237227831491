import React from "react";

const PageTitle = ({title, description}) => {
  return (
    <div className="col-lg-6">
      <div className="bold big-head-new">{title}</div>
      <div className="fst-normal setting_text_color">
       {description}
      </div>
    </div>
  );
};

export default PageTitle;
