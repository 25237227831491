import axios from "axios";
import React, { useCallback, useEffect, useRef, useState } from "react";
import { useParams } from "react-router-dom";
import { toast } from "react-toastify";
import { ReactComponent as Search } from "../../assests/images/icons/Search.svg";
import { ReactComponent as PLusIcon } from "../../assests/images/plus.svg";
import { ReactComponent as SelectAllIcon } from "../../assests/images/icons/selectall.svg";

import MainSidebar from "../MainSidebar";
import PopupInfoModal from "../Popup/PopupInfoModel";
import PageTitle from "../PageTitle";
import SectionTitle from "../SectionTitle.jsx";
import UserImageInfoToggle from "../UserImageInfoToggle";
import weburls from "../Weburls/weburls";
import AddTransactionModal from "../ModalForm/TransactionModals/AddTransactionModal";
import {
  groupTransactionsByDate,
  groupTransactionsByMonth,
  parseYourAccountBalancesData,
} from "../../utilities/helper";
import { TransactionListing } from "./TransactionListing";
import OverlayContainer from "../OverlayContainer/OverlayContainer";
import { Loading } from "../Loading/Loading";
import TransactionViewModal from "../ModalForm/TransactionModals/TransactionViewModal";
import useDebounce from "../../hooks/useDebouce";
import { dateOptions } from "../../utilities/Statics";
import { isEmpty } from "lodash";
import NoDataFound from "../NoDataFound/index.jsx";
import FilterTags from "../FilterTags/index.jsx";

const Transactions = () => {
  const { uuid } = useParams();
  const sidebarRef = useRef(null);
  const [category, setCategory] = useState([]);
  const [icomecat, setIcomCat] = useState([]);
  const [filterByDate, setFilterByDate] = useState("");
  const [isPopupOpenInfo, setPopupOpenInfo] = useState(false);
  const [selectedIncomeCategories, setSelectedIncomeCategories] = useState([]);
  const togglePopupInfo = () => setPopupOpenInfo(!isPopupOpenInfo);
  const [accountData, setAccountData] = useState([]);
  const [currentMonthGrouped, setCurrentMonthGrouped] = useState({});
  const [remainingMonthsGrouped, setRemainingMonthsGrouped] = useState({});
  const [loading, setLoading] = useState(false);
  const [spliModalData, setSplitModalData] = useState();
  const [totalIncomes, setTotalIncomes] = useState(0);
  const [totalExpense, setTotalExpense] = useState(0);
  const [search, setSearch] = useState("");
  const debouncedSearch = useDebounce(search, 500);
  const [isSelectionMode, setSelectionMode] = useState(false);
  const [selectAll, setSelectAll] = useState(false);
  const [unPress, setUnpress] = useState(false);
  const [categorizedTransactionCount, setCategorizedTransactionCount] =
    useState(false);
  const [unCategorizedTransactionCount, setUnCategorizedTransactionCount] =
    useState(false);

  const [page, setPage] = useState(1);
  const [hasMore, setHasMore] = useState(true);
  const scrollContainerRef = useRef(null);
  const [resetDone, setResetDone] = useState(false);
  const [fetchedMoreTransaction, setFetchedMoreTransactions] = useState(false);
  const [selectedForBulkUpdate, setSelectedForBulkUpdate] = useState({
    Expense: [],
    Income: [],
  });
  const [selectedExpenseCategories, setSelectedExpenseCategories] = useState(
    []
  );
  const [modals, setModals] = useState({
    addTransaction: false,
    viewTransaction: false,
    changePassword: false,
  });

  const handleSearch = (e) => {
    setSearch(e.target.value);
    handleResetStatesAfterChange();
  };

  const SelectAll = () => {
    if (selectAll) {
      return (
        <span
          className="select-all"
          onClick={() => handleSelectAllForBulkCategoryUpdate(false, true)}
        >
          Unselect all
        </span>
      );
    } else {
      return (
        <span
          className="select-all"
          onClick={() => handleSelectAllForBulkCategoryUpdate(true, false)}
        >
          Select all
        </span>
      );
    }
  };

  const handleSelectAllForBulkCategoryUpdate = (select, unSelect) => {
    const allTransactions = {
      Expense: [...selectedForBulkUpdate.Expense],
      Income: [...selectedForBulkUpdate.Income],
    };
    const data = {
      ...currentMonthGrouped,
      ...remainingMonthsGrouped,
    };
    if (select) {
      Object.values(data).forEach((transactions) => {
        transactions.forEach((transaction) => {
          const type = transaction.amount < 0 ? "Expense" : "Income";
          if (!allTransactions[type].includes(transaction.transactionUuid)) {
            allTransactions[type].push(transaction.transactionUuid);
          }
        });
      });
    } else if (unSelect) {
      setSelectedForBulkUpdate({
        Expense: [],
        Income: [],
      });
      return;
    }
    setSelectedForBulkUpdate(allTransactions);
  };

  const handleResetStatesAfterChange = () => {
    setPage(1);
    setTotalIncomes(0);
    setTotalExpense(0);
    setCurrentMonthGrouped([]);
    setRemainingMonthsGrouped([]);
    setResetDone(true);
  };

  const openModal = (modal, data) => {
    if (modal === "viewTransaction") {
      setSplitModalData(data);
    }
    setModals({ ...modals, [modal]: true });
  };
  const closeModal = (modal) => {
    setModals({ ...modals, [modal]: false });
  };

  const handleDoneWithBulkUpdate = () => {
    setSelectionMode(false);
    setSelectAll(false);
    setSelectedForBulkUpdate({
      Expense: [],
      Income: [],
    });
  };

  const getCategory = useCallback(async () => {
    try {
      const response = await axios.get(`${weburls.Get_All_Categories}${uuid}`);
      setCategory(response?.data?.data?.transaction?.Expense);
      setIcomCat(response?.data?.data?.transaction?.INCOME);
    } catch (err) {
      toast.error(err);
    }
  }, [uuid]);

  const fetchTransactions = async (page) => {
    setLoading(true);
    const data = {
      expensecategoryIds: [...selectedExpenseCategories],
      incomecategoryIds: [...selectedIncomeCategories],
      dateRageFilter: filterByDate,
    };
    try {
      const response = await axios.post(
        `${weburls.Get_Transaction_By_ClientId}${uuid}/10/${page}?name=${search}`,
        data
      );
      const groupedCurrentMonth = groupTransactionsByDate(
        response?.data?.data?.transactions?.thisMonth,
        currentMonthGrouped
      );
      const groupedRemainingMonths = groupTransactionsByMonth(
        response?.data?.data?.transactions?.remainingMonths,
        remainingMonthsGrouped
      );
      const transactionsThisMonth =
        response?.data?.data?.transactions?.thisMonth || [];
      const transactionsRemainingMonths =
        response?.data?.data?.transactions?.remainingMonths || [];
      const allTransactions = [
        ...transactionsThisMonth,
        ...transactionsRemainingMonths,
      ];
      setCurrentMonthGrouped(groupedCurrentMonth.data);
      setRemainingMonthsGrouped(groupedRemainingMonths.data);
      setTotalIncomes(
        totalIncomes +
          groupedCurrentMonth.incomeCount +
          groupedRemainingMonths.incomeCount
      );
      setTotalExpense(
        totalExpense +
          groupedCurrentMonth.expenseCount +
          groupedRemainingMonths.expenseCount
      );
      if (page > 1) {
        console.log("moeizz");
        setFetchedMoreTransactions(true);
        setSelectAll(false);
      }
      console.log(allTransactions.length > 0);
      setHasMore(allTransactions > 0);
      setLoading(false);
    } catch (error) {
      toast.error("Something went wrong, please try again.", {
        position: "top-right",
        autoClose: 2000,
      });
      setLoading(false);
      setHasMore(false);
    }
  };

  useEffect(() => {
    const handleScroll = () => {
      if (scrollContainerRef.current) {
        const { scrollTop, scrollHeight, clientHeight } =
          scrollContainerRef.current;
        if (scrollTop + clientHeight >= scrollHeight - 1) {
          setPage((prev) => prev + 1);
          fetchTransactions(page + 1);
        }
      }
    };
    const container = scrollContainerRef.current;
    if (container) {
      container.addEventListener("scroll", handleScroll);
    }

    return () => {
      if (container) {
        container.removeEventListener("scroll", handleScroll);
      }
    };
  }, [loading, hasMore, page]);

  useEffect(() => {
    getCategory();
    fetchCashAccount();
    fetchAllTransactionsCount();
  }, [getCategory]);

  useEffect(() => {
    if (resetDone) {
      fetchTransactions(1);
      setResetDone(false);
    }
  }, [resetDone]);

  useEffect(() => {
    handleResetStatesAfterChange();
  }, [
    debouncedSearch,
    filterByDate,
    selectedExpenseCategories,
    selectedIncomeCategories,
  ]);

  const fetchCashAccount = useCallback(async () => {
    try {
      const response = await axios.get(`${weburls.Get_Cash_Account}${uuid}`);
      if (response?.data) {
        const parsedData = parseYourAccountBalancesData(
          response?.data?.accounts
        );
        setAccountData(parsedData);
      }
    } catch (error) {
      console.error("error", error);
      toast.error("Something went wrong, please try again.", {
        position: "top-right",
        autoClose: 2000,
      });
    }
  }, [uuid]);

  const fetchAllTransactionsCount = async () => {
    try {
      const response = await axios.get(
        `${weburls.Get_ALL_Transactions_Count}${uuid}`
      );
      console.log(response, "errressssssss");
      setCategorizedTransactionCount(response?.data?.data?.categorized);
      setUnCategorizedTransactionCount(response?.data?.data?.uncategorized);
    } catch (error) {}
  };

  const addTransaction = async (data) => {
    const payload = {
      userUuid: uuid,
      trans_name: data.name,
      trans_date: data.date,
      trans_amount: data.amount,
      accound_id: data.id,
      avatar: data.avatar,
      trans_type: data.transactionType.toUpperCase(),
    };
    try {
      const response = await axios.post(`${weburls.Add_Transaction}`, payload);

      if (response?.data.code === 1) {
        toast.success(response?.data?.message, {
          position: "top-right",
          autoClose: 2000,
        });
      }
      if (response?.data.code === 0) {
        toast.error(response?.data?.message, {
          position: "top-right",
          autoClose: 2000,
        });
      }
      fetchTransactions();
    } catch (error) {
      toast.error("Somthing went wrong...");
    }
  };

  const splitTransaction = async (splits, transactionId, type) => {
    try {
      const response = await axios.post(
        `${weburls.Split_Transaction}${uuid}/${transactionId}/${type}`,
        splits
      );
      if (response?.data?.code === 1) {
        toast.success(`${response?.data?.message}`);
      }
      fetchTransactionAfterAnyUpdate();
      closeModal("viewTransaction");
    } catch (error) {
      toast.error("Something went wrong while spliting transaction");
    }
  };

  const addNewCategory = (data, type) => {
    if (type === "Expense") {
      addNewExpenseCategory(data);
    } else {
      addNewIncomeCategory(data);
    }
  };

  const addNewExpenseCategory = async (data) => {
    try {
      const response = await axios.post(
        `${weburls.Add_Expense_Category}${uuid}`,
        data
      );
      if (response?.data?.code === 1) {
        toast.success(`${response?.data?.message}`);
      }
      if (response?.data?.code === 0) {
        toast.error(`${response?.data?.message}`);
      }
      getCategory();
    } catch (error) {
      toast.error("Somthing went wronge while adding expense category");
    }
  };

  const addNewIncomeCategory = async (data) => {
    try {
      const response = await axios.post(
        `${weburls.Add_Income_Category}${uuid}`,
        data
      );
      if (response?.data?.code === 1) {
        toast.success(`${response?.data?.message}`);
      }
      if (response?.data?.code === 0) {
        toast.error(`${response?.data?.message}`);
      }
      getCategory();
    } catch (error) {
      toast.error("Somthing went wronge while adding expense category");
    }
  };

  const updateTransactionCategory = async (transactionId, categoryId, type) => {
    const data = {
      transactionUuid: transactionId,
      categoryId: categoryId,
      type: type,
    };
    try {
      const response = await axios.post(
        `${weburls.Update_Category}${uuid}`,
        data
      );
      toast.success(`${response?.data?.message}`);
      fetchTransactionAfterAnyUpdate(page);
    } catch (error) {}
  };

  const updateSingleCategory = async (categoryData) => {
    const data = {
      transactionUuid: categoryData.transactionId,
      categoryId: categoryData.userCategoryId,
      type: categoryData.type,
    };
    try {
      const response = await axios.post(
        `${weburls.Update_Category}${uuid}`,
        data
      );
      toast.success(`${response?.data?.message}`);
      setSplitModalData((prev) => ({
        ...prev,
        masterCategoryColour: categoryData.colour,
        masterCategoryEmoji: categoryData.emoji,
        masterCategoryName: categoryData.name,
      }));
      fetchTransactionAfterAnyUpdate(page);
    } catch (error) {}
  };

  const fetchTransactionAfterAnyUpdate = async () => {
    setLoading(true);
    const data = {
      expensecategoryIds: [...selectedExpenseCategories],
      incomecategoryIds: [...selectedIncomeCategories],
      dateRageFilter: filterByDate,
    };
    try {
      const response = await axios.post(
        `${weburls.Get_Transaction_By_ClientId}${uuid}/${
          totalExpense + totalIncomes
        }/${1}?name=${search}`,
        data
      );
      const groupedCurrentMonth = groupTransactionsByDate(
        response?.data?.data?.transactions?.thisMonth
      );
      const groupedRemainingMonths = groupTransactionsByMonth(
        response?.data?.data?.transactions?.remainingMonths
      );
      setCurrentMonthGrouped(groupedCurrentMonth.data);
      setRemainingMonthsGrouped(groupedRemainingMonths.data);
      setLoading(false);
    } catch (error) {
      toast.error("Something went wrong, please try again.", {
        position: "top-right",
        autoClose: 2000,
      });
      setLoading(false);
      setHasMore(false);
    }
  };

  const updateCategoriesInBulk = async (categoryId, type) => {
    setLoading(true);
    const data = {
      expenseTransactionUuids: [...selectedForBulkUpdate.Expense],
      incomeTransactionUuids: [...selectedForBulkUpdate.Income],
      categoryId: categoryId,
      type: type,
    };
    try {
      const response = await axios.post(
        `${weburls.Update_Category_In_Bulk}${uuid}`,
        data
      );
      if (response?.data.code === 1) {
        toast.success(`${response?.data?.message}`);
      }
      fetchTransactionAfterAnyUpdate();
      setLoading(false);
    } catch (error) {
      setLoading(false);
    }
  };

  const handleTagClick = (id, type) => {
    if (type === "expense") {
      setSelectedExpenseCategories((prev) =>
        prev.includes(id) ? prev.filter((tag) => tag !== id) : [...prev, id]
      );
    } else if (type === "income") {
      setSelectedIncomeCategories((prev) =>
        prev.includes(id) ? prev.filter((tag) => tag !== id) : [...prev, id]
      );
    }
  };

  return (
    <>
      <div className="container-scroller" ref={sidebarRef}>
        <div className="container-fluid">
          <div className="appmain mainsidebar main-wrapper">
            <MainSidebar uuid={uuid} />
            <div
              className="app-main__outer your-merchants-main-wrapper"
              ref={scrollContainerRef}
            >
              <div className="row">
                <PageTitle
                  title={"Your Transactions"}
                  description={
                    "Here’s all of your transactions from all accounts"
                  }
                />
                <UserImageInfoToggle />
              </div>
              <div className="d-flex gap-4 mt-5">
                <div className="account-detail-left">
                  <div className="d-flex align-items-center justify-content-between">
                    <SectionTitle
                      title={
                        isSelectionMode
                          ? `${
                              selectedForBulkUpdate.Expense.length +
                              selectedForBulkUpdate.Income.length
                            } Selected`
                          : "Transactions"
                      }
                    />
                    <div className="d-flex gap-1">
                      {!isSelectionMode ? (
                        <>
                          <span className="transaction-count transaction-count-green d-flex align-items-center justify-content-center">
                            {categorizedTransactionCount}
                          </span>
                          <span className="transaction-count transaction-count-red  d-flex align-items-center justify-content-center">
                            {unCategorizedTransactionCount}
                          </span>
                          <span
                            onClick={() => openModal("addTransaction")}
                            className="cursor-pointer"
                          >
                            <PLusIcon />
                          </span>
                        </>
                      ) : (
                        <div className="selection-flex">
                          <div
                            className="d-flex align-items-center gap-1 cursor-pointer"
                            onClick={() => setSelectAll(!selectAll)}
                          >
                            <SelectAllIcon />
                            <SelectAll />
                            {/* <span className="select-all">Select all</span> */}
                          </div>
                          <span
                            className="select-done cursor-pointer"
                            onClick={handleDoneWithBulkUpdate}
                          >
                            Done
                          </span>
                        </div>
                      )}
                    </div>
                  </div>
                  <div className="account-detail-left-wrapper">
                    {!isEmpty(currentMonthGrouped) ||
                    !isEmpty(remainingMonthsGrouped) ? (
                      <TransactionListing
                        data={{
                          ...currentMonthGrouped,
                          ...remainingMonthsGrouped,
                        }}
                        modalOpen={openModal}
                        updateTransactionCategory={updateTransactionCategory}
                        addNewCategory={addNewCategory}
                        expenseCategories={category}
                        incomeCategories={icomecat}
                        setSelectionMode={setSelectionMode}
                        isSelectionMode={isSelectionMode}
                        setSelectedForBulkUpdate={setSelectedForBulkUpdate}
                        selectedForBulkUpdate={selectedForBulkUpdate}
                        updateCategoriesInBulk={updateCategoriesInBulk}
                        selectAll={selectAll}
                        unPress={unPress}
                      />
                    ) : (
                      <NoDataFound />
                    )}
                  </div>
                </div>
                <div className="account-detail-right">
                  <div
                    className="
                 search-spacing filter-merchants-wrp-side"
                  >
                    <div className="row">
                      <div className="col-md-7 col-lg-12">
                        <SectionTitle title={"Search for transactions"} />

                        <div>
                          <div className="search-merchant your-merchant-search">
                            <form>
                              <input
                                type="text"
                                value={search}
                                className="search search-color-white"
                                onChange={(e) => handleSearch(e)}
                              />
                              <Search className="search-merchant" />
                            </form>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="d-flex justify-content-between mt-3 search-mb">
                      <SectionTitle title={"Filter transactions"} />
                    </div>

                    <div className="merchants-background-color">
                      <FilterTags
                        title={"Expense categories"}
                        categories={category}
                        selectedCategories={selectedExpenseCategories}
                        setSelectedCategories={setSelectedExpenseCategories}
                        handleTagClick={handleTagClick}
                        type={"expense"}
                      />

                      <FilterTags
                        title={"Income categories"}
                        categories={icomecat}
                        selectedCategories={selectedIncomeCategories}
                        setSelectedCategories={setSelectedIncomeCategories}
                        handleTagClick={handleTagClick}
                        type={"income"}

                      />
                      
                      <div className="bold live-connection mt-3">
                        Date range
                      </div>
                      <div className="account-date-group green-btn">
                        {dateOptions.map((option) => (
                          <button
                            key={option}
                            className={`account-date-btn ${
                              filterByDate === option ? "green" : ""
                            }`}
                            onClick={() => setFilterByDate(option)}
                          >
                            {option}
                          </button>
                        ))}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            {modals.addTransaction && (
              <AddTransactionModal
                modalOpen={modals.addTransaction}
                closeModal={closeModal}
                addTransaction={addTransaction}
                accountData={accountData}
              />
            )}
            {modals.viewTransaction && (
              <TransactionViewModal
                data={spliModalData}
                modalOpen={modals.viewTransaction}
                closeModal={closeModal}
                addTransaction={addTransaction}
                accountData={accountData}
                splitTransaction={splitTransaction}
                expenseCategories={category}
                incomeCategories={icomecat}
                updateSingleCategory={updateSingleCategory}
              />
            )}
            {loading && (
              <OverlayContainer>
                <div>
                  <Loading />
                </div>
              </OverlayContainer>
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export default Transactions;
