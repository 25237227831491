import React, { forwardRef } from "react";
import "./index.css";

const formatNumber = (value) => {
  const number = parseFloat(value.replace(/[^0-9]/g, ''));
  return isNaN(number) ? '' : number.toLocaleString();
};

const Cinput = forwardRef(
  ({ onChange, name, type = "text", value = '', width }, ref) => {
    const handleChange = (e) => {
      let inputValue = e.target.value;
      
      if (type === "number") {
        inputValue = inputValue.replace(/[^0-9]/g, "");
        const formattedValue = formatNumber(inputValue);
        onChange({ target: { name, value: inputValue } });

        inputValue = formattedValue ? `$${formattedValue}` : '';
      } else {
        onChange(e);
      }
      e.target.value = inputValue;
    };

    return (
      <input
        style={{ width }}
        type={type === "number" ? "text" : type}
        name={name}
        value={type === "number" ? (value ? `$${formatNumber(String(value))}` : '') : value}
        ref={ref}
        className="custom-input"
        onChange={handleChange}
      />
    );
  }
);

export default Cinput;
