import React from "react";
import "../BudgetSummary.css";
import { progressbarColor } from "../../../utilities/Statics";
import { lightBackgroundColor } from "../../../utilities/Statics";

const BudgetBreakdown = ({
  budgetData,
  handleToggleClick,
  isBudgetBreakToggled,
  totalAmount,
  remainingAmount,
}) => {
  const displayedAmount = isBudgetBreakToggled ? remainingAmount : totalAmount;

  const incomeLabel = isBudgetBreakToggled
    ? "Total budgeted Income"
    : "Total actual Income";
  const expenseLabel = isBudgetBreakToggled
    ? "Total budgeted Expenses"
    : "Total actual Expenses";

  const budgetSummaryData = [
    {
      label: incomeLabel,
      amount: displayedAmount.income ? displayedAmount.income.toFixed(2) : 0,
      progress: Math.min(
        (displayedAmount.income / totalAmount.income) * 100 || 0,
        100
      ),
      progressColor: "Green", // Green color for income
    },
    {
      label: expenseLabel,
      amount: displayedAmount.expense ? displayedAmount.expense.toFixed(2) : 0,
      progress: Math.min(
        (displayedAmount.expense / totalAmount.expense) * 100 || 0,
        100
      ),
      progressColor: "Red", // Red color for expenses
    },
  ];

  // Calculate the left over based on toggle (Actual vs Budgeted)
  const leftOver =
    displayedAmount.income && displayedAmount.expense
      ? (displayedAmount.income - displayedAmount.expense).toFixed(2)
      : 0;

  return (
    <div className="col-md-4">
      <div className="budget-breakdown-header">
        <h4>Budget breakdown</h4>
        <button
          className="toggle-button"
          type="button"
          style={{
            borderRadius: "30px",
            background: "#479985",
            color: "white",
            padding: "5px",
            marginBottom: "8px",
          }}
          onClick={handleToggleClick}
        >
          {isBudgetBreakToggled ? "Budgeted" : "Actual"}
        </button>
      </div>

      {/* Budget Breakdown Card */}
      <div className="budget-breakdown-card">
        <div className="summary">
          <h5>Summary</h5>

          {/* Mapping through budget summary data */}
          {budgetSummaryData.map((item, index) => (
            <div key={index} className="summary-item">
              <div className="label">
                <span>{item.label}</span>
                <span className="amount">${item.amount}</span>
              </div>

              <div
                className={`budget-progress-container ${lightBackgroundColor(
                  item.progressColor
                )}`}
                style={{ backgroundColor: `#D3D3D3` }}
              >
                <div
                  className={`budget-progress ${progressbarColor(
                    item.progressColor
                  )}`}
                  style={{
                    width: `${item.progress}%`,
                    // backgroundColor: item.progressColor,
                  }}
                ></div>
              </div>
            </div>
          ))}
        </div>
      </div>

      {/* Left Over Section */}
      <div className="left-over-card">
        <h3>${leftOver.toLocaleString()}</h3>
        <p>Left over</p>
      </div>
    </div>
  );
};

export default BudgetBreakdown;
