/* eslint-disable react/jsx-pascal-case */
import React, { useCallback, useEffect, useRef, useState } from "react";
import { Link, useParams } from "react-router-dom";
import Profile from "../../assests/images/icons/image.png";
import Shape from "../../assests/images/icons/shape.png";
import MainSidebar from "../MainSidebar";
import PopupInfoModal from "../Popup/PopupInfoModel";
import "./BudgetSummary.css";
import { ReactComponent as Arrow } from "../../assests/images/icons/arrow.svg";
import { ReactComponent as Arrow_right } from "../../assests/images/Arrow_right_nav.svg";
// import { ReactComponent as Chart } from "../../assests/images/chart_vertical.svg";
// import { ReactComponent as Edit } from "../../assests/images/icons/edit.svg";
import BudgetBreakdown from "./BudgetSummeryComponent/BudgetBreakdown";
import BudgetsComponent from "./BudgetSummeryComponent/BudgetsComponent";
import { toast } from "react-toastify";
import weburls from "../Weburls/weburls";
import axios from "axios";
import { ReactComponent as Toggle_Money_Insight } from "../../assests/images/toggle_money_insight.svg";
import OverlayContainer from "../OverlayContainer/OverlayContainer";
import { Loading } from "../Loading/Loading";
import {
  getCurrentFortnight,
  getCurrentMonth,
  getCurrentQuarter,
  getCurrentWeek,
  getCurrentYear,
  getLastFortnight,
  getLastMonth,
  getLastQuarter,
  getLastWeek,
} from "../../utilities/helper";
import OneOffBudget from "./BudgetSummeryComponent/OneOffBudget";

const BudgetSummary = () => {
  const sidebarRef = useRef(null);
  const { uuid } = useParams();

  const [isPopupOpenInfo, setPopupOpenInfo] = useState(false);
  const [isBudgetBreakToggled, setIsBudgetBreakToggled] = useState(false);
  const [incomeBudgetsData, setIncomeBudgetsData] = useState([]);
  const [expenseBudgetsData, setExpenseBudgetsData] = useState([]);
  const [oneOffBudgetsData, setOneOffBudgetsData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [currentIndex, setCurrentIndex] = useState(0);
  const [completeBudgetDataIncome, setCompleteBudgetDataIncome] = useState();
  const [completeBudgetDataExpense, setCompleteBudgetDataExpense] = useState();
  const [remainingAmount, setRemainingAmount] = useState({
    income: 0,
    expense: 0,
  });

  const [totalAmount, setTotalAmount] = useState({
    income: 0,
    expense: 0,
  });

  const [selectedPeriod, setSelectedPeriod] = useState("thisWeek");

  const togglePopupInfo = () => setPopupOpenInfo(!isPopupOpenInfo);

  const handleToggleClick = () => {
    setIsBudgetBreakToggled(!isBudgetBreakToggled);
  };

  const options = [
    { label: "thisWeek", text: "This Week", date: getCurrentWeek() },
    { label: "lastWeek", text: "Last Week", date: getLastWeek() },
    { label: "monthly", text: "Monthly", date: getCurrentMonth() },
    { label: "lastMonth", text: "Last Month", date: getLastMonth() },
    { label: "quarter", text: "Quarter", date: getCurrentQuarter() },
    { label: "lastQuarter", text: "Last Quarter", date: getLastQuarter() },
    { label: "thisYear", text: "This Year", date: getCurrentYear() },
    {
      label: "thisFortnightly",
      text: "This Fortnightly",
      date: getCurrentFortnight(),
    },
    {
      label: "lastFortnightly",
      text: "Last Fortnightly",
      date: getLastFortnight(),
    },
    { label: "thisCustom", text: "This Custom", date: "Custom Date" },
    {
      label: "lastCustom",
      text: "Last Custom",
      date: "Last Custom Date",
    },
  ];

  const handleLeftClick = () => {
    const newIndex = currentIndex === 0 ? options.length - 1 : currentIndex - 1;
    const newLabel = options[newIndex].label;
    setSelectedPeriod(newLabel);
    setCurrentIndex(newIndex);
    setIncomeBudgetsData(completeBudgetDataIncome.income[newLabel]);
    setExpenseBudgetsData(completeBudgetDataExpense.expenses[newLabel]);
  };

  const handleRightClick = () => {
    const newIndex = currentIndex === options.length - 1 ? 0 : currentIndex + 1;
    const newLabel = options[newIndex].label;
    setSelectedPeriod(newLabel);
    setCurrentIndex(newIndex);
    setIncomeBudgetsData(completeBudgetDataIncome.income[newLabel]);
    setExpenseBudgetsData(completeBudgetDataExpense.expenses[newLabel]);
  };

  const fetchIncomeBudgetData = useCallback(async () => {
    setLoading(true);
    try {
      const response = await axios.get(`${weburls.Get_Income_Budget}${uuid}`);

      if (response?.data?.code === "0") {
        toast.error(response?.data?.message || "An error occurred.", {
          position: "top-right",
          autoClose: 2000,
        });
      } else if (response?.data) {
        setCompleteBudgetDataIncome(response?.data);
        setIncomeBudgetsData(response?.data.income.thisWeek);
      }
    } catch (error) {
      console.error("Error fetching income budget data:", error);
      toast.error("Something went wrong, please try again.", {
        position: "top-right",
        autoClose: 2000,
      });
    } finally {
      setLoading(false);
    }
  }, [uuid]);

  const fetchExpenseBudgetData = useCallback(async () => {
    setLoading(true);
    try {
      const response = await axios.get(`${weburls.Get_Expense_Budget}${uuid}`);

      if (response?.data?.code === "0") {
        toast.error(response?.data?.message || "An error occurred.", {
          position: "top-right",
          autoClose: 2000,
        });
      } else if (response?.data) {
        setCompleteBudgetDataExpense(response?.data);
        setExpenseBudgetsData(response?.data.expenses.thisWeek);
      }
    } catch (error) {
      console.error("Error fetching Expense budget data:", error);
      toast.error("Something went wrong, please try again.", {
        position: "top-right",
        autoClose: 2000,
      });
    } finally {
      setLoading(false);
    }
  }, [uuid]);

  const fetchOneOffBudgetData = useCallback(async () => {
    setLoading(true);
    try {
      const response = await axios.get(`${weburls.Get_OneOff_Budget}${uuid}`);

      if (response?.data?.code === "0") {
        toast.error(response?.data?.message || "An error occurred.", {
          position: "top-right",
          autoClose: 2000,
        });
      } else if (response?.data) {
        setOneOffBudgetsData(response.data);
      }
    } catch (error) {
      console.error("Error fetching One Off budget data:", error);
      toast.error("Something went wrong, please try again.", {
        position: "top-right",
        autoClose: 2000,
      });
    } finally {
      setLoading(false);
    }
  }, [uuid]);

  useEffect(() => {
    if (uuid) {
      fetchIncomeBudgetData();
      fetchExpenseBudgetData();
      fetchOneOffBudgetData();
    }
  }, [
    uuid,
    fetchIncomeBudgetData,
    fetchExpenseBudgetData,
    fetchOneOffBudgetData,
  ]);

  return (
    <>
      <div className="container-scroller" ref={sidebarRef}>
        <div className="container-fluid">
          <div className="appmain mainsidebar main-wrapper">
            <MainSidebar uuid={uuid} />
            <div className="app-main__outer budget-summary-main">
              <div className="row top-nav">
                <div className="col-md-4 edit-cashflow-title-heading">
                  <div className="bold big-head-new">Budget summary</div>
                  <div className="fst-normal setting_text_color mt-1">
                    Here`s your summary of your budget and how you`re tracking.
                  </div>
                </div>
                <div className="col-md-8 right-section back-portal-edit-cashflow">
                  <div className=" right-section">
                    <div
                      className="edit-btn"
                      style={{
                        width: "327px",
                     
                        height: "60px",
                        marginRight: "30px",
                        borderRadius: "360px",
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "space-between",
                        backgroundColor: "#001f3f",
                      }}
                    >
                      <div
                        className="left"
                        onClick={handleLeftClick}
                        style={{ cursor: "pointer" }}
                      >
                        <Arrow_right />
                      </div>

                      <div className="" style={{ textAlign: "center" }}>
                        <h4>{options[currentIndex].text}</h4>
                        <p style={{ color: "#04baff" }}>
                          <p>{options[currentIndex].date}</p>
                        </p>
                      </div>

                      <div
                        className="right"
                        onClick={handleRightClick}
                        style={{ cursor: "pointer" }}
                      >
                        <Arrow />
                      </div>
                    </div>

                    <Link
                      to={`/BudgetSummary/${uuid}/budget-insights/${uuid}`}
                      // className="edit-btn"
                      style={{
                        // width: "84px",
                        marginRight: "30px",
                        borderRadius: "32px",
                        cursor: "pointer",
                      }}
                    >
                      <Toggle_Money_Insight style={{ backgroundColor: "" }} />
                    </Link>

                    <div className="profile-dashboard">
                      <img
                        src={Shape}
                        alt="Mortage_img"
                        onClick={togglePopupInfo}
                        style={{ width: "20px" }}
                      />
                    </div>

                    {isPopupOpenInfo && (
                      <PopupInfoModal
                        isOpen={isPopupOpenInfo}
                        onClose={togglePopupInfo}
                        title="Cashflow 🤑"
                        description={[
                          "Your cashflow is determined by the data you enter in your budget. Based on how often you get paid or spend money, this shows when either of these two will occur next in the future",
                          "You can change views for your cash flow to show weekly, monthly, or quarterly incomings and outgoings.",
                          "Review your balance left over per week, month, or quarter. If it`s in the negative, make some adjustments. If it’s in green, then you’re good to go.",
                        ]}
                      />
                    )}
                    <div className="profile-dashboard">
                      <div className="profile-img">
                        <img src={Profile} alt="profile" />
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className="row mt-4">
                <div className="col">
                  {/* Income Budgets Section */}

                  <BudgetsComponent
                    mainHeading="Income"
                    incomeExpenseBudgetsData={incomeBudgetsData}
                    setTotalAmount={setTotalAmount}
                    setRemainingAmount={setRemainingAmount}
                    isIncome={true}
                    selectedPeriod={selectedPeriod}
                    showFields={true}
                    uuid={uuid}
                    fetchBudgetData={fetchIncomeBudgetData}
                  />
                  <div className="mt-4">
                    <BudgetsComponent
                      mainHeading="Expense "
                      setRemainingAmount={setRemainingAmount}
                      setTotalAmount={setTotalAmount}
                      incomeExpenseBudgetsData={expenseBudgetsData}
                      isIncome={false}
                      showFields={true}
                      selectedPeriod={selectedPeriod}
                      uuid={uuid}
                      fetchBudgetData={fetchExpenseBudgetData}
                    />
                  </div>
                </div>

                {/* Budget Breakdown Card */}
                <BudgetBreakdown
                  handleToggleClick={handleToggleClick}
                  isBudgetBreakToggled={isBudgetBreakToggled}
                  totalAmount={totalAmount}
                  remainingAmount={remainingAmount}
                />
              </div>
              {/* End of Income Budgets Section */}

              <div className="row mt-4">
                <OneOffBudget
                  mainHeading="One off "
                  showFields={false}
                  oneOffBudgetsData={oneOffBudgetsData.oneOffBudget}
                  uuid={uuid}
                  fetchBudgetData={fetchOneOffBudgetData}
                />
              </div>
            </div>
            {loading && (
              <OverlayContainer>
                <div>
                  <Loading />
                </div>
              </OverlayContainer>
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export default BudgetSummary;
