import { yupResolver } from "@hookform/resolvers/yup";
import axios from "axios";
import React, { useCallback, useEffect, useRef, useState } from "react";
import { useForm } from "react-hook-form";
import { useParams } from "react-router-dom";
import { toast } from "react-toastify";
import * as yup from "yup";
import { accountTypeArray } from "../../../assests/mock/AccountType";
import MainSidebar from "../../MainSidebar";
import weburls from "../../Weburls/weburls";
import { parseYourAccountBalancesData } from "../../../utilities/helper";
import { AkahuAccountCard } from "../../AkahuAccountCard";
import { ReactComponent as UploadImageIcon } from "../../../assests/images/upload-img.svg";
import {
  iconCategories,
  moduleInfoPopUpData,
} from "../../../utilities/Statics";
import { BankConnectionList } from "../../BankConnectionList";
import ConnectAkahuModal from "../../Popup/ConnectAkahuModal";
import OverlayContainer from "../../OverlayContainer/OverlayContainer";
import { Loading } from "../../Loading/Loading";
import SectionTitle from "../../SectionTitle.jsx";
import PageTitle from "../../PageTitle";
import UserImageInfoToggle from "../../UserImageInfoToggle";
import NoDataFound from "../../NoDataFound/index.jsx";

const schema = yup.object().shape({
  accountName: yup.string().required("*Please enter account name"),
  value: yup.string().required("*Please enter value"),
});

const {
  bankConnections,
  kiwiSaverConnections,
  addInvestmentConnections,
  otherConnections,
} = iconCategories;

const AccountBalances = () => {
  const sidebarRef = useRef(null);
  const { uuid } = useParams();
  const {
    register,
    // formState: { errors },
    reset,
  } = useForm({
    resolver: yupResolver(schema),
  });

  const [cashAccountData, setCashAccountData] = useState({
    userUuid: "",
    accountName: "",
    value: "",
    avatar: "",
    accountType: "",
    accountNumber: "",
    personalisedColour: "",
  });

  const [apiData, setApiData] = useState([]);
  const [avatar, setAvatar] = useState();
  const [errors, setErrors] = useState({});
  const [isPopupOpenInfo, setPopupOpenInfo] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const [loading, setLoading] = useState(false);

  const handleAkahuModal = () => {
    setIsOpen(!isOpen);
  };

  const togglePopupInfo = () => setPopupOpenInfo(!isPopupOpenInfo);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setCashAccountData({ ...cashAccountData, userUuid: uuid, [name]: value });
    const newErrors = { ...errors };
    if (name === "accountName" && value.length < 3) {
      newErrors.name = "Account Name must be at least 3 characters long";
    } else if (name === "accountType" && !value) {
      newErrors.accountType = "Please enter account type";
    } else if (name === "value" && !value) {
      newErrors.accountBalance = "Please enter account balance";
    } else {
      newErrors.name = "";
      newErrors.accountType = "";
      newErrors.accountBalance = "";
      delete newErrors[name];
    }
    setErrors(newErrors);
  };

  const fetchCashAccount = useCallback(async () => {
    setLoading(true);
    try {
      const response = await axios.get(`${weburls.Get_Cash_Account}${uuid}`);
      if (response?.data) {
        const parsedData = parseYourAccountBalancesData(
          response?.data?.accounts
        );
        setApiData(parsedData);
        localStorage.setItem(
          "accountUuid",
          JSON.stringify(response?.data?.accounts)
        );
      }
      setLoading(false);
    } catch (error) {
      setLoading(false);
      toast.error("Something went wrong, please try again.", {
        position: "top-right",
        autoClose: 2000,
      });
    } finally {
      setLoading(false);
    }
  }, [uuid]);
  const clearFileInput = () => {
    const fileInput = document.querySelector('input[type="file"]');
    if (fileInput) {
      fileInput.value = "";
    }
  };
  const submitHandler = async () => {
    try {
      const formData = new FormData();
      if (
        cashAccountData.accountName &&
        cashAccountData.value &&
        cashAccountData.accountType
      ) {
        formData.append("accountName", cashAccountData.accountName);
        formData.append("avatar", avatar);
        formData.append("userUuid", uuid);
        formData.append("value", cashAccountData.value);
        formData.append("accountType", cashAccountData.accountType);
        formData.append("accountNumber", cashAccountData.accountNumber);
        formData.append(
          "personalisedColour",
          cashAccountData.personalisedColour
        );
        const response = await axios.post(weburls.Add_Cash_Account, formData);
        if (response.status === 200) {
          toast.success(response.data.message, {
            position: "top-right",
            autoClose: 2000,
          });
          fetchCashAccount();
          setAvatar("");
          setImageName("");
          reset({
            accountName: "",
            value: "",
            accountType: "",
            accountNumber: "",
            personalisedColour: "",
          });
          setCashAccountData({
            accountName: "",
            avatar: "",
            userUuid: "",
            value: "",
            accountType: "",
            accountNumber: "",
            personalisedColour: "",
          });
          clearFileInput();
        } else if (response.status === 404) {
          toast.error("Something went wrong please try again.", {
            position: "top-right",
            autoClose: 2000,
          });
        }
      } else {
        handleChange({
          target: { name: "accountName", value: cashAccountData.accountName },
        });
        handleChange({
          target: { name: "accountType", value: cashAccountData.accountType },
        });
        handleChange({
          target: { name: "value", value: cashAccountData.value },
        });
      }
    } catch (error) {
      console.error("error", error);
      toast.error("Something went wrong please try again.", {
        position: "top-right",
        autoClose: 2000,
      });
    }
  };

  useEffect(() => {
    fetchCashAccount();
  }, [fetchCashAccount]);

  const fileInputRef = useRef(null);
  const [imageName, setImageName] = useState("");

  const handleClick = () => {
    fileInputRef.current.click();
  };

  const handleFileChange = (e) => {
    const file = e.target.files[0];
    setImageName(file?.name);
    setAvatar(file);
  };

  return (
    <>
      <div className="container-scroller" ref={sidebarRef}>
        <div className="container-fluid">
          <div className="appmain mainsidebar main-wrapper">
            <MainSidebar uuid={uuid} />
            <div className="app-main__outer your-account-balances-container-main">
              <div className="row">
                <PageTitle
                  title={"Your Accounts"}
                  description={"See what your account balances are today."}
                />
                <UserImageInfoToggle
                  isPopupOpenInfo={isPopupOpenInfo}
                  togglePopupInfo={togglePopupInfo}
                  title={moduleInfoPopUpData.accounts.title}
                  description={moduleInfoPopUpData.accounts.description}
                />
              </div>
              <div className="row mt-5">
                <div className="d-flex justify-content-between flex-wrap">
                  <div className="account-left">
                    <SectionTitle title={"Account balances"} />
                    <div className="acccount-balances-wrappper-left-side">
                      {apiData?.length > 0 ? (
                        apiData?.map((item, index) => (
                          <AkahuAccountCard
                            key={index}
                            avatar={item?.avatar}
                            accountNumber={item?.accountNumber}
                            accountName={item?.accountName}
                            value={item?.value}
                            accountId={item?.accountId}
                          />
                        ))
                      ) : (
                        <NoDataFound />
                      )}
                    </div>
                  </div>

                  <div
                    className="col-lg-4 search-spacing your-account-bal-add-cash account-right"
                  >
                    <SectionTitle title={"Add an account"} />
                    <div className="cash-account-box">
                      <div className="d-flex justify-content-between">
                        <div className="fw-500 fs-16">Name</div>
                        <div className="account-input">
                          <input
                            type="text"
                            onChange={handleChange}
                            {...register("accountName", {
                              onChange: (e) => {
                                handleChange(e);
                              },
                            })}
                            style={{ width: "226px", height: "35px" }}
                          />
                        </div>
                      </div>
                      {errors.name && (
                        <>
                          <br />
                          <p className="error-message">{errors.name}</p>
                        </>
                      )}
                      <div className="d-flex justify-content-between mt-3">
                        <div className="fw-500 fs-16">Account&nbsp;balance</div>
                        <div className="account-input small-account">
                          <input
                            type="text"
                            onChange={handleChange}
                            {...register("value", {
                              onChange: (e) => {
                                handleChange(e);
                              },
                            })}
                            style={{ width: "148px", height: "35px" }}
                          />
                        </div>
                      </div>
                      {errors.accountBalance && (
                        <>
                          <br />
                          <p className="error-message">
                            {errors.accountBalance}
                          </p>
                        </>
                      )}

                      <div className="d-flex justify-content-between mt-3">
                        <div className="fw-500 fs-16">Image</div>
                        <div className="account-input">
                          <div className="upload">
                            <div className="account-img-upload">
                              <input
                                type="file"
                                accept="image/*"
                                ref={fileInputRef}
                                style={{ display: "none" }}
                                onChange={handleFileChange}
                              />
                              <UploadImageIcon
                                className="upload-icon"
                                onClick={handleClick}
                              />
                              {imageName}
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="d-flex justify-content-between align-items-center">
                        <div className="fw-500 fs-16">Account type</div>
                        <div className="account-input">
                          <select
                            className="account-select mt-3"
                            name="accountType"
                            {...register("accountType", {
                              onChange: (e) => {
                                handleChange(e);
                              },
                            })}
                          >
                            <option value="">Select</option>
                            {accountTypeArray &&
                              accountTypeArray.length > 0 &&
                              accountTypeArray.map((item, index) => (
                                <option key={index} value={item.id}>
                                  {item.title}
                                </option>
                              ))}
                          </select>
                        </div>
                      </div>
                      {errors.accountType && (
                        <>
                          <br />
                          <p className="error-message">{errors.accountType}</p>
                        </>
                      )}

                      <div className="add-account-button  ">
                        <button onClick={submitHandler}>Add Account</button>
                      </div>
                    </div>

                    <div className="mt-4">
                      <SectionTitle title={"Connect an account"} />
                    </div>

                    <div className="bank-background">
                      <BankConnectionList
                        title={"Add a bank connection"}
                        connections={bankConnections}
                        onIconClick={handleAkahuModal}
                      />
                      <div className="connection-mt">
                        <BankConnectionList
                          title={"Add a Kiwisaver connection"}
                          connections={kiwiSaverConnections}
                          onIconClick={handleAkahuModal}
                        />
                      </div>
                      <div className="connection-mt">
                        <BankConnectionList
                          title={"Add an investment connection"}
                          connections={addInvestmentConnections}
                        />
                      </div>
                      <div className="connection-mt">
                        <BankConnectionList
                          title={"Other connections"}
                          connections={otherConnections}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            {loading && (
              <OverlayContainer>
                <div>
                  <Loading />
                </div>
              </OverlayContainer>
            )}
          </div>
          {isOpen && (
            <ConnectAkahuModal
              modalOpen={isOpen}
              closeModal={handleAkahuModal}
            />
          )}
          {loading && (
            <OverlayContainer>
              <div>
                <Loading />
              </div>
            </OverlayContainer>
          )}
        </div>
      </div>
    </>
  );
};
export default AccountBalances;
