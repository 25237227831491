import React, { useState } from 'react';
import 'react-datepicker/dist/react-datepicker.css';
import { ToastContainer } from 'react-toastify';
import { ReactComponent as EyeClose } from '../../../assests/images/eye-close-icon.svg';
import { ReactComponent as EyeOpen } from '../../../assests/images/eye-open-icon.svg';
import { ReactComponent as Success } from '../../../assests/images/icons/passwordSucess.svg';
import '../AddIncome/addIncome.css';
import './index.css';

const ChangePassword = ({
  modalOpen,
  setNewPassword,
  setCurrentPassword,
  setConfirmPassword,
  currentPassword,
  confirmPassword,
  changePassword,
  closeModal,
  newPassword,
  modalActionCompleted
}) => {

  const [passwordVisibility, setPasswordVisibility] = useState({
    current: false,
    new: false,
    confirm: false,
  });

  const togglePasswordVisibility = (field) => {
    setPasswordVisibility({
      ...passwordVisibility,
      [field]: !passwordVisibility[field],
    });
  };

  return (
    <div className={`modal-background ${modalOpen ? 'open-modal' : ''} `}>
      <ToastContainer position="top-right" autoClose={5000} closeOnClick />
      <div className="modal-content delete-modal-width">
        {!modalActionCompleted && (
          <button onClick={()=> closeModal('changePassword')} className="close-pass-button">
            <i class="fa fa-times"></i>
          </button>
        )}
        <div className="">
          {!modalActionCompleted ? (
            <>
              <div className="d-flex justify-content-between">
                <div className="fs-3">
                  <span className="bold black password-heading">Change Password</span>
                </div>
              </div>
              <form>
                <div className="row add_income-wrp">
                  <div className="setting-left-section mt-4">
                    <div className="">
                      <form className="row">
                        <div className="field-form col-xl-12">
                          <label className="label-text">Current password</label>
                          <div className="password-input-flex">
                            <input
                              type={passwordVisibility.current ? 'text' : 'password'}
                              name="currentPassword"
                              value={currentPassword}
                              className="edit-settings-house w-75 py-2 inter add-goal full-width-transaction"
                              style={{ border: 'none' }}
                              onChange={(e) => {
                                setCurrentPassword(e.target.value);
                              }}
                              required
                            />
                            <div
                              className="show-password"
                              style={{ marginRight: '20px' }}
                              onClick={() => togglePasswordVisibility('current')}
                            >
                              {passwordVisibility.current ?<EyeOpen />  :  <EyeClose />}
                            </div>
                          </div>
                        </div>
                        <div className="field-form col-xl-12">
                          <label className="label-text">New password</label>
                          <div className="password-input-flex">
                            <input
                              type={passwordVisibility.new ? 'text' : 'password'}
                              name="currentPassword"
                              value={newPassword}
                              className="edit-settings-house w-75 py-2 inter add-goal full-width-transaction"
                              style={{ border: 'none' }}
                              onChange={(e) => {
                                setNewPassword(e.target.value);
                              }}
                              required
                            />
                            <div
                              className="show-password"
                              style={{ marginRight: '20px' }}
                              onClick={() => togglePasswordVisibility('new')}
                            >
                              {passwordVisibility.new ?<EyeOpen />  :  <EyeClose />}
                            </div>
                          </div>
                        </div>
                        <div className="field-form col-xl-12">
                          <label className="label-text">Confirm password</label>
                          <div className="password-input-flex">
                            <input
                              type={passwordVisibility.confirm ? 'text' : 'password'}
                              name="currentPassword"
                              value={confirmPassword}
                              className="edit-settings-house w-75 py-2 inter add-goal full-width-transaction"
                              style={{ border: 'none' }}
                              onChange={(e) => {
                                setConfirmPassword(e.target.value);
                              }}
                              required
                            />
                            <div
                              className="show-password"
                              style={{ marginRight: '20px' }}
                              onClick={() => togglePasswordVisibility('confirm')}
                            >
                              {passwordVisibility.confirm ?<EyeOpen />  :  <EyeClose />}
                            </div>
                          </div>
                        </div>
                        <div class="modal-popup-btn-wrp">
                          <button className="change-password-btn" type="button" onClick={changePassword}>
                            Change password
                          </button>
                        </div>
                      </form>
                    </div>
                  </div>
                </div>
              </form>
            </>
          ) : (
            <div className="sucess-container transition-delay">
              <div className="fs-3">
                <span className="bold black password-heading">Password Changed</span>
              </div>
              <p className="sucess-p">
                Your password has now been updated. Login using this password and enjoy all the features we have to
                offer.
              </p>
              <div className="success-img">
                <Success />
              </div>
              <div class="modal-popup-btn-wrp">
                <button className="change-password-btn" type="button" onClick={()=> closeModal("changePassword")}>
                  Close
                </button>
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default ChangePassword;
