import axios from "axios";
import moment from "moment/moment";
import React, { useCallback, useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import { ReactComponent as Editnw } from "../../assests/images/icons/edit.svg";
import { ReactComponent as Edit } from "../../assests/images/icons/main-plus.svg";
import MainSidebar from "../MainSidebar";
import weburls from "../Weburls/weburls";
import PageTitle from "../PageTitle";
import UserImageInfoToggle from "../UserImageInfoToggle";
import { emojiBackgroundColor, lightBackgroundColor, moduleInfoPopUpData } from "../../utilities/Statics";

export const colorCss = (color) => {
  switch (color) {
    case "Dark green":
      return "dark-green-color";
    case "Red":
      return "red-color";
    case "Yellow":
      return "yellow-color";
    case "Blue":
      return "blue-color";
    case "Purple":
      return "purple-color-color";
    case "Orange":
      return "orange-color";
    case "Pink":
      return "pink-color";
    case "Light blue":
      return "light-blue-color";
    case "Lime green":
      return "lime-green-color";
    case "Dark red":
      return "dark-red-color";
    case "Green":
      return "green-color";
    case "Dark blue":
      return "dark-blue-color";
    default:
      return "green";
  }
};


export const progressbarColor = (color) => {
  switch (color) {
    case "Dark green":
      return "progress-dark-green-color";
    case "Red":
      return "progress-red-color";
    case "Yellow":
      return "progress-yellow-color";
    case "Blue":
      return "progress-blue-color";
    case "Purple":
      return "progress-purple-color-color";
    case "Orange":
      return "progress-orange-color";
    case "Pink":
      return "progress-pink-color";
    case "Light blue":
      return "progress-light-blue-color";
    case "Lime green":
      return "progress-lime-green-color";
    case "Dark red":
      return "progress-dark-red-color";
    case "Green":
      return "progress-green-color";
    case "Dark blue":
      return "progress-dark-blue-color";
    default:
      return "progress-green";
  }
};

const Financial = () => {
  const [active, setActive] = useState(false);
  const [goals, setGoals] = useState([]);
  const [selectedGoal, setSelectedGoal] = useState(null);
  const { uuid } = useParams();
  const [isPopupOpenInfo, setPopupOpenInfo] = useState(false);
  const togglePopupInfo = () => setPopupOpenInfo(!isPopupOpenInfo);

  const fetchGoalDetails = useCallback(async () => {
    try {
      const response = await axios.get(`${weburls.Get_AllGoals}${uuid}`);
      if (response?.data) {
        setGoals(response?.data?.goals);
      }
    } catch (error) {
      console.error("error", error);
      toast.error("Something went wrong, please try again.", {
        position: "top-right",
        autoClose: 2000,
      });
    }
  }, [uuid]);

  useEffect(() => {
    fetchGoalDetails();
  }, [fetchGoalDetails]);

  const handleEditGoal = (goal) => {
    setSelectedGoal(goal);
    setActive(true);
  };
  const handleDeleteGoal = async (goalUuid) => {
    try {
      await axios.delete(`${weburls.Delete_Goals}${uuid}/${goalUuid}`);
      fetchGoalDetails();
      toast.success("Goal successfully Deleted...", {
        position: "top-right",
        autoClose: 1000,
      });
      setSelectedGoal(null);
      setActive(false);
      fetchGoalDetails();
    } catch (error) {
      toast.error("Goal details not update. Please try again.");
      console.error("error", error);
    }
  };

  const handleCancelEdit = () => {
    setSelectedGoal(null);
    setActive(false);
  };

  const handleSaveEdit = async (editedGoal) => {
    try {
      const response = await axios.put(weburls.Update_Goals, editedGoal);
      if (response) {
        toast.success("Goal successfully Updated...", {
          position: "top-right",
          autoClose: 2000,
        });
        setSelectedGoal(null);
        setActive(false);
        fetchGoalDetails();
      } else if (response.status === 500) {
        toast.error("Goal details not update. Please try again.");
      }
    } catch (error) {
      console.error("error", error);
    }
  };

  return (
    <>
      <div className="container-scroller">
        <div className="container-fluid">
          <div className="appmain mainsidebar main-wrapper">
            <MainSidebar uuid={uuid} />
            <div className="app-main__outer financial-goals-main">
              <ToastContainer
                position="top-right"
                autoClose={5000}
                closeOnClick
              />
              <div className="row new-finance">
                <PageTitle
                  title={"Your Goals"}
                  description={
                    "Let's set some goals, it’s always good having goals and something to aim for. "
                  }
                />
                <div className="col-lg-4 col-sm-12 right-section back-to-portal-financial">
                  <div className="setting_background_color right-section">
                    <Link to={`/Financial/${uuid}/AddNewFinancial`}>
                      <div className="edit-btn-col">
                        <div
                          className="edit-btn"
                          href="#"
                          style={{
                            width: "251px",
                            height: "60px",
                            borderRadius: "50px",
                            marginRight: "-3rem",
                            boxShadow:"0px 5px 30px 0px rgba(0, 0, 0, 0.10)",
                            padding: "11px 22px"
                          }}
                        >
                          <div className="left">
                            <h4 className="bold">Add a new goal</h4>
                            <p style={{ color: "#04baff", textAlign: "left" }}>
                              Keep on track
                            </p>
                          </div>
                          <div className="right">
                            <Edit />
                          </div>
                        </div>
                      </div>
                    </Link>

                    <UserImageInfoToggle
                      title={moduleInfoPopUpData.goals.title}
                      description={moduleInfoPopUpData.goals.description}
                      isPopupOpenInfo={isPopupOpenInfo}
                      togglePopupInfo={togglePopupInfo}
                    />
                  </div>
                </div>

                <div className="row mt-5">
                  <div className="">
                    <div className="row">
                      {goals && goals.length > 0 ? (
                        goals.map((it) => (
                          <div
                            className="col-lg-6 col-md-12 mt-4"
                            key={it.goalUuid}
                          >
                            <Link
                              to={`/Financial/${uuid}/GoalDetails/${it.goalUuid}`}
                              style={{
                                color: "white",
                              }}
                            >
                              <div
                                className={`financial-holiday financial cursor-pointer ${colorCss(
                                  it.colour
                                )}`}
                              >
                                <div className="d-flex justify-content-between">
                                  <div className="d-flex justify-content-between">
                                    <div className="d-flex align-items-center">
                                      <div className={`icon-holiday ${emojiBackgroundColor(it.colour)}`}>
                                        {it.icon}
                                      </div>
                                      <div className="ms-2 text-white category-text inter">
                                        {it.goalName}
                                      </div>
                                    </div>
                                  </div>
                                  <div className="d-flex align-items-center">
                                    <div className="financial-icon">
                                      <Editnw
                                        onClick={() => handleEditGoal(it)}
                                      />
                                    </div>
                                  </div>
                                </div>
                                <div className="d-flex justify-content-between mt-3 mb-2 align-items-center">
                                  <div className="financial-text">Balance</div>
                                  <div className="financial-text">
                                    {(
                                      (it.currentBalance / it.value).toFixed(
                                        2
                                      ) * 100
                                    ).toFixed(0)}
                                    %
                                  </div>
                                </div>
                                <div
                                  className={`progress ${lightBackgroundColor(it.colour)}`}
                                  style={{
                                    height: "7px",
                                  }}
                                >
                                  <div
                                    className={`progress-bar ${progressbarColor(
                                      it.colour
                                    )}`}
                                    role="progressbar"
                                    aria-valuenow={(
                                      (it.currentBalance / it.value) *
                                      100
                                    ).toFixed(2)}
                                    aria-valuemin="0"
                                    aria-valuemax="100"
                                    style={{
                                      width: `${(
                                        (it.currentBalance / it.value) *
                                        100
                                      ).toFixed(2)}%`,
                                      opacity: "2 !important",
                                    }}
                                  ></div>
                                </div>
                                <div className="amount-top d-flex justify-content-between align-items-start">
                                  <div>
                                    <span className="goal-amount text-white">
                                      ${(it?.currentBalance.toFixed(2))}
                                    </span>
                                    <span className="financial-text">
                                      &nbsp; of &nbsp;${it.value}
                                    </span>

                                    <div
                                      className="mt-3 see-detail"
                                      style={{
                                        fontSize: "12px",
                                      }}
                                    >
                                      See detail
                                    </div>
                                  </div>
                                  <div className="financial-text">
                                    {it?.endDate
                                      ? moment(it?.endDate).format(
                                          "Do MMMM YYYY"
                                        )
                                      : ""}
                                  </div>
                                </div>
                              </div>
                            </Link>
                          </div>
                        ))
                      ) : (
                        <div className="col-lg-6 col-md-12 mt-4">
                          <div className="text-danger">
                            No goal, please add your goal
                          </div>
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          
        </div>
      </div>
    </>
  );
};

export default Financial;
