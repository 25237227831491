import axios from "axios";
import React, { useCallback, useEffect, useRef, useState } from "react";
import { useLocation, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import { ReactComponent as Search } from "../../../assests/images/icons/Search.svg";
import MainSidebar from "../../MainSidebar";
import weburls from "../../Weburls/weburls";
import {
  groupTransactionsByDate,
  groupTransactionsByMonth,
} from "../../../utilities/helper";
import { TransactionListing } from "../../Transactions/TransactionListing";
import OverlayContainer from "../../OverlayContainer/OverlayContainer";
import { Loading } from "../../Loading/Loading";
import TransactionViewModal from "../../ModalForm/TransactionModals/TransactionViewModal";
import useDebounce from "../../../hooks/useDebouce";
import { dateOptions } from "../../../utilities/Statics";
import Amount from "../../Amount.jsx";
import SectionTitle from "../../SectionTitle.jsx/index.jsx";
import PageTitle from "../../PageTitle/index.jsx";
import UserImageInfoToggle from "../../UserImageInfoToggle/index.jsx";
import Logo from "../../../assests/images/icons/Black Logo 1.png";
import { isEmpty } from "lodash";
import NoDataFound from "../../NoDataFound/index.jsx";

const AccountDetails = () => {
  const { uuid } = useParams();
  const location = useLocation();
  const { avatar, accountName, value, accountNumber, accountId } =
    location.state || {};
  const sidebarRef = useRef(null);
  const [category, setCategory] = useState([]);
  const [icomecat, setIcomCat] = useState([]);
  const [filterByDate, setFilterByDate] = useState("");
  const [isPopupOpenInfo, setPopupOpenInfo] = useState(false);
  const [selectedExpenseCategories, setSelectedExpenseCategories] = useState(
    []
  );
  const [selectedIncomeCategories, setSelectedIncomeCategories] = useState([]);
  const togglePopupInfo = () => setPopupOpenInfo(!isPopupOpenInfo);
  const [currentMonthGrouped, setCurrentMonthGrouped] = useState({});
  const [remainingMonthsGrouped, setRemainingMonthsGrouped] = useState({});
  const [loading, setLoading] = useState(false);
  const [spliModalData, setSplitModalData] = useState();
  const [modals, setModals] = useState({
    viewTransaction: false,
  });

  const [search, setSearch] = useState("");
  const debouncedSearch = useDebounce(search, 500);

  const openModal = (modal, data) => {
    if (modal === "viewTransaction") {
      setSplitModalData(data);
    }
    setModals({ ...modals, [modal]: true });
  };
  const closeModal = (modal) => {
    setModals({ ...modals, [modal]: false });
  };

  const getCategory = useCallback(async () => {
    try {
      const response = await axios.get(`${weburls.Get_All_Categories}${uuid}`);
      setCategory(response?.data?.data?.transaction?.Expense);
      setIcomCat(response?.data?.data?.transaction?.INCOME);
    } catch (err) {
      toast.error(err);
    }
  }, [uuid]);

  const fetchTransactions = useCallback(async () => {
    setLoading(true);
    const data = {
      expensecategoryIds: [...selectedExpenseCategories],
      incomecategoryIds: [...selectedIncomeCategories],
      dateRageFilter: filterByDate,
    };
    try {
      const response = await axios.post(
        `${weburls.Get_Account_Transactions}${uuid}/${accountId}/700/1?name=${search}`,
        data
      );
      const groupedCurrentMonth = groupTransactionsByDate(
        response?.data?.data?.transactions?.thisMonth
      );
      const groupedRemainingMonths = groupTransactionsByMonth(
        response?.data?.data?.transactions?.remainingMonths
      );

      setCurrentMonthGrouped(groupedCurrentMonth.data);
      setRemainingMonthsGrouped(groupedRemainingMonths.data);
      setLoading(false);
    } catch (error) {
      toast.error("Something went wrong, please try again.", {
        position: "top-right",
        autoClose: 2000,
      });
      setLoading(false);
    }
  }, [
    uuid,
    selectedExpenseCategories,
    selectedIncomeCategories,
    debouncedSearch,
    filterByDate,
  ]);

  useEffect(() => {
    getCategory();
    fetchTransactions();
    // fetchCashAccount();
  }, [getCategory, fetchTransactions, debouncedSearch]);

  useEffect(() => {
    if (debouncedSearch) {
      fetchTransactions();
    }
  }, [debouncedSearch, fetchTransactions]);

  const addTransaction = async (data) => {
    const payload = {
      userUuid: uuid,
      trans_name: data.name,
      trans_date: data.date,
      trans_amount: data.amount,
      accound_id: data.id,
      avatar: data.avatar,
      trans_type: data.transactionType.toUpperCase(),
    };
    try {
      const response = await axios.post(`${weburls.Add_Transaction}`, payload);

      if (response?.data.code === 1) {
        toast.success(response?.data?.message, {
          position: "top-right",
          autoClose: 2000,
        });
      }
      if (response?.data.code === 0) {
        toast.error(response?.data?.message, {
          position: "top-right",
          autoClose: 2000,
        });
      }
      fetchTransactions();
    } catch (error) {
      toast.error("Somthing went wrong...");
    }
  };

  const splitTransaction = async (splits, transactionId, type) => {
    try {
      const response = await axios.post(
        `${weburls.Split_Transaction}${uuid}/${transactionId}/${type}`,
        splits
      );
      if (response?.data?.code === 1) {
        toast.success(`${response?.data?.message}`);
      }
      fetchTransactions();
      closeModal("viewTransaction");
    } catch (error) {
      toast.error("Something went wrong while spliting transaction");
    }
  };

  const addNewCategory = (data, type) => {
    if (type === "Expense") {
      addNewExpenseCategory(data);
    } else {
      addNewIncomeCategory(data);
    }
  };

  const addNewExpenseCategory = async (data) => {
    try {
      const response = await axios.post(
        `${weburls.Add_Expense_Category}${uuid}`,
        data
      );
      if (response?.data?.code === 1) {
        toast.success(`${response?.data?.message}`);
      }
      if (response?.data?.code === 0) {
        toast.error(`${response?.data?.message}`);
      }
      getCategory();
    } catch (error) {
      toast.error("Somthing went wronge while adding expense category");
    }
  };

  const addNewIncomeCategory = async (data) => {
    try {
      const response = await axios.post(
        `${weburls.Add_Income_Category}${uuid}`,
        data
      );
      if (response?.data?.code === 1) {
        toast.success(`${response?.data?.message}`);
      }
      if (response?.data?.code === 0) {
        toast.error(`${response?.data?.message}`);
      }
      getCategory();
    } catch (error) {
      toast.error("Somthing went wronge while adding expense category");
    }
  };

  const updateTransactionCategory = async (transactionId, categoryId, type) => {
    const data = {
      transactionUuid: transactionId,
      categoryId: categoryId,
      type: type,
    };
    try {
      const response = await axios.post(
        `${weburls.Update_Category}${uuid}`,
        data
      );
      toast.success(`${response?.data?.message}`);
      fetchTransactions();
    } catch (error) {}
  };

  const handleTagClick = (id, type) => {
    if (type === "expense") {
      setSelectedExpenseCategories((prev) =>
        prev.includes(id) ? prev.filter((tag) => tag !== id) : [...prev, id]
      );
    } else if (type === "income") {
      setSelectedIncomeCategories((prev) =>
        prev.includes(id) ? prev.filter((tag) => tag !== id) : [...prev, id]
      );
    }
  };

  return (
    <>
      <div className="container-scroller" ref={sidebarRef}>
        <div className="container-fluid">
          <div className="appmain mainsidebar main-wrapper">
            <MainSidebar uuid={uuid} />
            <div className="app-main__outer your-merchants-main-wrapper">
              <div className="row">
                <PageTitle
                  title={"Account details"}
                  description={
                    "Here’s all your account details including all your transactions as well."
                  }
                />
                <UserImageInfoToggle />
              </div>
              <div className="d-flex gap-4 mt-5">
                <div className="account-detail-left">
                  <SectionTitle title={"Account"} />
                  <div className="selected-account ">
                    <div className="merchants-inner-wrp"></div>
                    <div className="merchant-box ">
                      <div>
                        <div className="d-flex align-items-center">
                          <div className="img-box">
                            <img
                              src={!isEmpty(avatar) ? avatar : Logo}
                              alt=""
                              style={{
                                borderRadius: "50%",
                              }}
                            />
                          </div>
                          <div className="ms-3">
                            <div className="text-merchant bold black">
                              {accountName}
                            </div>
                            <div className="small-text-merchant">
                              <h4 className="small-text-merchant space black">
                                <div className="setting_text_color-2">
                                  {accountNumber}
                                </div>
                              </h4>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="button-blck">
                        <div className="selected-amount">
                          <Amount amount={value} />
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="d-flex align-items-center justify-content-between">
                    <SectionTitle title={"Transactions"} />
                  </div>
                  <div className="account-detail-left-wrapper">
                    {!isEmpty(currentMonthGrouped) ||
                    !isEmpty(remainingMonthsGrouped) ? (
                        <TransactionListing
                          data={{...currentMonthGrouped,...remainingMonthsGrouped}}
                          modalOpen={openModal}
                          expenseCategories={category}
                          updateTransactionCategory={updateTransactionCategory}
                          addNewCategory={addNewCategory}
                          incomeCategories={icomecat}
                          isAccountModule={true}
                        />
                    ) : (
                      <NoDataFound />
                    )}
                  </div>
                </div>
                <div className="account-detail-right">
                  <div className="search-spacing filter-merchants-wrp-side">
                    <div className="row">
                      <div className="col-md-7 col-lg-12">
                        <SectionTitle title={"Search for transactions"} />
                        <div>
                          <div className="search-merchant your-merchant-search">
                            <form>
                              <input
                                type="text"
                                value={search}
                                className="search search-color-white"
                                onChange={(e) => setSearch(e.target.value)}
                              />
                              <Search className="search-merchant" />
                            </form>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="d-flex justify-content-between mt-3">
                      <SectionTitle title={"Filter transactions"} />
                    </div>
                    <div className="merchants-background-color">
                      <div className="bold live-connection">
                        Expense categories
                      </div>
                      <div className="group-btn green-btn">
                        <div className="group-btn green-btn ">
                          <div className="button-blck">
                            <button
                              className={`group-button ${
                                selectedExpenseCategories.length <= 0
                                  ? "green"
                                  : ""
                              }`}
                              onClick={() => setSelectedExpenseCategories([])}
                            >
                              All
                            </button>
                          </div>
                          {category &&
                            category?.length > 0 &&
                            category.map((it) => (
                              <div
                                key={it?.userCategoryId}
                                className="button-blck"
                              >
                                <div
                                  className={`icon-tag ${
                                    selectedExpenseCategories.includes(
                                      it.userCategoryId
                                    )
                                      ? "green"
                                      : ""
                                  }`}
                                  onClick={() =>
                                    handleTagClick(it.userCategoryId, "expense")
                                  }
                                >
                                  <div
                                    className="icon-transaction-img  "
                                    style={{
                                      backgroundColor: it?.colour,
                                    }}
                                  >
                                    {it?.emoji}
                                  </div>
                                  <div className="content">
                                    <h6
                                      className={`icon-content ${
                                        selectedExpenseCategories.includes(
                                          it.userCategoryId
                                        )
                                          ? "icon-white"
                                          : ""
                                      }`}
                                    >
                                      {it?.name}
                                    </h6>
                                  </div>
                                </div>
                              </div>
                            ))}
                        </div>
                      </div>
                      <div className="bold live-connection">
                        Income categories
                      </div>
                      <div className="group-btn green-btn">
                        <div className="group-btn green-btn ">
                          <div className="button-blck">
                            <button
                              className={`group-button ${
                                selectedIncomeCategories.length <= 0
                                  ? "green"
                                  : ""
                              }`}
                              onClick={() => setSelectedIncomeCategories([])}
                            >
                              All
                            </button>
                          </div>
                          {icomecat &&
                            icomecat?.length > 0 &&
                            icomecat.map((it) => (
                              <div
                                key={it?.userCategoryId}
                                className="button-blck"
                              >
                                <div
                                  className={`icon-tag ${
                                    selectedIncomeCategories.includes(
                                      it.userCategoryId
                                    )
                                      ? "green"
                                      : ""
                                  }`}
                                  onClick={() =>
                                    handleTagClick(it.userCategoryId, "income")
                                  }
                                >
                                  <div
                                    className="icon-transaction-img  "
                                    style={{
                                      backgroundColor: it?.colour,
                                    }}
                                  >
                                    {it?.emoji}
                                  </div>
                                  <div className="content">
                                    <h6
                                      className={`icon-content ${
                                        selectedIncomeCategories.includes(
                                          it.userCategoryId
                                        )
                                          ? "icon-white"
                                          : ""
                                      }`}
                                    >
                                      {it?.name}
                                    </h6>
                                  </div>
                                </div>
                              </div>
                            ))}
                        </div>
                      </div>
                      <div className="bold live-connection mt-3">
                        Date range
                      </div>
                      <div className="account-date-group green-btn">
                        {dateOptions.map((option) => (
                          <div className="button-blck" key={option}>
                            <button
                              className={`account-date-btn ${
                                filterByDate === option ? "green" : ""
                              }`}
                              onClick={() => setFilterByDate(option)}
                            >
                              {option}
                            </button>
                          </div>
                        ))}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            {modals.viewTransaction && (
              <TransactionViewModal
                data={spliModalData}
                modalOpen={modals.viewTransaction}
                closeModal={closeModal}
                addTransaction={addTransaction}
                // accountData={accountData}
                splitTransaction={splitTransaction}
              />
            )}
            {loading && (
              <OverlayContainer>
                <div>
                  <Loading />
                </div>
              </OverlayContainer>
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export default AccountDetails;
