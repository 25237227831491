import React from "react";
import "./index.css";
import { ToggleSwitch } from "../ToggleSwitch";
import Logo from "../../assests/images/icons/Black Logo 1.png";

import Amount from "../Amount.jsx";
import { isEmpty } from "lodash";
export const ConnectedAccounts = ({
  avatar,
  accountName,
  value,
  accountId,
  handleToggleSwitch,
  id,
  selectedAccount,
  isDrawer = false,
  width,
}) => {
  const isChecked = isDrawer
    ? selectedAccount === id
    : selectedAccount.includes(id);

  return (
    <div
      className="connected-account-box d-flex justify-content-between"
      style={{ width: width }}
    >
      <div
        className="d-flex align-items-center"
        style={{ gap: "12px", width: "80%" }}
      >
        <div>
          <img className="account-img"  src={!isEmpty(avatar) ? avatar : Logo} alt="" />
        </div>
        <div className="lcs-inner w-50">
          <div className="connected-title account-elipses">{accountName}</div>
          <div className="connected-amount">{<Amount amount={value} />}</div>
        </div>
      </div>
      <ToggleSwitch
        onChange={() =>
          handleToggleSwitch({ accountId, avatar, id, accountName,value })
        }
        value={isChecked}
      />
    </div>
  );
};
