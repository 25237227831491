import React, { useEffect, useState } from "react";
import "./IncomeExpenseBudgetModal.css";
import HowOften from "./HowOften";
import PopupColorModal from "../../../Popup/PopupColorModel";
import EmojiPicker from "emoji-picker-react";
import EditDetails from "./EditDetails";
import axios from "axios";
import weburls from "../../../Weburls/weburls";
import { useParams } from "react-router-dom";
import { toast } from "react-toastify";
import OverlayContainer from "../../../OverlayContainer/OverlayContainer";
import { Loading } from "../../../Loading/Loading";

export const colorCss = (color) => {
  switch (color) {
    case "Dark green":
      return "dark-green-color";
      break;
    case "Red":
      return "red-color";
      break;
    case "Yellow":
      return "yellow-color";
      break;
    case "Blue":
      return "blue-color";
      break;
    case "Purple":
      return "purple-color-color";
      break;
    case "Orange":
      return "orange-color";
      break;
    case "Pink":
      return "pink-color";
      break;
    case "Light blue":
      return "light-blue-color";
      break;
    case "Lime green":
      return "lime-green-color";
      break;
    case "Dark red":
      return "dark-red-color";
      break;
    case "Green":
      return "green-color";
      break;
    case "Dark blue":
      return "dark-blue-color";
      break;
    default:
      return "green";
  }
};

const IncomeExpenseBudgetModal = ({
  isOpen,
  toggleModal,
  mainHead,
  isIncome,
  fetchBudgetData,
}) => {
  const { uuid } = useParams();
  const [selectedFrequency, setSelectedFrequency] = useState("Weekly");
  const [isFrequencyModalOpen, setFrequencyModalOpen] = useState(false);
  const [selectedColorOption, setSelectedColorOption] = useState("Red");
  const [isPopupOpenColor, setPopupOpenColor] = useState(false);
  const togglePopupColor = () => setPopupOpenColor(!isPopupOpenColor);
  const [showPicker, setShowPicker] = useState(false);
  const [chosenEmoji, setChosenEmoji] = useState("😁");
  const [amount, setAmount] = useState("$");
  const [isSpreadToggled, setIsSpreadToggled] = useState(false);
  const [needOrWant, setNeedOrWant] = useState("");
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const [budgetExpenseResponse, setBudgetExpenseResponse] = useState(null);
  const [loading, setLoading] = useState(false);

  const [incomeApi, setIncomeApi] = useState({});
  const [expenseApi, setExpenseApi] = useState({});
  const checkIncomeOrExpense = mainHead?.trim().toLowerCase();
  const [editName, setEditName] = useState("");

  useEffect(() => {
    if (checkIncomeOrExpense === "expense" && budgetExpenseResponse?.data) {
      setAmount(`$${budgetExpenseResponse.data.amount}`);
      setSelectedFrequency(budgetExpenseResponse.data.howOften || "Weekly");
      setSelectedColorOption(budgetExpenseResponse.data.colour || "Red");
    } else {
      setAmount("$");
      setSelectedFrequency("Weekly");
      setSelectedColorOption("Red");
    }
  }, [checkIncomeOrExpense, budgetExpenseResponse]);

  const handleNeedToggleClick = () => {
    setNeedOrWant(!needOrWant);
  };

  const handleOptionColorClick = (option) => {
    setSelectedColorOption(option);
    setPopupOpenColor(false);
  };

  const handleToggleClick = () => {
    setIsSpreadToggled(!isSpreadToggled);
  };

  const handleAmountChange = (e) => {
    const value = e.target.value;

    const numericValue = value.replace(/^\$/, "");

    if (/^\d*\.?\d*$/.test(numericValue)) {
      setAmount(`$${numericValue}`);
    }
  };

  const handleNameChange = (e) => {
    setEditName(e.target.value);
  };

  const handleFrequencySelection = (frequency) => {
    setSelectedFrequency(frequency);
    setFrequencyModalOpen(false);
  };

  const toggleFrequencyModal = () => {
    setFrequencyModalOpen((prev) => !prev);
  };

  const getButtonBackgroundColor = (frequency) => {
    switch (frequency) {
      case "Weekly":
        return "#479985";
      case "Fortnightly":
        return "#3de1bc";
      case "Monthly":
        return "#3d6ae2";
      case "Quarterly":
        return "#3eb1e1";
      case "Annual":
        return "#39e16f";
      case "Custom":
        return "#8D83FE";
      default:
        return "#479985";
    }
  };

  if (!isOpen) return null;

  const updateTransactionCategory = (transactionId, userCategoryId, type) => {
    // Add logic for updating category in transaction
  };

  const transaction = {
    transactionUuid: 1,
    amount: 100,
  };

  const handleEmojiClick = (event) => {
    const { emoji } = event;
    setChosenEmoji(emoji);
    setShowPicker(false);
  };

  // API call starts

  const addIncomeOrExpenseBudget = async () => {
    try {
      const apiData =
        checkIncomeOrExpense === "expense" ? expenseApi : incomeApi;

      const payload = {
        ...(checkIncomeOrExpense === "one off"
          ? {
              name: editName,
              amount: amount.replace("$", ""),
              colour: selectedColorOption,
              emoji: chosenEmoji,
            }
          : {
              [checkIncomeOrExpense === "expense"
                ? "expenseName"
                : "incomeName"]:
                apiData[
                  checkIncomeOrExpense === "expense"
                    ? "expenseName"
                    : "incomeName"
                ] || "",
              [checkIncomeOrExpense === "expense"
                ? "expenseAmount"
                : "incomeAmount"]: amount.replace("$", ""),
              howOften: selectedFrequency,
              colour: selectedColorOption,
              emoji: chosenEmoji,
              type: "",
              user_category_id: apiData.user_category_id || 0,
              ...(selectedFrequency === "Custom" && {
                startDate: startDate || "",
                endDate: endDate || "",
              }),
            }),
      };

      const url =
        checkIncomeOrExpense === "expense"
          ? `${weburls.Add_Expense_Budget}${uuid}`
          : checkIncomeOrExpense === "income"
          ? `${weburls.Add_Income_Budget}${uuid}`
          : `${weburls.Create_OneOff_Budget}${uuid}`;

      const response = await axios.post(`${url}`, payload);

      if (response?.data?.code === 1) {
        toast.success(`${response?.data?.message}`);
      } else if (response?.data?.code === 0) {
        toast.error(`${response?.data?.message}`);
      }
    } catch (error) {
      toast.error(
        `Something went wrong while adding ${checkIncomeOrExpense} category`
      );
    }
  };

  const autoSingleBudgetExpenses = async () => {
    if (!expenseApi?.user_category_id || !uuid) {
      toast.error("Please select a category first.");
      return;
    }
    if (expenseApi?.isDefaultCategory === false) {
      toast.error("Please select a Default categories for the Auto Expense.");
      return;
    }

    setLoading(true);
    try {
      const data = {
        howOften: selectedFrequency,
        categoryId: expenseApi.user_category_id,
      };
      const response = await axios.post(
        `${weburls.Auto_Single_Budget_Expenses}${uuid}`,
        data,
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      );

      setBudgetExpenseResponse(response?.data);

      if (response?.data?.code === 1) {
        toast.success(`${response?.data?.message}`);
      } else if (response?.data?.code === 0) {
        toast.error(`${response?.data?.message}`);
      }
    } catch (error) {
      toast.error("Something went wrong while adding expense category");
    } finally {
      setLoading(false);
    }
  };

  // API call Ends

  const handleNext = async (e) => {
    e.preventDefault();

    try {
      await addIncomeOrExpenseBudget();

      if (checkIncomeOrExpense === "one off") {
        setEditName("");
        setAmount("$");
        setSelectedColorOption("Red");
        setChosenEmoji("😁");
      } else {
        setAmount("$");
        setSelectedFrequency("Weekly");
        setSelectedColorOption("Red");
        setChosenEmoji("😁");
        setStartDate("");
        setEndDate("");
      }
      await fetchBudgetData();
    } catch (error) {
      console.error("Error while processing next step:", error);
    }
  };

  const handleSaveAndFinish = async () => {
    try {
      await addIncomeOrExpenseBudget();

      toggleModal();

      await fetchBudgetData();
    } catch (error) {
      console.error("Error while saving and finishing:", error);
    }
  };

  return (
    <div className="modal-overlay">
      <div className="modal-container">
        <button className="close-button" onClick={toggleModal}>
          &times;
        </button>
        <h2>Add {mainHead} budget</h2>
        <p>Enter in your details for your income budget.</p>
        <div className="modal-budget-content">
          <EditDetails
            mainHead={mainHead}
            transaction={transaction}
            updateTransactionCategory={updateTransactionCategory}
            amount={amount}
            needOrWant={needOrWant}
            handleAmountChange={handleAmountChange}
            selectedFrequency={selectedFrequency}
            toggleFrequencyModal={toggleFrequencyModal}
            isSpreadToggled={isSpreadToggled}
            getButtonBackgroundColor={getButtonBackgroundColor}
            handleToggleClick={handleToggleClick}
            showCategory={
              checkIncomeOrExpense === "expense" ||
              checkIncomeOrExpense === "income"
                ? true
                : false
            }
            incomeExpenseBudgetsName={checkIncomeOrExpense}
            handleNeedToggleClick={handleNeedToggleClick}
            startDate={startDate}
            setStartDate={setStartDate}
            endDate={endDate}
            setEndDate={setEndDate}
            setIncomeApi={setIncomeApi}
            setExpenseApi={setExpenseApi}
            autoExpense={checkIncomeOrExpense === "expense" ? true : false}
            autoSingleBudgetExpenses={autoSingleBudgetExpenses}
            expenseOrIncome={mainHead.toLowerCase().replace(/\s+/g, "")}
            handleNameChange={handleNameChange}
          />
          <h2>Personalise</h2>
          <div
            className="modal-input-group modal-section-card"
            style={{ marginBottom: "0px" }}
          >
            <label>Personalised Color</label>
            <div className="color-option">
              <div className="dropdown">
                <button
                  className={`group-button ${colorCss(
                    selectedColorOption
                  )} popoup-color`}
                  onClick={togglePopupColor}
                  aria-haspopup="true"
                  aria-expanded={isPopupOpenColor}
                  type="button"
                >
                  {selectedColorOption}
                </button>
                {isPopupOpenColor && (
                  <PopupColorModal
                    isOpen={isPopupOpenColor}
                    onClose={togglePopupColor}
                    title="Date Range"
                    options={[
                      "Dark green",
                      "Red",
                      "Yellow",
                      "Blue",
                      "Purple",
                      "Orange",
                      "Pink",
                      "Light blue",
                      "Lime green",
                      "Dark red",
                      "Green",
                      "Dark blue",
                    ]}
                    selectedOption={selectedColorOption}
                    handleOptionClick={handleOptionColorClick}
                  />
                )}
              </div>
            </div>
          </div>

          <div
            className="modal-input-group modal-section-card"
            style={{ marginBottom: "0px", marginTop: "5px" }}
          >
            <label>Personalised emoji</label>
            <div className="d-flex flex-column">
              <div
                className="py-2 inter add-goal show-emoji"
                onClick={() => setShowPicker(!showPicker)}
                style={{ width: "150px" }}
              >
                {chosenEmoji}
              </div>
              {showPicker && (
                <div className="category-emoji-container">
                  <EmojiPicker onEmojiClick={handleEmojiClick} />
                </div>
              )}
            </div>
          </div>

          <div className="modal-actions">
            <button
              type="button"
              className="save-button"
              onClick={handleSaveAndFinish}
            >
              Save & Finish
            </button>
            <button type="button" className="next-button" onClick={handleNext}>
              Next {mainHead}
            </button>
          </div>

          {/* Frequency Selection Modal */}
          {isFrequencyModalOpen && (
            <HowOften
              toggleFrequencyModal={toggleFrequencyModal}
              handleFrequencySelection={handleFrequencySelection}
            />
          )}
        </div>
        {loading && (
          <OverlayContainer>
            <div>
              <Loading />
            </div>
          </OverlayContainer>
        )}
      </div>
    </div>
  );
};

export default IncomeExpenseBudgetModal;
