import axios from "axios";
import moment from "moment/moment";
import React, { useCallback, useEffect, useState } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import Profile from "../../assests/images/icons/image.png";
import Shape from "../../assests/images/icons/shape.png";
import MainSidebar from "../MainSidebar";
import PopupInfoModal from "../Popup/PopupInfoModel";
import weburls from "../Weburls/weburls";
import { EditGoal } from "./EditGoal";
import { ToggleSwitch } from "../ToggleSwitch";
import OverlayContainer from "../OverlayContainer/OverlayContainer";
import { Loading } from "../Loading/Loading";
import {
  parseGoalData,
  parseYourAccountBalancesData,
} from "../../utilities/helper";
import { ConnectedAccounts } from "../ConnectedAccountsCard";
import ConfirmationModal from "../Popup/ConfirmationModal";
import { colorCss, progressbarColor } from "./Financial";
import { emojiBackgroundColor, moduleInfoPopUpData } from "../../utilities/Statics";
import PageTitle from "../PageTitle";
import UserImageInfoToggle from "../UserImageInfoToggle";

const GoalDetails = () => {
  const [active, setActive] = useState(false);
  const [goals, setGoals] = useState([]);
  const [selectedGoal, setSelectedGoal] = useState(null);
  const { uuid, goalUuid } = useParams();
  const [isPopupOpenInfo, setPopupOpenInfo] = useState(false);
  const togglePopupInfo = () => setPopupOpenInfo(!isPopupOpenInfo);
  const [loading, setLoading] = useState(false);
  const [accountData, setAccountData] = useState([]);
  const [isLinkedToAccount, setIsLinkedToAccount] = useState(false);
  const [chosenEmoji, setChosenEmoji] = useState(null);
  const [showPicker, setShowPicker] = useState(false);
  const [showConfirmationModal, setShowConfirmationModal] = useState(false);
  const [selectedAccounts, setSelectedAccounts] = useState([]);

  const navigate = useNavigate();
  const fetchGoalDetails = useCallback(async () => {
    setLoading(true);
    try {
      const response = await axios.get(
        `${weburls.Get_Goals_ById}${uuid}/${goalUuid}`
      );
      if (response?.data) {
        const parsedData = parseGoalData(response?.data?.goal);
        setGoals(parsedData);
        setIsLinkedToAccount(parsedData.isConnected)
        setSelectedAccounts(parsedData.accounts)
        setActive(true);
        setLoading(false);
        setChosenEmoji(parsedData.icon)
      }
    } catch (error) {
      console.error("error", error);
      setLoading(false);

      toast.error("Something went wrong, please try again.", {
        position: "top-right",
        autoClose: 2000,
      });
    }
  }, [goalUuid]);

  const fetchCashAccount = useCallback(async () => {
    setLoading(true);
    try {
      const response = await axios.get(`${weburls.Get_Cash_Account}${uuid}`);
      if (response?.data) {
        const parsedData = parseYourAccountBalancesData(
          response?.data?.accounts
        );
        setAccountData(parsedData);
      }
      setLoading(false);
    } catch (error) {
      console.error("error", error);

      toast.error("Something went wrong please try again.", {
        position: "top-right",
        autoClose: 2000,
      });
    }
  }, [uuid]);

  useEffect(() => {
    fetchGoalDetails();
    fetchCashAccount();
  }, [fetchGoalDetails]);

  const handleEditGoal = (goal) => {
    setSelectedGoal(goal);
    setActive(true);
  };

  const updateGoal = async (goals) => {
    const data = { ...goals };
    try {
      await axios.put(`${weburls.Update_Goals}${uuid}`, data);
      toast.success("Goal successfully Updated...", {
        position: "top-right",
        autoClose: 1000,
      });
      navigate(`/Financial/${uuid}`);
    } catch (error) {
      toast.error("Something went wrong while updating goal...");
    }
  };

  const handleDeleteGoal = async () => {
    setLoading(true);
    try {
      await axios.delete(`${weburls.Delete_Goals}${uuid}/${goalUuid}`);
      fetchGoalDetails();
      toast.success("Goal successfully Deleted...", {
        position: "top-right",
        autoClose: 1000,
      });
      setLoading(true);
      setSelectedGoal(null);
      setActive(false);
      fetchGoalDetails();
      navigate(`/Financial/${uuid}`);
    } catch (error) {
      toast.error("Goal details not update. Please try again.");
    }
  };

  const handleCancelEdit = () => {
    setSelectedGoal(null);
    setActive(false);
  };

  const handleSaveEdit = async (editedGoal) => {
    if (!isLinkedToAccount) {
      editedGoal.accounts = [];
      editedGoal.isConnected=false
    }
    try {
      const response = await axios.put(weburls.Update_Goals + uuid, editedGoal);
      if (response) {
        toast.success("Goal successfully Updated...", {
          position: "top-right",
          autoClose: 2000,
        });
        setSelectedGoal(null);
        setActive(false);
        navigate(`/Financial/${uuid}`);
      } else if (response.status === 500) {
        toast.error("Goal details not update. Please try again.");
      }
    } catch (error) {
      console.error("error", error);
    }
  };


  const handleToggleSwitch = (data) => {
    const { accountId } = data;
    setSelectedAccounts((prevAccounts) => {
      const newAccounts = prevAccounts.includes(accountId)
        ? prevAccounts.filter((id) => id !== accountId)
        : [...prevAccounts, accountId];

      const currentBalance = accountData
        .filter((acc) => newAccounts.includes(acc.accountId))
        .reduce((total, acc) => total + acc.value, 0);
      setGoals((prevGoalData) => ({
        ...prevGoalData,
        accounts: newAccounts,
        isConnected: true,
        currentBalance: currentBalance,
      }));
      return newAccounts;
    });
  };



  return (
    <>
      <div className="container-scroller">
        <div className="container-fluid">
          <div className="appmain mainsidebar main-wrapper">
            <MainSidebar uuid={uuid} />
            <div className="app-main__outer financial-goals-main">
              <div className="row new-finance">
                <PageTitle title={"Goal details"} description={"Let's set some goals, it’s always good having goals and something to aim for."}/>
                <UserImageInfoToggle
                  title={moduleInfoPopUpData.goals.title}
                  description={moduleInfoPopUpData.goals.description}
                  isPopupOpenInfo={isPopupOpenInfo}
                  togglePopupInfo={togglePopupInfo}
                />
                <div className="row">
                  <div className="col-lg-8 financial-cards-wrp-div goal-grid-style">
                    <div className="d-flex gap-5 mt-3">
                      {active && (
                        <EditGoal
                          goal={goals}
                          onCancel={handleCancelEdit}
                          handleSaveEdit={handleSaveEdit}
                          onDelete={handleDeleteGoal}
                          isLinkedToAccount={isLinkedToAccount}
                          setIsLinkedToAccount={setIsLinkedToAccount}
                          setShowPicker={setShowPicker}
                          showPicker={showPicker}
                          chosenEmoji={chosenEmoji}
                          updateGoal={updateGoal}
                          setShowConfirmationModal={setShowConfirmationModal}
                          showConfirmationModal={showConfirmationModal}
                          selectedAccounts={selectedAccounts}
                          setChosenEmoji={setChosenEmoji}
                        />
                      )}
                      <div
                        className="col-lg-8 col-md-12 mt-3"
                        key={goals?.goalUuid}
                      >
                        <div
                          className="black sub-head-text"
                          style={{ maginTop: "10px", marginBottom: "10px" }}
                        >
                          Your goal
                        </div>

                        <div
                          className={`financial-holiday financial cursor-pointer ${colorCss(
                            goals?.colour
                          )}`}
                        >
                          <div className="d-flex justify-content-between">
                            <div className="d-flex justify-content-between">
                              <div className="d-flex align-items-center">
                                <div
                                  className={`icon-holiday ${emojiBackgroundColor(
                                    goals?.colour
                                  )}`}
                                >
                                  {goals?.icon}
                                </div>
                                <div className="ms-2 text-white category-text fw-bold">
                                  {goals?.goalName}
                                </div>
                              </div>
                            </div>
                            <div className="d-flex align-items-center">
                              <div
                                className="financial-icon"
                                style={{ marginRight: "15px" }}
                              >
                              </div>
                            </div>
                          </div>
                          <div className="d-flex justify-content-between mt-3 mb-2 align-items-center">
                            <div className="financial-text">Balance</div>
                            <div className="financial-text">
                              {goals?.goalComplete}
                            </div>
                          </div>
                          <div
                            className="progress red-progress"
                            style={{
                              height: "7px",
                            }}
                          >
                            <div
                              className={`progress-bar ${progressbarColor(
                                goals?.colour
                              )}`}
                              role="progressbar"
                              aria-valuenow={goals?.goalComplete}
                              aria-valuemin="0"
                              aria-valuemax="100"
                              style={{
                                width: goals?.goalComplete,
                                opacity: "2 !important",
                              }}
                            ></div>
                          </div>
                          <div className="amount-top d-flex justify-content-between align-items-start">
                            <div>
                              <span className="bold text-white">
                                ${goals?.currentBalance?.toFixed(2)}
                              </span>
                              <span className="financial-text">
                                &nbsp; of &nbsp;${goals?.value}
                              </span>
                              <div
                                className="mt-3 see-detail"
                              >
                                See detail
                              </div>
                            </div>
                            <div className="financial-text">
                              {goals?.endDate
                                ? moment(goals?.endDate).format("Do MMMM YYYY")
                                : ""}
                            </div>
                          </div>
                        </div>
                        {isLinkedToAccount && (
                          <>
                            <div className="black sub-head-text mt-4 mb-2">
                              Linked accounts
                            </div>

                            <div className="d-flex flex-wrap gap-2">
                              {accountData?.map((ele, index) => (
                                <ConnectedAccounts
                                  key={index}
                                  avatar={ele?.avatar}
                                  accountName={ele?.accountName}
                                  value={ele?.value}
                                  accountId={ele?.accountId}
                                  width={"49%"}
                                  selectedAccount={selectedAccounts}
                                  id={ele?.accountId}
                                  handleToggleSwitch={handleToggleSwitch}
                                />
                              ))}
                            </div>
                          </>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            {loading && (
              <OverlayContainer>
                <div>
                  <Loading />
                </div>
              </OverlayContainer>
            )}
          </div>
          {showConfirmationModal && (
            <ConfirmationModal
              isOpen={showConfirmationModal}
              onConfirm={handleDeleteGoal}
              onCancel={() => setShowConfirmationModal(!showConfirmationModal)}
            />
          )}
        </div>
      </div>
    </>
  );
};

export default GoalDetails;
