import React, { useCallback, useEffect, useRef, useState } from "react";
import ListingCategories from "./ListingCategories";
import moment from "moment";
import AddCategoriesModal from "../ModalForm/TransactionModals/AddCategoriesModal";
import Amount from "../Amount.jsx";
import { ReactComponent as Check } from "../../assests/images/icons/check.svg";
import { ReactComponent as UnCheck } from "../../assests/images/icons/uncheck.svg";
import { categoryBackgroundCss } from "../../utilities/Statics/index.jsx";


export const TransactionListing = ({
  data,
  modalOpen,
  expenseCategories,
  updateTransactionCategory,
  addNewCategory,
  incomeCategories,
  isSelectionMode,
  setSelectionMode,
  setSelectedForBulkUpdate,
  selectedForBulkUpdate,
  updateCategoriesInBulk,
  isAccountModule = false,
}) => {
  const [showAddCatogory, setShowAddCatogory] = useState(false);
  const [popoverOpen, setPopoverOpen] = useState({});
  const [transactionType, setTransactionType] = useState();
  const listingCategoriesRef = useRef(null);
  const isHexColor = (color) => /^#([0-9A-Fa-f]{3}){1,2}$/.test(color);


  const togglePopover = (transactionId, amount) => {
    setPopoverOpen((prev) => ({
      ...prev,
      [transactionId]: !prev[transactionId],
    }));

    setTransactionType(amount < 0 ? "Expense" : "Income");
  };

  const handleAddCategory = () => {
    setShowAddCatogory(!showAddCatogory);
    setPopoverOpen({});
  };

  const handleLongPress = useCallback(() => {
    if (!isAccountModule) {
      setSelectionMode(true);
    }
  }, [setSelectionMode, isAccountModule]);

  const handleCardSelect = (id, amount) => {
    if (!isAccountModule) {
      const type = amount < 0 ? "Expense" : "Income";
      setSelectedForBulkUpdate((prevSelected) => {
        const newSelected = {
          ...prevSelected,
          [type]: [...prevSelected[type]],
        };
        if (newSelected[type].includes(id)) {
          newSelected[type] = newSelected[type].filter((item) => item !== id);
        } else {
          newSelected[type].push(id);
        }
        return newSelected;
      });
    }
  };

  const handleMouseDown = (e) => {
    if (e.button === 0) {
      e.currentTarget.longPressTimer = setTimeout(handleLongPress, 500);
    }
  };

  const handleMouseUp = (e) => {
    if (e.currentTarget.longPressTimer) {
      clearTimeout(e.currentTarget.longPressTimer);
    }
  };

  return (
    <div
      onMouseDown={handleMouseDown}
      onMouseUp={handleMouseUp}
      className="card-list"
    >
      {Object.keys(data).map((date) => (
        <div key={date}>
          <div className="acc-wrap-heading">{date}</div>
          {data[date].map((transaction) => (
            <div
              className="merchant-box mt-3"
              key={transaction.transactionUuid}
              onClick={() =>
                handleCardSelect(
                  transaction.transactionUuid,
                  transaction.amount
                )
              }
            >
              <div
                className="d-flex w-75"
                onClick={() => {
                  if (!isSelectionMode) {
                    modalOpen("viewTransaction", transaction);
                  }
                }}
              >
                <div className="img-box">
                  {isSelectionMode ? (
                    <div
                     
                    >
                      {selectedForBulkUpdate[
                        transaction.amount < 0 ? "Expense" : "Income"
                      ].includes(transaction.transactionUuid) ? (
                        <Check />
                      ) : (
                        <UnCheck />
                      )}
                    </div>
                  ) : (
                    <img
                      src={
                        transaction.merchantLogo ?? transaction.accountAvatar
                      }
                      alt=""
                      style={{ borderRadius: "50%" }}
                    />
                  )}
                </div>
                <div className="ms-3">
                  <div className="text-merchant bold black">
                    {transaction?.merchantName ?? transaction.transactionName}
                  </div>
                  <div className="small-text-merchant">
                    <Amount amount={transaction.amount} />
                    <span className="black ms-1">
                      <span className="black">|</span>
                    </span>
                    <h4 className="small-text-merchant space black">
                      <div className="setting_text_color-2">
                        {moment(transaction.dateTime).format("DD/MM/YYYY")}
                      </div>
                    </h4>
                    {transaction.status ==="Pending" && (
                      <div className="pending-item">P</div>
                    )} 
                  </div>
                </div>
              </div>
              <div className="button-blck">
                <div
                  className="icon-tag"
                  id={`Popover-${transaction.transactionUuid}`}
                  onClick={() =>
                    togglePopover(
                      transaction.transactionUuid,
                      transaction?.amount
                    )
                  }
                >
                  {transaction.masterCategoryName !== "Uncategorised" && (
                    <div
                    className={`icon-transaction-img ${isHexColor(transaction.masterCategoryColour) ? '' : categoryBackgroundCss(transaction.masterCategoryColour)}`}
                    style={{
                      background: transaction.status === "Pending"
                        ? "white"
                        : isHexColor(transaction.masterCategoryColour)
                          ? transaction.masterCategoryColour
                          : {},
                    }}
                    >
                      {transaction.status === "Pending" ? "⏳" :transaction.masterCategoryEmoji}
                    </div>
                   )} 

                  <div className="content">
                    <h6 className="icon-content">
                      {transaction.status ==="Pending" ? "Pending" :transaction.masterCategoryName}
                    </h6>
                  </div>
                </div>
                <div >
                <ListingCategories
                  categories={
                    transaction?.amount < 0
                      ? expenseCategories
                      : incomeCategories
                    }
                    updateTransactionCategory={updateTransactionCategory}
                    transactionId={transaction.transactionUuid}
                    togglePopover={togglePopover}
                    popoverOpen={popoverOpen}
                    showAddCatogory={showAddCatogory}
                    handleChange={handleAddCategory}
                    transactionType={transaction?.amount < 0 ? "Expense" : "Income"}
                    updateCategoriesInBulk={updateCategoriesInBulk}
                    isSelectionMode={isSelectionMode}
                    isPendingTransaction={transaction.status === "Pending"}
                    />
                    </div>
              </div>
            </div>
          ))}
        </div>
      ))}
      {showAddCatogory && (
        <AddCategoriesModal
          modalOpen={showAddCatogory}
          addNewCategory={addNewCategory}
          transactionType={transactionType}
          closeModal={handleAddCategory}
        />
      )}
    </div>
  );
};
