import React from "react";
import { formatNumberWithCommas } from "../../utilities/helper";

const Amount = ({ amount }) => {
  const isNegative = amount < 0;
  const absoluteAmount = Math.abs(amount);
  const formattedAmount = formatNumberWithCommas(absoluteAmount);
  const displayAmount = isNegative ? `-$${formattedAmount}` : `$${formattedAmount}`;
  return (
    <span className={amount < 0 ? "amount-red" : "amount-green"}>
      {displayAmount} 
    </span>
  );
};

export default Amount;
