import moment from "moment";
import React, { useState } from "react";
import ListingCategories from "../Transactions/ListingCategories";
import AddCategoriesModal from "../ModalForm/TransactionModals/AddCategoriesModal";

export const MerchantListing = ({
  merchant,
  onClick,
  merchantCategories,
  updateMerchantCategory,
  data,
  addNewCategory
}) => {
  const [showAddCatogory, setShowAddCatogory] = useState(false);
  const [popoverOpen, setPopoverOpen] = useState({});
  const [transactionType, setTransactionType] = useState();

  const togglePopover = (transactionId, amount) => {
    setPopoverOpen((prev) => ({
      ...prev,
      [transactionId]: !prev[transactionId],
    }));

    setTransactionType(amount < 0 ? "Expense" : "Income");
  };

  const handleAddCategory = () => {
    setShowAddCatogory(!showAddCatogory);
    setPopoverOpen({});
  };
  return (
    <div className="merchant-box mt-3" key={merchant.merchantsUuid}>
      <div className="d-flex w-75" onClick={() => onClick(merchant)}>
        <div className="img-box">
          <img src={merchant.logo} alt="" style={{ borderRadius: "50%" }} />
        </div>
        <div className="ms-3">
          <div className="text-merchant bold black">{merchant?.name}</div>
          <div className="small-text-merchant">
            <span
              className={
                merchant.amount < 0 ? "number-merchant" : "expense-amount"
              }
            >
              {merchant.amount}
            </span>
            <span className="black">|</span>
            <h4 className="small-text-merchant space black">
              <div className="setting_text_color-2">
                {moment(merchant.date).format("DD/MM/YYYY")}
              </div>
            </h4>
          </div>
        </div>
      </div>

      <div className="button-blck">
        <div
          className="icon-tag"
          id={`Popover-${merchant.merchantId}`}
          onClick={() => togglePopover(merchant.merchantId, merchant?.amount)}
        >
          <div
            className="icon-transaction-img"
            style={{ background: merchant.categoryColor }}
          >
            {merchant.categoryEmoji}
          </div>
          <div className="content">
            <h6 className="icon-content">{merchant.categoryName}</h6>
          </div>
        </div>
        <ListingCategories
          categories={merchantCategories}
          updateTransactionCategory={updateMerchantCategory}
          transactionId={merchant.merchantId}
          togglePopover={togglePopover}
          popoverOpen={popoverOpen}
          showAddCatogory={showAddCatogory}
          handleChange={handleAddCategory}
          transactionType={data?.amount < 0 ? "Expense" : "Income"}
        />
      </div>
      {showAddCatogory && (
        <AddCategoriesModal
          modalOpen={showAddCatogory}
          addNewCategory={addNewCategory}
          transactionType={"Expense"}
          closeModal={handleAddCategory}
        />
      )}
    </div>
  );
};
