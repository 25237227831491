import React, { useCallback, useEffect, useRef, useState } from "react";
import ListingCategories from "../../../Transactions/ListingCategories";
import AddCategoriesModal from "../../../ModalForm/TransactionModals/AddCategoriesModal";
import IncomeExpenseCategories from "./IncomeExpenseCategories";
import axios from "axios";
import weburls from "../../../Weburls/weburls";
import { useParams } from "react-router-dom";
import { toast } from "react-toastify";
import { ReactComponent as Wang } from "../../../../assests/images/icons/wand.svg";

const EditDetails = ({
  amount,
  handleAmountChange,
  selectedFrequency,
  toggleFrequencyModal,
  isSpreadToggled,
  getButtonBackgroundColor,
  handleToggleClick,
  needOrWant,
  handleNeedToggleClick,
  incomeExpenseBudgetsData,
  incomeExpenseBudgetsName,
  showCategory,
  expenseOrIncome,
  startDate,
  setStartDate,
  setEndDate,
  endDate,
  mainHead,
  setIncomeApi,
  setExpenseApi,
  autoExpense,
  autoSingleBudgetExpenses,
  handleNameChange,
  editName
}) => {
  const { uuid } = useParams();
  const endDateRef = useRef(null);
  const [showNeedOrWant, setShowNeedOrWant] = useState(false);
  const [popoverOpen, setPopoverOpen] = useState(false);
  const [selectedCategory, setSelectedCategory] = useState(null);
  const [isStartDateSelected, setIsStartDateSelected] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [showAddCatogory, setShowAddCatogory] = useState(false);
  const [categoryData, setCategoryData] = useState(null);

  useEffect(() => {
    if (incomeExpenseBudgetsName === "expense") {
      setShowNeedOrWant(true);
    } else {
      setShowNeedOrWant(false);
    }
  }, [incomeExpenseBudgetsName]);

  useEffect(() => {
    if (isStartDateSelected && endDateRef.current) {
      endDateRef.current.focus();
    }
  }, [isStartDateSelected]);

  const handleStartDateChange = (e) => {
    setStartDate(e.target.value);
    setIsStartDateSelected(true);
    setErrorMessage("");
  };

  const handleEndDateChange = (e) => {
    const selectedEndDate = e.target.value;

    if (new Date(selectedEndDate) < new Date(startDate)) {
      setErrorMessage("End date cannot be earlier than start date");
    } else {
      setEndDate(selectedEndDate);
      setErrorMessage("");
    }
  };

  const handleDateRangeClick = () => {
    setStartDate("");
    setEndDate("");
    setIsStartDateSelected(false);
    setErrorMessage("");
  };

  const formatDate = (dateString) => {
    if (!dateString) return "";
    const date = new Date(dateString);
    const day = String(date.getDate()).padStart(2, "0");
    const month = String(date.getMonth() + 1).padStart(2, "0");
    return `${day}/${month}`;
  };

  const togglePopover = () => {
    setPopoverOpen(!popoverOpen);
  };

  const handleCategoryClick = (category) => {
    setSelectedCategory(category);
    setIncomeApi({
      incomeName: category?.categoryName,
      user_category_id: category?.categoryId,
    });
    setExpenseApi({
      expenseName: category?.categoryName,
      user_category_id: category?.categoryId,
      isDefaultCategory: category?.defaultCategory,
    });
    togglePopover();
  };

  const handleAddCategory = () => {
    // setPopoverOpen((prevState) => ({
    //   ...prevState,
    // }));
    
    setShowAddCatogory(!showAddCatogory);
    setPopoverOpen({});
    togglePopover();
  };



  const defaultCategoriesData =
    incomeExpenseBudgetsName === "expense"
      ? categoryData?.expense
      : categoryData?.income;

  const yourCreatedCategoriesData =
    incomeExpenseBudgetsName === "expense"
      ? categoryData?.yourExpense
      : categoryData?.customCategory;

  // API Calls starts

  const incomeExpenseCategory = async () => {
    try {
      const url =
        incomeExpenseBudgetsName === "expense"
          ? `${weburls.Get_Expense_Remaining_By_UserUuid}${uuid}`
          : `${weburls.Get_Income_Remaining_By_UserUuid}${uuid}`;

      const response = await axios.get(url);

      if (response.status === 200) {
        if (response?.data?.code === 1) {
          setCategoryData(response?.data);
          // toast.success(response?.data?.message);
        } else if (response?.data?.code === 0) {
          toast.error(response?.data?.message);
        } else {
          toast.warn("Unexpected response from server");
        }
      } else {
        toast.error(`Error: ${response.statusText}`);
      }
    } catch (error) {
      console.error("API error:", error);
      toast.error("Something went wrong while fetching data");
    }
  };

  const addNewExpenseCategory = async (data) => {
    try {
      const response = await axios.post(
        `${weburls.Add_Expense_Category}${uuid}`,
        data
      );
      if (response?.data?.code === 1) {
        toast.success(`${response?.data?.message}`);
      }
      if (response?.data?.code === 0) {
        toast.error(`${response?.data?.message}`);
      }
      incomeExpenseCategory();
    } catch (error) {
      toast.error("Somthing went wronge while adding expense category");
    }
  };

  const addNewIncomeCategory = async (data) => {
    try {
      const response = await axios.post(
        `${weburls.Add_Income_Category}${uuid}`,
        data
      );
      if (response?.data?.code === 1) {
        toast.success(`${response?.data?.message}`);
      }
      if (response?.data?.code === 0) {
        toast.error(`${response?.data?.message}`);
      }
      incomeExpenseCategory();
    } catch (error) {
      toast.error("Somthing went wronge while adding expense category");
    }
  };
  const addNewCategory = (data, type) => {
    if (type === "Expense") {
      addNewExpenseCategory(data);
    } else {
      addNewIncomeCategory(data);
    }
  };


  // API Calls Ends

  useEffect(() => {
    incomeExpenseCategory();
  }, [incomeExpenseBudgetsName]);

console.log("-------------------> incomeExpenseBudgetsData", incomeExpenseBudgetsData);

  return (
    <>
      <div className="modal-input-group" style={{ marginBottom: "0px" }}>
        <h2
          style={{ fontSize: "20px", fontWeight: "500", marginBottom: "5px" }}
        >
          Enter details
        </h2>
        {autoExpense && (
          <div
            className=""
            style={{
              cursor: "pointer",
              backgroundColor: "#469B88",
              borderRadius: "360px",
              padding: "4px",
              paddingLeft: "8px",
              paddingRight: "8px",
              marginBottom: "10px",
            }}
            onClick={autoSingleBudgetExpenses}
          >
            <div
              style={{
                cursor: "pointer",

                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                gap: "4px",
              }}
            >
              <p
                style={{
                  fontSize: "14px",
                  fontWeight: "400",
                  marginBottom: "0px",
                  color: "white",
                }}
              >
                Auto expense
              </p>
              <Wang />
            </div>
          </div>
        )}
      </div>
      <div className="modal-section-card">
        {showCategory ? (
          <div>
            <div className="modal-input-group">
              <label>Category</label>

              {selectedCategory ? (
                <div
                  className="icon-tag"
                  id="PopUpoverTarget"
                  onClick={togglePopover}
                >
                  <div
                    className="icon-transaction-img"
                    style={{
                      backgroundColor: selectedCategory?.categoryColour,
                    }}
                  >
                    {selectedCategory?.categoryEmoji}
                  </div>
                  <div className="content">
                    <h6 className="icon-content">
                      {selectedCategory?.categoryName}
                    </h6>
                  </div>
                </div>
              ) : (
                <span id="PopUpoverTarget" onClick={togglePopover}>
                  Select or create a category
                </span>
              )}
            </div>

            <IncomeExpenseCategories
              togglePopover={togglePopover}
              isOpen={popoverOpen}
              mainHead={mainHead}
              handleCategoryClick={handleCategoryClick}
              defaultCategory={defaultCategoriesData}
              yourCreatedCategory={yourCreatedCategoriesData}
              handleChange={handleAddCategory}
            />

            {showAddCatogory && (
              <AddCategoriesModal
                modalOpen={showAddCatogory}
                addNewCategory={addNewCategory}
                transactionType={incomeExpenseBudgetsName.charAt(0).toUpperCase() + incomeExpenseBudgetsName.slice(1)}              
                closeModal={handleAddCategory}
              />
            )}
          </div>
        ) : expenseOrIncome === "oneoff" ? (
          <div className="modal-input-group">
            <label>Name</label>
            <input
              className="toggle-button"
              type="text"
              style={{
                width: "175px",
                textAlign: "center",
                border: "none",
                outline: "none",
                background: "#EFEDED",
              }}
              value={editName}
              onChange={handleNameChange}
            />
          </div>
        ) : (
          <div className="modal-input-group">
            <label>Category</label>
            <div className="button-blck">
              <div className="icon-tag">
                <div
                  className="icon-transaction-img"
                  style={{
                    backgroundColor: incomeExpenseBudgetsData?.category_colour,
                  }}
                >
                  {incomeExpenseBudgetsData?.category_emoji}
                </div>
                <div className="content">
                  <h6 className="icon-content hello">
                    {incomeExpenseBudgetsData?.category_name}
                  </h6>
                </div>
              </div>
            </div>
          </div>
        )}

        <div className="modal-input-group">
          <label>Amount</label>
          <input
            className="toggle-button"
            type="text"
            style={{
              width: "175px",
              textAlign: "center",
              border: "none",
              outline: "none",
              background: "#EFEDED",
            }}
            value={amount}
            onChange={handleAmountChange}
          />
        </div>
        {expenseOrIncome !== "oneoff" && (
          <div>
            <div className="modal-input-group">
              <label>How often?</label>
              <button
                className="toggle-button"
                type="button"
                style={{
                  borderRadius: "30px",
                  background: getButtonBackgroundColor(selectedFrequency),
                  color: "white",
                }}
                onClick={toggleFrequencyModal}
              >
                {selectedFrequency}
              </button>
            </div>
            {selectedFrequency === "Custom" && (
              <div style={{ display: "flex", flexDirection: "column" }}>
                <div className="modal-input-group">
                  <label>Date range?</label>

                  {!startDate || !endDate ? (
                    <>
                      {!isStartDateSelected ? (
                        <div>
                          <input
                            type="date"
                            value={startDate}
                            onChange={handleStartDateChange}
                            className="edit-goal-house  py-2 inter add-goal"
                            style={{
                              borderRadius: "10px",
                            }}
                          />
                          <p
                            style={{
                              fontSize: "10px",
                              color: "red",
                              marginBottom: "0px",
                            }}
                          >
                            Please select the start date
                          </p>
                        </div>
                      ) : (
                        <div>
                          <input
                            type="date"
                            value={endDate}
                            onChange={handleEndDateChange}
                            ref={endDateRef}
                            style={{ borderRadius: "10px" }}
                            className="edit-goal-house  py-2 inter add-goal"
                          />
                          <p
                            style={{
                              fontSize: "10px",
                              color: "red",
                              marginBottom: "0px",
                            }}
                          >
                            Please select the end date
                          </p>
                        </div>
                      )}
                    </>
                  ) : null}

                  {startDate && endDate && (
                    <div
                      onClick={handleDateRangeClick}
                      style={{
                        padding: "10px",
                        background: errorMessage ? "red" : "#f4f4f4",
                        borderRadius: "10px",
                        textAlign: "center",
                        marginBottom: "10px",
                        cursor: "pointer",
                        display: "flex",
                        flexDirection: "column",
                      }}
                    >
                      {formatDate(startDate)} - {formatDate(endDate)}
                    </div>
                  )}
                </div>
                {errorMessage && (
                  <p
                    style={{
                      color: "red",
                      fontSize: "12px",
                      marginLeft: "90px",
                    }}
                  >
                    {errorMessage}
                  </p>
                )}
              </div>
            )}

            {showNeedOrWant && (
              <div className="modal-input-group">
                <label>Need or want?</label>
                <button
                  className="toggle-button"
                  type="button"
                  style={{
                    borderRadius: "30px",
                    background: needOrWant ? "#C537C8" : "#3d6ae2",
                    color: "white",
                  }}
                  onClick={handleNeedToggleClick}
                >
                  {needOrWant ? "Need" : "Want"}
                </button>
              </div>
            )}
          </div>
        )}
        {expenseOrIncome !== "oneoff" && (
          <div className="modal-input-group">
            <label>Spread across periods</label>
            <button
              className="toggle-button"
              type="button"
              style={{
                borderRadius: "30px",
                background: isSpreadToggled ? "#479985" : "#E0533D",
                color: "white",
              }}
              onClick={handleToggleClick}
            >
              {isSpreadToggled ? "Yes" : "No"}
            </button>
          </div>
        )}
      </div>
    </>
  );
};

export default EditDetails;
