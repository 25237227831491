import React from "react";
import Shape from "../../assests/images/icons/shape.png";
import PopupInfoModal from "../Popup/PopupInfoModel";
import Profile from "../../assests/images/icons/image.png";
import { ReactComponent as InfoIcon } from "../../assests/images/icons/infoIcon.svg";
import { Link, useParams } from "react-router-dom";

const UserImageInfoToggle = ({
  isPopupOpenInfo,
  togglePopupInfo,
  title,
  description,
}) => {
  const { uuid } = useParams();

  return (
    <div className="col-lg-6 right-section search-spacing your-merchants-back-to-portal">
      <div className="setting_background_color right-section">
        <div
          className="profile-dashboard cursor-pointer"
          onClick={togglePopupInfo}
        >
          <InfoIcon />
        </div>

        {isPopupOpenInfo && (
          <PopupInfoModal
            isOpen={isPopupOpenInfo}
            onClose={togglePopupInfo}
            title={title}
            description={description}
          />
        )}
        <Link className="profile-dashboard" to={`/SettingPage/${uuid}`}>
          <div className="profile-img">
            <img src={Profile} alt="Profile" />
          </div>
        </Link>
      </div>
    </div>
  );
};

export default UserImageInfoToggle;
