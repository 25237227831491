import React, { useCallback, useEffect, useRef, useState } from "react";
import { useParams, useSearchParams } from "react-router-dom";
import Profile from "../../assests/images/icons/image.png";
import "./BudgetDetails.css";
import MainSidebar from "../MainSidebar";
import { ReactComponent as ArrowAccoor } from "../../assests/images/icons/accordionArrow.svg";
import { ReactComponent as OverBudget } from "../../assests/images/over_budget.svg";
import PersonalisedColorEmoji from "./PersonalisedColorEmoji";
import HowOften from "../Innerpanel/BudgetSummeryComponent/IncomeExpenseBudget/HowOften.jsx";
import TransactionViewModal from "../ModalForm/TransactionModals/TransactionViewModal";
import CategoryBreakdownSection from "../Moneyinsights/CategoryBreakdownSection";
import EditDetails from "../Innerpanel/BudgetSummeryComponent/IncomeExpenseBudget/EditDetails.jsx";
import SectionTitle from "../SectionTitle.jsx";
import { ReactComponent as Search } from "../../assests/images/icons/Search.svg";
import { TransactionListing } from "../Transactions/TransactionListing.jsx";
import weburls from "../Weburls/weburls.jsx";
import axios from "axios";
import { toast } from "react-toastify";
import OverlayContainer from "../OverlayContainer/OverlayContainer.jsx";
import { Loading } from "../Loading/Loading.jsx";
import { isEmpty, throttle } from "lodash";
import { useNavigate } from "react-router-dom";
import { BudgetBuddie } from "../Weburls/weburls.jsx";
import NoDataFound from "../NoDataFound/index.jsx";
import {
  groupTransactionsByDate,
  groupTransactionsByMonth,
} from "../../utilities/helper.jsx";
import {
  emojiBackgroundColor,
  lightBackgroundColor,
  progressbarColor,
} from "../../utilities/Statics/index.jsx";
import { PopUpForDelete, PopUpForUpdateDelete } from "./PopUpForUpdateDelete.jsx";

const BudgetDetails = () => {
  const sidebarRef = useRef(null);
  const { uuid } = useParams();
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const period = searchParams.get("period");
  const [incomeState, setIncomeState] = useState();
  const [filteredData, setFilteredData] = useState([]);
  const [selectedColorOption, setSelectedColorOption] = useState("Red");

  const [isPopupOpenColor, setPopupOpenColor] = useState(false);
  const togglePopupColor = () => setPopupOpenColor(!isPopupOpenColor);
  const [loading, setLoading] = useState(false);
  const [showPicker, setShowPicker] = useState(false);

  const [popoverOpen, setPopoverOpen] = useState({});
  const [showAddCategory, setShowAddCategory] = useState(false);
  const [needOrWant, setNeedOrWant] = useState("");
  const [dateRange, setDateRange] = useState("");

  const [chosenEmoji, setChosenEmoji] = useState(undefined);
  const [editAmount, setEditAmount] = useState(undefined);
  const [editName, setEditName] = useState(undefined);
  const [selectedFrequency, setSelectedFrequency] = useState(undefined);

  const [isSpreadToggled, setIsSpreadToggled] = useState(false);
  const [search, setSearch] = useState("");

  const [isPopupOpenTopExpenses, setPopupOpenTopExpenses] = useState(false);
  const [selectedTopExpensesOption, setSelectedTopExpensesOption] =
    useState(period);

  const [isFrequencyModalOpen, setFrequencyModalOpen] = useState(false);
  const [modals, setModals] = useState({
    merchantDetails: false,
    viewTransaction: false,
    addCategory: false,
  });
  const openModal = (modal, data) => {
    if (modal === "viewTransaction") {
      setSplitModalData(data);
    }
    setModals({ ...modals, [modal]: true });
  };
  const closeModal = (modal) => {
    setModals({ ...modals, [modal]: false });
  };

  const [hasMore, setHasMore] = useState(true);
  const [totalIncomes, setTotalIncomes] = useState(0);
  const [totalExpense, setTotalExpense] = useState(0);
  const [currentMonthGrouped, setCurrentMonthGrouped] = useState({});
  const [remainingMonthsGrouped, setRemainingMonthsGrouped] = useState({});
  const [category, setCategory] = useState([]);
  const [icomecat, setIcomCat] = useState([]);
  const [isSelectionMode, setSelectionMode] = useState(false);
  const [spliModalData, setSplitModalData] = useState();
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");

  const scrollContainerRef = useRef(null);
  const [page, setPage] = useState(1);

  const handleSearch = (e) => {
    setSearch(e.target.value);
    setPage(1);
  };

  const splitTransaction = async (splits, transactionId, type) => {
    try {
      const response = await axios.post(
        `${weburls.Split_Transaction}${uuid}/${transactionId}/${type}`,
        splits
      );
      if (response?.data?.code === 1) {
        toast.success(`${response?.data?.message}`);
      }
      closeModal("viewTransaction");
    } catch (error) {
      toast.error("Something went wrong while spliting transaction");
    }
  };

  const addNewCategory = (data, type) => {
    if (type === "Expense") {
      addNewExpenseCategory(data);
    } else {
      addNewIncomeCategory(data);
    }
  };
  const [selectedForBulkUpdate, setSelectedForBulkUpdate] = useState({
    Expense: [],
    Income: [],
  });

  const addNewExpenseCategory = async (data) => {
    try {
      const response = await axios.post(
        `${weburls.Add_Expense_Category}${uuid}`,
        data
      );
      if (response?.data?.code === 1) {
        toast.success(`${response?.data?.message}`);
      }
      if (response?.data?.code === 0) {
        toast.error(`${response?.data?.message}`);
      }
      getCategory();
    } catch (error) {
      toast.error("Somthing went wronge while adding expense category");
    }
  };

  const addNewIncomeCategory = async (data) => {
    try {
      const response = await axios.post(
        `${weburls.Add_Income_Category}${uuid}`,
        data
      );
      if (response?.data?.code === 1) {
        toast.success(`${response?.data?.message}`);
      }
      if (response?.data?.code === 0) {
        toast.error(`${response?.data?.message}`);
      }
      getCategory();
    } catch (error) {
      toast.error("Somthing went wronge while adding expense category");
    }
  };

  const getCategory = useCallback(async () => {
    try {
      const response = await axios.get(`${weburls.Get_All_Categories}${uuid}`);
      setCategory(response?.data?.data?.transaction?.Expense);
      setIcomCat(response?.data?.data?.transaction?.INCOME);
    } catch (err) {
      toast.error(err);
    }
  }, [uuid]);

  const currentUrl = window.location.href;

  const urlSegments = currentUrl.split("/");

  const expenseOrIncome = urlSegments[urlSegments.length - 2];

  const budgetId = urlSegments[urlSegments.length - 1].split("?")[0];

  const findBudgetData = (data, budgetId) => {
    const matchingItems = {};

    for (const key in data) {
      if (Array.isArray(data[key])) {
        const matched = data[key].filter(
          (item) =>
            item.income_uuid === budgetId ||
            item.expense_uuid === budgetId ||
            item.uuid === budgetId
        );

        if (matched.length > 0) {
          matchingItems[key] = matched;
        }
      }
    }

    return matchingItems;
  };

  const fetchIncomeBudgetData = useCallback(async () => {
    setLoading(true);
    try {
      const response = await axios.get(`${weburls.Get_Income_Budget}${uuid}`);

      if (response?.data?.code === "0") {
        toast.error(response?.data?.message || "An error occurred.", {
          position: "top-right",
          autoClose: 2000,
        });
      } else if (response?.data) {
        const budgetItems = findBudgetData(response?.data?.income, budgetId);
        setIncomeState(budgetItems);
      }
    } catch (error) {
      console.error("Error fetching income budget data:", error);
      toast.error("Something went wrong, please try again.", {
        position: "top-right",
        autoClose: 2000,
      });
    } finally {
      setLoading(false);
    }
  }, [uuid, budgetId]);

  const fetchExpenseBudgetData = useCallback(async () => {
    setLoading(true);
    try {
      const response = await axios.get(`${weburls.Get_Expense_Budget}${uuid}`);

      if (response?.data?.code === "0") {
        toast.error(response?.data?.message || "An error occurred.", {
          position: "top-right",
          autoClose: 2000,
        });
      } else if (response?.data) {
        const budgetItems = findBudgetData(response?.data?.expenses, budgetId);
        setIncomeState(budgetItems);
      }
    } catch (error) {
      console.error("Error fetching Expense budget data:", error);
      toast.error("Something went wrong, please try again.", {
        position: "top-right",
        autoClose: 2000,
      });
    } finally {
      setLoading(false);
    }
  }, [uuid, budgetId]);

  const fetchOneOffBudgetData = useCallback(async () => {
    setLoading(true);
    try {
      const response = await axios.get(`${weburls.Get_OneOff_Budget}${uuid}`);

      if (response?.data?.code === "0") {
        toast.error(response?.data?.message || "An error occurred.", {
          position: "top-right",
          autoClose: 2000,
        });
      } else if (response?.data) {
        const budgetItems = findBudgetData(
          response?.data?.oneOffBudget,
          budgetId
        );
        setIncomeState(budgetItems);
      }
    } catch (error) {
      console.error("Error fetching Expense budget data:", error);
      toast.error("Something went wrong, please try again.", {
        position: "top-right",
        autoClose: 2000,
      });
    } finally {
      setLoading(false);
    }
  }, [uuid, budgetId]);

  useEffect(() => {
    if (uuid) {
      if (expenseOrIncome === "expense") {
        fetchExpenseBudgetData();
      } else if (expenseOrIncome === "income") {
        fetchIncomeBudgetData();
      } else {
        fetchOneOffBudgetData();
      }
    }
  }, [
    uuid,
    fetchExpenseBudgetData,
    fetchIncomeBudgetData,
    fetchOneOffBudgetData,
  ]);

  let progress;

  const categoryType = filteredData[0]?.category_type?.toLowerCase();

  const adjustedAmount =
    categoryType === "income"
      ? filteredData[0]?.adjusted_income_amount
      : categoryType === "expense"
      ? filteredData[0]?.adjusted_expense_amount -
        filteredData[0]?.expense_amount
      : filteredData[0]?.amount - filteredData[0]?.total_transaction_amount;

  const totalAmount =
    categoryType === "income"
      ? filteredData[0]?.income_amount
      : categoryType === "expense"
      ? filteredData[0]?.expense_amount
      : filteredData[0]?.amount;

  if (adjustedAmount < 0) {
    progress = 100;
  } else {
    progress = (adjustedAmount / totalAmount) * 100;

    progress = progress > 100 ? 100 : progress;
  }

  const handleOptionColorClick = (option) => {
    setSelectedColorOption(option);
    setPopupOpenColor(false);
  };

  const handleEmojiClick = (event) => {
    const { emoji } = event;
    setChosenEmoji(emoji);
    setShowPicker(false);
  };

  const toggleFrequencyModal = () => {
    setFrequencyModalOpen((prev) => !prev);
  };

  const handleFrequencySelection = (frequency) => {
    setSelectedFrequency(frequency);
    setFrequencyModalOpen(false);
  };

  const getButtonBackgroundColor = (frequency) => {
    switch (frequency) {
      case "Weekly":
        return "#479985";
      case "Fortnightly":
        return "#3de1bc";
      case "Monthly":
        return "#3d6ae2";
      case "Quarterly":
        return "#3eb1e1";
      case "Annual":
        return "#39e16f";
      case "Custom":
        return "#8D83FE";
      default:
        return "#479985";
    }
  };

  const togglePopupTopExpenses = () =>
    setPopupOpenTopExpenses(!isPopupOpenTopExpenses);

  const handleOptionTopExpensesClick = (option) => {
    setSelectedTopExpensesOption(option);
    let data;
    if (filteredData) {
      switch (option) {
        case "This Week":
          data = incomeState.thisWeek ?? [];
          break;
        case "Last Week":
          data = incomeState.lastWeek ?? [];
          break;
        case "Quarter":
          data = incomeState.quarter ?? [];
          break;
        case "Last Quarter":
          data = incomeState.lastQuarter ?? [];
          break;
        case "This Month":
          data = incomeState.monthly ?? [];
          break;
        case "Last Month":
          data = incomeState.lastMonth ?? [];
          break;
        case "This Year":
          data = incomeState.thisYear ?? [];
          break;
        case "Last Fortnightly":
          data = incomeState.lastFortnightly ?? [];
          break;
        case "This Fortnightly":
          data = incomeState.thisFortnightly ?? [];
          break;
        default:
          data = [];
          break;
      }
    }
    setFilteredData(data);
    setPopupOpenTopExpenses(false);
  };

  const transaction = {
    transactionUuid: 1,
    amount: 100,
  };

  const incomeCategories = [
    { userCategoryId: 1, name: "Salary", emoji: "💰", colour: "green" },
  ];
  const expenseCategories = [
    { userCategoryId: 2, name: "Groceries", emoji: "🛒", colour: "red" },
  ];

  const togglePopover = (transactionId) => {
    setPopoverOpen((prevState) => ({
      ...prevState,
      [transactionId]: !prevState[transactionId],
    }));
  };

  const handleAddCategory = (transactionId) => {
    setPopoverOpen((prevState) => ({
      ...prevState,
      [transactionId]: false,
    }));
    setShowAddCategory(!showAddCategory);
  };

  const updateTransactionCategory = async (transactionId, categoryId, type) => {
    const data = {
      transactionUuid: transactionId,
      categoryId: categoryId,
      type: type,
    };
    try {
      const response = await axios.post(
        `${weburls.Update_Category}${uuid}`,
        data
      );
      toast.success(`${response?.data?.message}`);
    } catch (error) {}
  };

  const handleAmountChange = (e) => {
    const value = e.target.value;

    const numericValue = value.replace(/^\$/, "");

    if (/^\d*\.?\d*$/.test(numericValue)) {
      setEditAmount(`$${numericValue}`);
    }
  };

  const handleNameChange = (e) => {
    setEditName(e.target.value);
  };

  const handleEditToggleClick = () => {
    setIsSpreadToggled(!isSpreadToggled);
  };

  const handleNeedToggleClick = () => {
    setNeedOrWant(!needOrWant);
  };

  const fetchIncomeTransactionsData = useCallback(
    async (page = 1) => {
      setLoading(true);
      try {
        const response = await axios.get(
          `${weburls.Get_Income_Transactions}${uuid}/${budgetId}/10/${page}?search=${search}`
        );

        if (response?.data?.code === "0") {
          toast.error(response?.data?.message || "An error occurred.", {
            autoClose: 2000,
          });
        } else if (response?.data) {
          const transactionsThisMonth =
            response?.data?.data?.transactions?.thisMonth || [];
          const transactionsRemainingMonths =
            response?.data?.data?.transactions?.remainingMonths || [];

          const groupedCurrentMonth = groupTransactionsByDate(
            transactionsThisMonth
          );
          const groupedRemainingMonths = groupTransactionsByMonth(
            transactionsRemainingMonths
          );

          setCurrentMonthGrouped((prev) => ({
            ...prev,
            ...groupedCurrentMonth.data,
          }));

          setRemainingMonthsGrouped((prev) => ({
            ...prev,
            ...groupedRemainingMonths.data,
          }));

          setTotalIncomes(
            totalIncomes +
              groupedCurrentMonth.incomeCount +
              groupedRemainingMonths.incomeCount
          );
          setTotalExpense(
            totalExpense +
              groupedCurrentMonth.expenseCount +
              groupedRemainingMonths.expenseCount
          );

          setHasMore(
            transactionsThisMonth.length > 0 ||
              transactionsRemainingMonths.length > 0
          );
        }
      } catch (error) {
        toast.error("Error fetching income data.");
      } finally {
        setLoading(false);
      }
    },
    [uuid, search]
  );

  const fetchExpenseTransactionsData = useCallback(
    async (page = 1) => {
      setLoading(true);
      try {
        const response = await axios.get(
          `${weburls.Get_Expense_Transactions}${uuid}/${budgetId}/10/${page}?search=${search}`
        );

        if (response?.data?.code === "0") {
          toast.error(response?.data?.message || "An error occurred.", {
            autoClose: 2000,
          });
        } else if (response?.data) {
          const transactionsThisMonth =
            response?.data?.data?.transactions?.thisMonth || [];
          const transactionsRemainingMonths =
            response?.data?.data?.transactions?.remainingMonths || [];

          const groupedCurrentMonth = groupTransactionsByDate(
            transactionsThisMonth
          );
          const groupedRemainingMonths = groupTransactionsByMonth(
            transactionsRemainingMonths
          );

          setCurrentMonthGrouped((prev) => ({
            ...prev,
            ...groupedCurrentMonth.data,
          }));

          setRemainingMonthsGrouped((prev) => ({
            ...prev,
            ...groupedRemainingMonths.data,
          }));

          setTotalIncomes(
            (prev) =>
              prev +
              groupedCurrentMonth.incomeCount +
              groupedRemainingMonths.incomeCount
          );
          setTotalExpense(
            (prev) =>
              prev +
              groupedCurrentMonth.expenseCount +
              groupedRemainingMonths.expenseCount
          );

          setHasMore(
            transactionsThisMonth.length > 0 ||
              transactionsRemainingMonths.length > 0
          );
        }
      } catch (error) {
        toast.error("Error fetching expense data.");
      } finally {
        setLoading(false);
      }
    },
    [uuid, search]
  );

  const fetchOneOffTransactionsData = useCallback(
    async (page = 1) => {
      setLoading(true);
      try {
        const response = await axios.get(
          `${weburls.Get_OneOff_Transactions}${uuid}/${budgetId}/10/${page}?search=${search}`
        );

        if (response?.data?.code === "0") {
          toast.error(response?.data?.message || "An error occurred.", {
            autoClose: 2000,
          });
        } else if (response?.data) {
          const transactionsThisMonth =
            response?.data?.data?.transactions?.thisMonth || [];
          const transactionsRemainingMonths =
            response?.data?.data?.transactions?.remainingMonths || [];

          const groupedCurrentMonth = groupTransactionsByDate(
            transactionsThisMonth
          );
          const groupedRemainingMonths = groupTransactionsByMonth(
            transactionsRemainingMonths
          );

          setCurrentMonthGrouped((prev) => ({
            ...prev,
            ...groupedCurrentMonth.data,
          }));

          setRemainingMonthsGrouped((prev) => ({
            ...prev,
            ...groupedRemainingMonths.data,
          }));

          setTotalIncomes(
            totalIncomes +
              groupedCurrentMonth.incomeCount +
              groupedRemainingMonths.incomeCount
          );
          setTotalExpense(
            totalExpense +
              groupedCurrentMonth.expenseCount +
              groupedRemainingMonths.expenseCount
          );

          setHasMore(
            transactionsThisMonth.length > 0 ||
              transactionsRemainingMonths.length > 0
          );
        }
      } catch (error) {
        toast.error("Error fetching income data.");
      } finally {
        setLoading(false);
      }
    },
    [uuid, search]
  );

  useEffect(() => {
    if (page === 1) return;

    if (expenseOrIncome === "expense") {
      fetchExpenseTransactionsData(page);
    } else if (expenseOrIncome === "income") {
      fetchIncomeTransactionsData(page);
    } else {
      fetchOneOffTransactionsData(page);
    }
  }, [
    page,
    expenseOrIncome,
    fetchExpenseTransactionsData,
    fetchIncomeTransactionsData,
    fetchOneOffTransactionsData,
  ]);

  useEffect(() => {
    if (uuid && page === 1) {
      if (expenseOrIncome === "expense") {
        fetchExpenseTransactionsData(1);
      } else if (expenseOrIncome === "income") {
        fetchIncomeTransactionsData(1);
      } else {
        fetchOneOffTransactionsData(1);
      }
    }
  }, [uuid, expenseOrIncome]);

  useEffect(() => {
    const handleScroll = throttle(() => {
      if (scrollContainerRef.current) {
        const { scrollTop, scrollHeight, clientHeight } =
          scrollContainerRef.current;

        if (
          scrollTop + clientHeight >= scrollHeight - 1 &&
          !loading &&
          hasMore
        ) {
          setPage((prevPage) => prevPage + 1);
        }
      }
    }, 200);

    const container = scrollContainerRef.current;
    if (container) {
      container.addEventListener("scroll", handleScroll);
    }

    return () => {
      if (container) {
        container.removeEventListener("scroll", handleScroll);
      }
    };
  }, [loading, hasMore]);

  useEffect(() => {
    getCategory();
  }, [getCategory]);

  const flattenAndFilterTransactions = (groupedTransactions) => {
    let filteredTransactions = {};

    for (let month in groupedTransactions) {
      if (Array.isArray(groupedTransactions[month])) {
        if (!search.trim()) {
          filteredTransactions[month] = groupedTransactions[month];
        } else {
          const filteredForMonth = groupedTransactions[month].filter(
            (transaction) =>
              transaction.transactionName
                ?.toLowerCase()
                .includes(search.toLowerCase())
          );

          if (filteredForMonth.length > 0) {
            filteredTransactions[month] = filteredForMonth;
          }
        }
      }
    }

    return filteredTransactions;
  };

  const filteredCurrentMonthTransactions =
    flattenAndFilterTransactions(currentMonthGrouped);
  const filteredRemainingMonthTransactions = flattenAndFilterTransactions(
    remainingMonthsGrouped
  );

  useEffect(() => {
    if (incomeState && period) {
      const dataForPeriod = incomeState[selectedTopExpensesOption] || [];
      setFilteredData(dataForPeriod);

      if (dataForPeriod.length > 0) {
        setChosenEmoji(dataForPeriod[0]?.category_emoji);

        const amount =
          dataForPeriod[0]?.category_type?.toLowerCase() === "expense"
            ? dataForPeriod[0]?.expense_amount
            : dataForPeriod[0]?.category_type?.toLowerCase() === "income"
            ? dataForPeriod[0]?.income_amount
            : dataForPeriod[0]?.amount;
        setEditAmount(amount !== undefined ? `$${amount}` : "");

        const name =
          dataForPeriod[0]?.category_type?.toLowerCase() === "oneoff"
            ? dataForPeriod[0]?.name
            : "";

        setEditName(name);

        setSelectedFrequency(dataForPeriod[0]?.how_often);
        setSelectedColorOption(dataForPeriod[0]?.category_colour);
      }
    }
  }, [incomeState, period]);

  const [showUpdatePopup, setShowUpdatePopup] = useState(false);
  const [showDeletePopup, setShowDeletePopup] = useState(false);
  const [updateOrDeleteText, setUpdateOrDeleteText] = useState("");

  const handleSaveChanges = async () => {
    if (expenseOrIncome === "oneoff") {
      await updateIncomeOrExpenseBudget(true);
    } else {
      setUpdateOrDeleteText("Update");
      setShowUpdatePopup(true);
    }
  };

  const handleDeleteBudget = () => {
    setShowDeletePopup(true);
  };

  const handleDeleteConfirm = async () => {
    setShowDeletePopup(false);

    if (expenseOrIncome === "oneoff") {
      await deleteIncomeOrExpenseBudget(true);
      setShowUpdatePopup(false);
    } else {
      setUpdateOrDeleteText("Delete");
      setShowUpdatePopup(true);
    }
  };

  const handleDeleteCancel = () => {
    setShowDeletePopup(false);
  };

  const handleUpdateCashflowYes = async () => {
    setShowUpdatePopup(false);
    if (updateOrDeleteText === "Delete") {
      await deleteIncomeOrExpenseBudget(true);
    } else {
      await updateIncomeOrExpenseBudget(true);
    }
  };

  const handleUpdateCashflowNo = () => {
    setShowUpdatePopup(false);
  };

  const updateIncomeOrExpenseBudget = async (isCashflow) => {
    try {
      const formattedAmount = editAmount.replace(/^\$/, "");
      const payload =
        expenseOrIncome === "expense"
          ? {
              userUuid: uuid,
              expenseUuid: budgetId,
              expenseName: filteredData[0]?.expense_Name,
              emoji: chosenEmoji,
              colour: selectedColorOption,
              expenseAmount: formattedAmount,
              howOften: selectedFrequency,
              isCashflow: isCashflow,
              user_category_id: filteredData[0]?.user_category_id,
              isDefaultCategory: filteredData[0]?.is_default_category,
            }
          : expenseOrIncome === "income"
          ? {
              userUuid: uuid,
              incomeUuid: budgetId,
              incomeName: filteredData[0]?.income_name,
              emoji: chosenEmoji,
              colour: selectedColorOption,
              incomeAmount: formattedAmount,
              howOften: selectedFrequency,
              isCashflow: isCashflow,
            }
          : {
              name: editName,
              amount: formattedAmount,
              colour: selectedColorOption,
              emoji: chosenEmoji,
            };

      const url =
        expenseOrIncome === "expense"
          ? `${weburls.Update_Expense_Budget}`
          : expenseOrIncome === "income"
          ? `${weburls.Update_Income_Budget}${uuid}/${budgetId}`
          : `${weburls.Update_OneOff_Budget}${budgetId}/${uuid}`;

      const response = await axios.put(url, payload);

      if (response?.data?.code === 1) {
        toast.success(`${response?.data?.message}`);
        window.location.reload();
      } else if (response?.data?.code === 0) {
        toast.error(`${response?.data?.message}`);
      }
    } catch (error) {
      toast.error(
        `Something went wrong while updating ${expenseOrIncome} category`
      );
    }
  };

  const deleteIncomeOrExpenseBudget = async (isCashflow) => {
    try {
      const payload =
        expenseOrIncome === "expense"
          ? {
              isCashflow: isCashflow,
            }
          : expenseOrIncome === "income"
          ? {
              IsDeleteCategory: true,
              isCashflow: isCashflow,
            }
          : null;

      const url =
        expenseOrIncome === "expense"
          ? `${weburls.Delete_Expense_Budget}${uuid}/${budgetId}`
          : expenseOrIncome === "income"
          ? `${weburls.Delete_Income_Budget}${uuid}/${budgetId}`
          : `${weburls.Delete_OneOff_Budget}${budgetId}/${uuid}`;

      const response = await axios.delete(url, { data: payload });

      if (response?.data?.code === 1) {
        toast.success(`${response?.data?.message}`);
        // navigate(`${BudgetBuddie}BudgetSummary/f${uuid}`);
        navigate(`/BudgetSummary/${uuid}`, { replace: true });
      } else if (response?.data?.code === 0) {
        toast.error(`${response?.data?.message}`);
      }
    } catch (error) {
      toast.error(
        `Something went wrong while deleting ${expenseOrIncome} category`
      );
    }
  };

  return (
    <div className="container-scroller" ref={sidebarRef}>
      <div className="container-fluid">
        <div className="appmain mainsidebar main-wrapper">
          <MainSidebar uuid={uuid} />
          <div className="app-main__outer edit-cashflow-container-main">
            <div className="row top-nav">
              <div className="col-md-4 edit-cashflow-title-heading">
                <div className="bold big-head-new">
                  {filteredData[0]?.category_name} budget
                </div>
                <div className="fst-normal setting_text_color mt-1">
                  Make any changes to personalise your budget.
                </div>
              </div>
              <div className="col-md-8 right-section back-portal-edit-cashflow">
                <div className="right-section">
                  <div className="modal-actions" style={{ marginTop: "0px" }}>
                    <button
                      type="button"
                      className="save-button"
                      onClick={handleSaveChanges}
                    >
                      Save changes
                    </button>
                    <button
                      type="button"
                      className="next-button"
                      onClick={handleDeleteBudget}
                    >
                      Delete budget
                    </button>
                  </div>
                  <div
                    className="profile-dashboard"
                    style={{ marginTop: "0px" }}
                  >
                    <div className="profile-img">
                      <img src={Profile} alt="profile" />
                    </div>
                  </div>
                </div>
              </div>
              {showDeletePopup && (
                <PopUpForDelete
                  handleDeleteCancel={handleDeleteCancel}
                  handleDeleteConfirm={handleDeleteConfirm}
                />
              )}

              {showUpdatePopup && (
                <PopUpForUpdateDelete
                  updateOrDeleteText={updateOrDeleteText}
                  handleUpdateCashflowNo={handleUpdateCashflowNo}
                  handleUpdateCashflowYes={handleUpdateCashflowYes}
                />
              )}
            </div>

            {/* Expense Form */}

            <div className="row mt-4 ">
              {/* Income Budgets Section */}
              <div className="col-md-8">
                <div className="col-md-11">
                  {expenseOrIncome === "oneoff" ? (
                    <div className="mt-4">
                      <h2
                        style={{
                          marginBottom: "10px",
                          fontSize: "20px",
                          fontweight: "500",
                        }}
                      >
                        One off budget
                      </h2>
                    </div>
                  ) : (
                    <CategoryBreakdownSection
                      togglePopupTopExpenses={togglePopupTopExpenses}
                      isPopupOpenTopExpenses={isPopupOpenTopExpenses}
                      selectedTopExpensesOption={selectedTopExpensesOption}
                      handleOptionTopExpensesClick={
                        handleOptionTopExpensesClick
                      }
                      heading={
                        expenseOrIncome === "expense"
                          ? "Spend vs budget"
                          : "Income vs budget"
                      }
                    />
                  )}
                  <div className="budget-item-card">
                    <div className="budget-item" style={{ cursor: "pointer" }}>
                      <div className="budget-label">
                        <div className="pay-budget-sec">
                          <div
                            className={`budget-icon ${emojiBackgroundColor(
                              filteredData[0]?.category_colour
                            )}`}
                            style={
                              {
                                // backgroundColor: "#D3D3D3",
                              }
                            }
                          >
                            {filteredData[0]?.category_emoji}
                          </div>
                          <div>
                            <h6>{filteredData[0]?.category_name}</h6>
                            <h4>${totalAmount}</h4>
                          </div>
                        </div>
                        <p className="header-p" style={{ cursor: "pointer" }}>
                          <div className="account-number inter text-16px">
                            <div className="button-blck">
                              <ArrowAccoor />
                            </div>
                          </div>
                        </p>
                      </div>
                      <div className="budget-remaining">
                        {expenseOrIncome === "oneoff" ? (
                          <>${Math.max(0, adjustedAmount)} remaining</>
                        ) : (
                          <>
                            {adjustedAmount < 0 ? (
                              filteredData[0]?.category_type === "INCOME" ? (
                                `You've earned $${Math.abs(
                                  adjustedAmount
                                )} more`
                              ) : (
                                <>
                                  {`You're $${Math.abs(
                                    adjustedAmount
                                  )} over budget`}
                                  <OverBudget
                                    style={{
                                      marginLeft: "1px",

                                      marginTop: "2px",
                                    }}
                                  />
                                  {/* Display the icon */}
                                </>
                              )
                            ) : (
                              `$${adjustedAmount} remaining`
                            )}
                          </>
                        )}
                      </div>

                      <div
                        className={`budget-progress-container ${lightBackgroundColor(
                          filteredData[0]?.category_colour
                        )}`}
                        // style={{ backgroundColor: "#D3D3D3" }}
                      >
                        <div
                          className={`budget-progress ${progressbarColor(
                            filteredData[0]?.category_colour
                          )}`}
                          style={{
                            width: `${progress}%`,
                            // backgroundColor: `${filteredData[0]?.category_colour}`,
                          }}
                        ></div>
                      </div>
                    </div>
                  </div>
                  <div className="search-spacing filter-merchants-wrp-side mt-4">
                    <div className="row">
                      <div className="col-md-7 col-lg-12">
                        <SectionTitle title={"Transactions"} />
                        <div>
                          <div className="search-merchant your-merchant-search">
                            <form>
                              <input
                                type="text"
                                value={search}
                                className="search search-color-white"
                                onChange={(e) => handleSearch(e)}
                              />
                              <Search className="search-merchant" />
                            </form>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="account-detail-left-wrapper mt-5">
                      {!isEmpty(currentMonthGrouped) ||
                      !isEmpty(remainingMonthsGrouped) ? (
                        <TransactionListing
                          data={{
                            ...filteredCurrentMonthTransactions,
                            ...filteredRemainingMonthTransactions,
                          }}
                          modalOpen={openModal}
                          expenseCategories={category}
                          updateTransactionCategory={updateTransactionCategory}
                          addNewCategory={addNewCategory}
                          incomeCategories={icomecat}
                          setSelectionMode={setSelectionMode}
                          isSelectionMode={isSelectionMode}
                          setSelectedForBulkUpdate={setSelectedForBulkUpdate}
                        />
                      ) : (
                        <NoDataFound />
                      )}
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-md-4">
                <EditDetails
                  transaction={transaction}
                  togglePopover={togglePopover}
                  expenseCategories={expenseCategories}
                  incomeCategories={incomeCategories}
                  updateTransactionCategory={updateTransactionCategory}
                  popoverOpen={popoverOpen}
                  showAddCategory={showAddCategory}
                  handleAddCategory={handleAddCategory}
                  amount={editAmount}
                  handleAmountChange={handleAmountChange}
                  selectedFrequency={selectedFrequency}
                  toggleFrequencyModal={toggleFrequencyModal}
                  isSpreadToggled={isSpreadToggled}
                  getButtonBackgroundColor={getButtonBackgroundColor}
                  handleToggleClick={handleEditToggleClick}
                  handleNeedToggleClick={handleNeedToggleClick}
                  needOrWant={needOrWant}
                  incomeExpenseBudgetsName={filteredData[0]?.category_type
                    ?.trim()
                    .toLowerCase()}
                  incomeExpenseBudgetsData={filteredData[0]}
                  showCategory={false}
                  expenseOrIncome={expenseOrIncome}
                  startDate={startDate}
                  setStartDate={setStartDate}
                  endDate={endDate}
                  setEndDate={setEndDate}
                  autoExpense={false}
                  handleNameChange={handleNameChange}
                  editName={editName}
                />

                <PersonalisedColorEmoji
                  selectedColorOption={selectedColorOption}
                  togglePopupColor={togglePopupColor}
                  isPopupOpenColor={isPopupOpenColor}
                  handleOptionColorClick={handleOptionColorClick}
                  setShowPicker={setShowPicker}
                  showPicker={showPicker}
                  chosenEmoji={chosenEmoji}
                  handleEmojiClick={handleEmojiClick}
                />
              </div>
            </div>

            {/* Frequency Selection Modal */}

            {isFrequencyModalOpen && (
              <HowOften
                toggleFrequencyModal={toggleFrequencyModal}
                handleFrequencySelection={handleFrequencySelection}
                style={{ left: "81.5%", top: "75px" }}
              />
            )}
            {modals.viewTransaction && (
              <TransactionViewModal
                data={spliModalData}
                modalOpen={modals.viewTransaction}
                closeModal={closeModal}
                splitTransaction={splitTransaction}
              />
            )}
          </div>
          {loading && (
            <OverlayContainer>
              <div>
                <Loading />
              </div>
            </OverlayContainer>
          )}
        </div>
      </div>
    </div>
  );
};

export default BudgetDetails;
