import React, { useState } from "react";
import "react-datepicker/dist/react-datepicker.css";
import * as yup from "yup";
import "../AddIncome/addIncome.css";

import { MerchantListing } from "../../Merchats/MerchantListing";
import MerchantTransactions from "./MerchantTransactions";
import TransactionViewModal from "../TransactionModals/TransactionViewModal";
const schema = yup.object().shape({
  assetsName: yup.string().required("*Please enter assets name"),
  value: yup
    .string()
    .required("*Please enter asset value")
    .matches(/^[0-9]+$/, "*Please enter number only"),
});

const MerchantDetail = ({
  modalOpen,
  closeModal,
  merchant,
  merchantTransactions,
  handleTransactionClick,
}) => {

  return (
    <div className={`modal-background ${modalOpen ? "open-modal" : ""} `}>
      <div className="modal-content merchant-modal-width">
        <button
          onClick={() => closeModal("merchantDetails")}
          className="close-pass-button"
        >
          <i className="fa fa-times"></i>
        </button>
        <div className="asset-detail">
          <div className="d-flex justify-content-between">
            <div className="fs-3">
              <span className="bold black password-heading">
                Merchant Details
              </span>
              <p className="trans-modal-para">
                View all the details for your merchant.
              </p>
            </div>
            <button
              type="submit"
              className="add-transaction-btn cursor-pointer me-4"
            >
              Save changes
            </button>
          </div>
          <div className="black account-bal-title mt-1">Your Merchant</div>
          <MerchantListing merchant={merchant} />
        </div>
        <div className="black account-bal-title mer-title">Transactions</div>
        <div>
          <div className="mer-list">
            {merchantTransactions?.map((ele, index) => (
              <MerchantTransactions
                transaction={ele}
                key={index}
                onClick={handleTransactionClick}
              />
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default MerchantDetail;
