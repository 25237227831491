import React, { useState, useEffect } from "react";
import { ReactComponent as ArrowAccoor } from "../../../assests/images/icons/accordionArrow.svg";
import "../BudgetSummary.css";
import IncomeExpenseBudgetModal from "./IncomeExpenseBudget/IncomeExpenseBudgetModal";
import { useNavigate } from "react-router-dom";
import { ReactComponent as OverBudget } from "../../../assests/images/over_budget.svg";
import {
  emojiBackgroundColor,
  progressbarColor,
} from "../../../utilities/Statics";
import { lightBackgroundColor } from "../../../utilities/Statics";

const BudgetsComponent = ({
  incomeExpenseBudgetsData = [],
  mainHeading,
  isIncome,
  setTotalAmount,
  setRemainingAmount,
  selectedPeriod,
  showFields,
  uuid,
  fetchBudgetData,
}) => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const navigate = useNavigate();
  const toggleModal = () => {
    setIsModalOpen(!isModalOpen);
  };

  const handleCardClick = (budgetId, incomeExpenseBudgetsData) => {
    const categoryType = incomeExpenseBudgetsData?.category_type?.toLowerCase();

    if (categoryType) {
      navigate(
        `/BudgetSummary/${uuid}/budget-details/${categoryType}/${budgetId}?period=${selectedPeriod}`
      );
    } else {
      console.error("Error: category_type is undefined");
    }
  };

  const totalAmount = incomeExpenseBudgetsData?.length
    ? incomeExpenseBudgetsData.reduce(
        (total, budget) =>
          total +
          (isIncome ? budget.income_amount : budget.adjusted_expense_amount),
        0
      )
    : 0;

  const totalAdjustedAmountAccumulated = incomeExpenseBudgetsData?.reduce(
    (total, budget) => {
      const adjustedAmount = isIncome
        ? budget.adjusted_income_amount
        : budget.adjusted_expense_amount - budget.expense_amount;
      return total + adjustedAmount;
    },
    0
  );

  const roundedAdjustedAmount = totalAdjustedAmountAccumulated.toFixed(2);

  let progress;
  if (totalAdjustedAmountAccumulated < 0) {
    progress = 100;
  } else {
    progress = (totalAdjustedAmountAccumulated / totalAmount) * 100;

    progress = progress > 100 ? 100 : progress;
  }

  useEffect(() => {
    if (typeof setTotalAmount === "function") {
      setTotalAmount((prevState) => ({
        ...prevState,
        [isIncome ? "income" : "expense"]: totalAmount,
      }));
    }
  }, [totalAmount, isIncome, setTotalAmount]);

  useEffect(() => {
    if (typeof setRemainingAmount === "function") {
      if (isIncome) {
        const totalIncome = incomeExpenseBudgetsData.reduce(
          (total, budget) => total + (budget.income_amount || 0),
          0
        );

        setRemainingAmount((prevState) => ({
          ...prevState,
          income: totalIncome,
        }));
      } else {
        const totalExpense = incomeExpenseBudgetsData.reduce(
          (total, budget) => total + (budget.expense_amount || 0),
          0
        );

        setRemainingAmount((prevState) => ({
          ...prevState,
          expense: totalExpense,
        }));
      }
    }
  }, [incomeExpenseBudgetsData, isIncome, setRemainingAmount]);

  return (
    <div className="col-md-12">
      <div className="budget-breakdown-header">
        <h4>{mainHeading} budgets</h4>
        <div className="col-md-6 right-section">
          <i
            className="fa-solid fa-plus"
            style={{
              marginLeft: "264px",
              width: "40px",
              fontSize: "20px",
              cursor: "pointer",
            }}
            onClick={toggleModal}
          ></i>
        </div>
      </div>

      <IncomeExpenseBudgetModal
        isOpen={isModalOpen}
        toggleModal={toggleModal}
        mainHead={mainHeading}
        showFields={showFields}
        isIncome={isIncome}
        fetchBudgetData={fetchBudgetData}
      />

      {incomeExpenseBudgetsData && (
        <div className="budget-item-card">
          {incomeExpenseBudgetsData?.map((budget, index) => {
            let progress;
            const adjustedAmount = isIncome
              ? budget.adjusted_income_amount
              : budget.adjusted_expense_amount - budget.expense_amount;
            const totalAmount = isIncome
              ? budget.income_amount
              : budget.adjusted_expense_amount;

            if (adjustedAmount < 0) {
              progress = 100;
            } else {
              progress = (adjustedAmount / totalAmount) * 100;
              progress = progress > 100 ? 100 : progress;
            }

            return (
              <div
                key={index}
                className="budget-item"
                onClick={() =>
                  handleCardClick(
                    isIncome ? budget.income_uuid : budget.expense_uuid,
                    budget,
                    adjustedAmount,
                    totalAmount,
                    progress
                  )
                }
                style={{ cursor: "pointer" }}
              >
                <div className="budget-label">
                  <div className="pay-budget-sec">
                    <div
                      className={`budget-icon ${emojiBackgroundColor(
                        budget.category_colour
                      )}`}
                    >
                      {budget.category_emoji}
                    </div>
                    <div>
                      <h6>{budget.category_name}</h6>
                      <h4>${totalAmount}</h4>
                    </div>
                  </div>
                  <p className="header-p" style={{ cursor: "pointer" }}>
                    <div className="account-number inter text-16px">
                      <div className="button-blck">
                        <ArrowAccoor />
                      </div>
                    </div>
                  </p>
                </div>

                <div className="budget-remaining">
                  {adjustedAmount < 0 ? (
                    isIncome ? (
                      `You've earned $${Math.abs(adjustedAmount)} more`
                    ) : (
                      <>
                        {`You're $${Math.abs(adjustedAmount)} over budget`}
                        <OverBudget
                          style={{
                            marginLeft: "1px",
                            marginTop: "2px",
                          }}
                        />
                      </>
                    )
                  ) : (
                    `$${adjustedAmount} remaining`
                  )}
                </div>

                <div
                  className={`budget-progress-container ${lightBackgroundColor(
                    budget.category_colour
                  )}`}
                >
                  <div
                    className={`budget-progress ${progressbarColor(
                      budget.category_colour
                    )}`}
                    style={{
                      width: `${progress}%`,
                      // backgroundColor: `${budget.category_colour}`,
                    }}
                  ></div>
                </div>
              </div>
            );
          })}
        </div>
      )}

      <div className="budget-item-card" style={{ marginTop: "25px" }}>
        <div className="budget-item">
          <div className="budget-label">
            <div className="pay-budget-sec">
              <div>
                <h6>Total {isIncome ? "Income" : "Expenses"}</h6>
                <h4>${totalAmount}</h4>
              </div>
            </div>
          </div>

          <div className="budget-remaining">
            {roundedAdjustedAmount < 0
              ? `$${Math.abs(
                  roundedAdjustedAmount
                ).toLocaleString()} over budget`
              : `$${roundedAdjustedAmount.toLocaleString()} remaining`}
          </div>

          <div
            className={`budget-progress-container ${lightBackgroundColor(
              isIncome ? "Green" : "Red"
            )}`}
            // style={{
            //   backgroundColor: `#D3D3D3`,
            // }}
          >
            <div
              className={`budget-progress ${progressbarColor(
                isIncome ? "Green" : "Red"
              )}`}
              style={{
                width: `${progress}%`,
                // backgroundColor: `#469B88`,
              }}
            ></div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default BudgetsComponent;
