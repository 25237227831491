import React from "react";
import "./IncomeExpenseBudgetModal.css";

const HowOften = ({ toggleFrequencyModal, handleFrequencySelection,style }) => {
  return (
    <div className="small-modal" style={style}>
      <div className="small-modal-header">
        <label>How often?</label>
        <h2 className="small-modal-close-button" onClick={toggleFrequencyModal}>
          &times;
        </h2>
      </div>

      <button
        onClick={() => handleFrequencySelection("Weekly")}
        style={{ backgroundColor: "#479985" }}
      >
        Weekly
      </button>
      <button
        onClick={() => handleFrequencySelection("Fortnightly")}
        style={{ backgroundColor: "#3de1bc" }}
      >
        Fortnightly
      </button>
      <button
        onClick={() => handleFrequencySelection("Monthly")}
        style={{ backgroundColor: "#3d6ae2" }}
      >
        Monthly
      </button>
      <button
        onClick={() => handleFrequencySelection("Quarterly")}
        style={{ backgroundColor: "#3eb1e1" }}
      >
        Quarterly
      </button>
      <button
        onClick={() => handleFrequencySelection("Annual")}
        style={{
          backgroundColor: "#39e16f",
      
        }} /* Spanning across 2 columns for full width */
      >
        Annual
      </button>
      <button
        onClick={() => handleFrequencySelection("Custom")}
        style={{
          backgroundColor: "#8D83FE",
          // gridColumn: "span 2",
        }} /* Spanning across 2 columns for full width */
      >
        Custom
      </button>
    </div>
  );
};

export default HowOften;
