import React from "react";
import PopupModal from "../Popup/PopupModel";
import "./Moneyinsights.css";

const CategoryBreakdownSection = ({
  togglePopupTopExpenses,
  isPopupOpenTopExpenses,
  selectedTopExpensesOption,
  handleOptionTopExpensesClick,
  heading
}) => {
  return (
    <div className="row mt-4">
      <div
        className="expense-summary-section"
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          marginBottom: "10px",
        }}
      >
        <div>
          <h2 style={{ margin: 0, fontSize: "20px", fontweight: "500" }}>
            {heading}
          </h2>
        </div>
        <div className="col-md-6 right-section">
          <div className="dropdown">
            <button
              className="group-button green"
              onClick={togglePopupTopExpenses}
              aria-haspopup="true"
              aria-expanded={isPopupOpenTopExpenses}
            >
              {selectedTopExpensesOption}
            </button>
            {isPopupOpenTopExpenses && (
              <PopupModal
                isOpen={isPopupOpenTopExpenses}
                onClose={togglePopupTopExpenses}
                title="Date Range"
                options={[
                  "This Week",
                  "Last Week",
                  "Quarter",
                  "Last Quarter",
                  "This Month",
                  "Last Month",
                  "This Year",
                  "Last Fortnightly",
                  "This Fortnightly"
                ]}
                selectedOption={selectedTopExpensesOption}
                handleOptionClick={handleOptionTopExpensesClick}
              />
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default CategoryBreakdownSection;
