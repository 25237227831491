import axios from "axios";
import weburls from "../Components/Weburls/weburls";

export const mapAkahuAccountsData = (data) => {
  return data?.map((item) => {
    return {
      akahuAccountName: item?.aku_bank_name,
      accounts: item?.accounts.map((act) => ({
        status: act?.account_status,
        accountName: act?.account_name,
        refreshDate: act?.aku_refreshed_balance,
        img: act?.avatar,
        akahuAccountId: act?.aku_account_id,
        akahuAccountName: item?.aku_bank_name,
      })),
    };
  });
};

export const parseYourAccountBalancesData = (data) => {
  return data?.map((item) => {
    return {
      accountName: item?.accountName,
      avatar: item?.avatar,
      value: item?.value,
      date: item?.updatedAt,
      accountNumber: item?.akahuAccountNumber,
      accountId: item?.accountUuid,
      id: item.id,
    };
  });
};

export const parseAssetOrLiabilityData = (data) => {
  return data?.map((item) => {
    return {
      name: item?.assetsName ?? item?.liabilityName,
      emoji: item?.emoji,
      value: item?.value,
      id: item?.assetsUuid ?? item?.liabilityUuid,
      amount: item?.value,
      accounts: item?.accounts,
    };
  });
};

export const parseMerchantsData = (data) => {
  return data?.map((item) => {
    return {
      name: item?.name,
      amount: item?.total_transaction_amount,
      date: item?.updated_at,
      id: item?.assetsUuid ?? item?.liabilityUuid,
      logo: item?.logo,
      categoryName: item?.categorie_name,
      categoryColor: item?.categorie_colour,
      categoryEmoji: item?.categorie_emoji,
      merchantId: item?.uuid,
    };
  });
};

export const parseRetirementPlannerAccounts = (data) => {
  return data?.map((item) => {
    return {
      name: item?.aku_bank_name,
      amount: item?.amount,
      date: item?.updated_at,
      id: item?.account_id,
      logo: item?.logo,
      type: item?.type,
    };
  });
};

export const parseGoalData = (data) => {
  return {
    accounts: data.accounts,
    colour: data.colour,
    contribution: data.contribution,
    currentBalance: data.currentBalance,
    goalComplete: data.goalComplete,
    goalName: data.goalName,
    goalUuid: data.goalUuid,
    howOften: data.howOften,
    icon: data.icon,
    isConnected: data.isConnected || false,
    startDate: data.startDate,
    endDate: data.endDate,
    userUuid: data.userUuid,
    value: data.value,
  };
};
const formatDateWithSuffix = (date) => {
  const today = new Date();
  today.setHours(0, 0, 0, 0);
  const yesterday = new Date(today);
  yesterday.setDate(today.getDate() - 1);

  const formatDate = (d) => {
    const suffix = (d) => {
      const j = d % 10;
      const k = Math.floor(d / 10);
      if (k === 1) return "th";
      return j === 1 ? "st" : j === 2 ? "nd" : j === 3 ? "rd" : "th";
    };

    const day = d.getDate();
    const month = d.toLocaleString("en-GB", { month: "long" });
    return `${day}${suffix(day)} ${month}`;
  };

  if (date.toDateString() === today.toDateString()) {
    return "Today";
  } else if (date.toDateString() === yesterday.toDateString()) {
    return "Yesterday";
  } else {
    return formatDate(date);
  }
};

// const formatDateWithSuffix = (date) => {
//   const suffix = (d) => {
//     const j = d % 10;
//     const k = Math.floor(d / 10);
//     if (k === 1) return "th";
//     return j === 1 ? "st" : j === 2 ? "nd" : j === 3 ? "rd" : "th";
//   };

//   const day = date.getDate();
//   const month = date.toLocaleString("en-GB", { month: "long" });
//   return `${day}${suffix(day)} ${month}`;
// };

const formatMonthYear = (date) => {
  const options = { year: "numeric", month: "long" };
  return new Intl.DateTimeFormat("en-GB", options).format(date);
};

export const groupTransactionsByDate = (transactions, oldData) => {
  const mergedData = { ...oldData };
  let expenseCount = 0;
  let incomeCount = 0;

  transactions.forEach((transaction) => {
    const date = new Date(transaction.dateTime);
    const formattedDate = formatDateWithSuffix(date);
    if (!mergedData[formattedDate]) {
      mergedData[formattedDate] = [];
    }

    mergedData[formattedDate].push(transaction);
    if (transaction.amount < 0) {
      expenseCount += 1;
    } else {
      incomeCount += 1;
    }
  });
  return {
    data: mergedData,
    expenseCount,
    incomeCount,
  };
};

export const groupTransactionsByMonth = (transactions, oldData) => {
  const mergedData = { ...oldData };
  let expenseCount = 0;
  let incomeCount = 0;
  transactions.forEach((transaction) => {
    const date = new Date(transaction.dateTime);
    const formattedMonthYear = formatMonthYear(date);
    if (!mergedData[formattedMonthYear]) {
      mergedData[formattedMonthYear] = [];
    }
    mergedData[formattedMonthYear].push(transaction);
    if (transaction.amount < 0) {
      expenseCount += 1;
    } else {
      incomeCount += 1;
    }
  });
  return {
    data: mergedData,
    expenseCount,
    incomeCount,
  };
};

export const countTransactions = (transactions) => {
  const expenseCount = transactions.filter(
    (transaction) => transaction.amount < 0
  ).length;
  const incomeCount = transactions.filter(
    (transaction) => transaction.amount >= 0
  ).length;

  return { expenseCount, incomeCount };
};

export function formatNumberWithCommas(number) {
  const formattedNumber = parseFloat(number).toFixed(2);
  return formattedNumber.replace(/\B(?=(\d{3})+(?!\d))/g, ",");
}

export const getOrdinalSuffix = (index) => {
  const j = (index + 1) % 10;
  const k = Math.floor(((index + 1) % 100) / 10);
  if (k === 1) return `${index + 1}th`;
  return `${index + 1}${
    j === 1 ? "st" : j === 2 ? "nd" : j === 3 ? "rd" : "th"
  }`;
};


export const getCurrentWeek = () => {
  const today = new Date();
  
  const firstDayOfWeek = new Date(today.setDate(today.getDate() - today.getDay() + 1));
  
  const lastDayOfWeek = new Date(firstDayOfWeek);
  lastDayOfWeek.setDate(firstDayOfWeek.getDate() + 6);
  
  const getDayWithSuffix = (day) => {
    const suffixes = ["th", "st", "nd", "rd"];
    const v = day % 100;
    return day + (suffixes[(v - 20) % 10] || suffixes[v] || suffixes[0]);
  };

  const startDay = getDayWithSuffix(firstDayOfWeek.getDate());
  const endDay = getDayWithSuffix(lastDayOfWeek.getDate());
  
  const startMonth = firstDayOfWeek.toLocaleString("default", { month: "long" });
  const endMonth = lastDayOfWeek.toLocaleString("default", { month: "long" });

  if (startMonth === endMonth) {
    return `${startDay} - ${endDay} ${startMonth}`;
  } else {
    return `${startDay} ${startMonth} - ${endDay} ${endMonth}`;
  }
};

export const getLastWeek = () => {
  const today = new Date();
  const firstDayOfLastWeek = new Date(
    today.setDate(today.getDate() - today.getDay() - 6)
  ); // Last Monday
  const options = { month: "long", day: "numeric", year: "numeric" };
  return `Week of ${firstDayOfLastWeek.toLocaleDateString(undefined, options)}`;
};

export const getCurrentMonth = () => {
  const today = new Date();
  const options = { month: "long", year: "numeric" };
  return today.toLocaleDateString(undefined, options);
};

export const getLastMonth = () => {
  const today = new Date();
  const lastMonthDate = new Date(today.setMonth(today.getMonth() - 1));
  const options = { month: "long", year: "numeric" };
  return lastMonthDate.toLocaleDateString(undefined, options);
};

export const getCurrentQuarter = () => {
  const today = new Date();
  const quarter = Math.floor((today.getMonth() + 3) / 3);
  return `Q${quarter} ${today.getFullYear()}`;
};

export const getLastQuarter = () => {
  const today = new Date();
  const quarter = Math.floor((today.getMonth() + 3) / 3);
  const lastQuarter = quarter === 1 ? 4 : quarter - 1;
  const year = quarter === 1 ? today.getFullYear() - 1 : today.getFullYear();
  return `Q${lastQuarter} ${year}`;
};

export const getCurrentYear = () => {
  const today = new Date();
  return today.getFullYear().toString();
};

export const getCurrentFortnight = () => {
  const today = new Date();
  const fortnight =
    today.getDate() <= 15 ? "First Fortnight" : "Second Fortnight";
  const options = { month: "long", year: "numeric" };
  return `${fortnight} of ${today.toLocaleDateString(undefined, options)}`;
};

export const getLastFortnight = () => {
  const today = new Date();
  const fortnight =
    today.getDate() <= 15
      ? "Last Fortnight of Previous Month"
      : "First Fortnight";
  const options = { month: "long", year: "numeric" };
  const lastMonthDate = new Date(
    today.setMonth(today.getMonth() - (today.getDate() <= 15 ? 1 : 0))
  );
  return `${fortnight} of ${lastMonthDate.toLocaleDateString(
    undefined,
    options
  )}`;
};

// export const incomeBudgetDataAPI = async (uuid) => {
//   try {
//     const response = await axios.get(`${weburls.Get_Income_Budget}${uuid}`);
//     income
//     incomeExpenseBudgetsData?.map((budget, index) => {
//       let progress;
//       const adjustedAmount = isIncome
//         ? budget.adjusted_income_amount
//         : budget.adjusted_expense_amount - budget.expense_amount;
//       const totalAmount = isIncome
//         ? budget.income_amount
//         : budget.adjusted_expense_amount;

//       if (adjustedAmount < 0) {
//         progress = 100;
//       } else {
//         progress = (adjustedAmount / totalAmount) * 100;
//         progress = progress > 100 ? 100 : progress;
//       }

//     if (response?.data?.code === "0") {

//     } else if (response?.data) {

//     }
//   } catch (error) {

//   }
// }
