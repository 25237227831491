import React from "react";
import PopupColorModal from "../Popup/PopupColorModel";
import EmojiPicker from "emoji-picker-react";
import { colorCss } from "../Innerpanel/BudgetSummeryComponent/IncomeExpenseBudget/IncomeExpenseBudgetModal";

const PersonalisedColorEmoji = ({
  selectedColorOption,
  togglePopupColor,
  isPopupOpenColor,
  handleOptionColorClick,
  setShowPicker,
  showPicker,
  chosenEmoji,
  handleEmojiClick,
}) => {

  return (
    <>
      <div className="budget-breakdown-header">
        <h4> Personalise</h4>
      </div>
      <div
        className="modal-input-group modal-section-card"
        style={{ marginBottom: "0px" }}
      >
        <label>Personalised Color</label>
        <div className="color-option">
          <div className="dropdown">
            <button
              className={`group-button ${colorCss(
                selectedColorOption
              )} popoup-color`}
              style={{ width: "150px", height: "35px" }}
              onClick={togglePopupColor}
              aria-haspopup="true"
              aria-expanded={isPopupOpenColor}
              type="button"
            >
              {selectedColorOption}
            </button>
            {isPopupOpenColor && (
              <PopupColorModal
                isOpen={isPopupOpenColor}
                onClose={togglePopupColor}
                title="Date Range"
                options={[
                  "Dark green",
                  "Red",
                  "Yellow",
                  "Blue",
                  "Purple",
                  "Orange",
                  "Pink",
                  "Light blue",
                  "Lime green",
                  "Dark red",
                  "Green",
                  "Dark blue",
                ]}
                selectedOption={selectedColorOption}
                handleOptionClick={handleOptionColorClick}
              />
            )}
          </div>
        </div>
      </div>

      <div
        className="modal-input-group modal-section-card"
        style={{ marginBottom: "0px", marginTop: "10px" }}
      >
        <label>Personalised emoji</label>
        <div className="d-flex flex-column">
          <div
            className="py-2 inter add-goal show-emoji"
            onClick={() => setShowPicker((prevState) => !prevState)} 
            style={{ width: "150px" }}
          >
            {chosenEmoji}
          </div>
          {showPicker && (
            <div
              className="category-emoji-container"
              style={{ left: "50rem", top: "1rem" }}
            >
              <EmojiPicker onEmojiClick={handleEmojiClick} />
            </div>
          )}
        </div>
      </div>
    </>
  );
};

export default PersonalisedColorEmoji;
