export const PopUpForUpdateDelete = ({
  handleUpdateCashflowYes,
  handleUpdateCashflowNo,
  updateOrDeleteText,
}) => {
  return (
    <div
      className="popup-container modal-background open-modal"
      id="popup_Update"
    >
      <div className="popup-content">
        <h2>{updateOrDeleteText} cashflow?</h2>
        <p>Would you like to {updateOrDeleteText} your cashflow as well?</p>
        <div className="popup-buttons">
          <button className="btn btn-no" onClick={handleUpdateCashflowNo}>
            No
          </button>
          <button className="btn btn-yes" onClick={handleUpdateCashflowYes}>
            Yes
          </button>
        </div>
      </div>
    </div>
  );
};

export const PopUpForDelete = ({ handleDeleteConfirm, handleDeleteCancel }) => {
  return (
    <div
      className="popup-container modal-background open-modal"
      id="popup_Delete"
    >
      <div className="popup-content">
        <h2>Are you sure?</h2>
        <p>This cannot be un-done.</p>
        <div className="popup-buttons">
          <button className="btn btn-no" onClick={handleDeleteCancel}>
            Go back
          </button>
          <button className="btn btn-yes" onClick={handleDeleteConfirm}>
            Delete
          </button>
        </div>
      </div>
    </div>
  );
};
