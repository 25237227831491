import React, { useState } from "react";
import { ConnectedAccounts } from "../ConnectedAccountsCard";
const Drawer = ({ isOpen, onClose, accountData, handleAccountToggle ,selectedAccount }) => {
  return (
    <div className={`side-drawer ${isOpen ? "open" : ""}`}>
      <button className="close-btn" onClick={onClose}>
        {/* <i class="fa fa-times"></i> */}
      </button>
      <div className="drawer-content-parent">
        {accountData?.map((ele, index) => (
          <ConnectedAccounts
            key={index}
            avatar={ele?.avatar}
            accountName={ele?.accountName}
            value={ele?.value}
            accountId={ele?.accountId}
            handleToggleSwitch={handleAccountToggle}
            id={ele?.id}
            selectedAccount={selectedAccount}
            isDrawer={true}
            width={"100%"}
          />
        ))}
      </div>
    </div>
  );
};

export default Drawer;
