import axios from 'axios';
import moment from 'moment';
import React, { useCallback, useEffect, useState } from 'react';
import { Link, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import { ReactComponent as Arrow } from '../../assests/images/icons/Arrow_right_card.svg';
import Countdown from '../../assests/images/icons/Countdown.png';
import Generate from '../../assests/images/icons/generate.png';
import Profile from '../../assests/images/icons/image.png';
import { ReactComponent as Edit } from '../../assests/images/icons/more.svg';
import Mortage from '../../assests/images/icons/Mortgage.png';
import { ReactComponent as People } from '../../assests/images/icons/People.svg';
import Shape from '../../assests/images/icons/shape.png';
import Sharesis from '../../assests/images/icons/Sharesies.png';
import MainSidebar from '../MainSidebar';
import PopupInfoModal from '../Popup/PopupInfoModel';
import PopupModal from '../Popup/PopupModel';
import weburls from '../Weburls/weburls';
import { Cart } from './Cart';
import { CashFlowChart } from './CashFlowChart';
import Piechart from './Donutpiechart/Piechart';
import Piechart2 from './Donutpiechart/Piechart2';
import Networthprogress from './Netwrothprogress';
import Smallcart from './Smallcart/Smallcart';

const Dashboardinner = () => {
  const [isPopupOpenIncome, setPopupOpenIncome] = useState(false);
  const [isPopupOpenInfo, setPopupOpenInfo] = useState(false);
  const [isPopupOpenExpenses, setPopupOpenExpenses] = useState(false);
  const [isPopupOpenTopExpenses, setPopupOpenTopExpenses] = useState(false);
  const [isPopupOpenBudget, setPopupOpenBudget] = useState(false);
  const [isPopupOpenMonthly, setPopupOpenMonthly] = useState(false);
  const [isPopupOpenMerchants, setPopupOpenMerchants] = useState(false);
  const [isPopupOpenExpensesBreakdown, setPopupOpenExpensesBreakdown] = useState(false);
  const [isPopupOpenIncomeBreakdown, setPopupOpenIncomeBreakdown] = useState(false);
  const [selectedIncomeOption, setSelectedIncomeOption] = useState('This Week');
  const { uuid } = useParams();
  const [selectedExpensesOption, setSelectedExpensesOption] = useState('This Week');
  const [selectedTopExpensesOption, setSelectedTopExpensesOption] = useState('This Week');
  const [selectedBudgetOption, setSelectedBudgetOption] = useState('This Month');
  const [selectedMonthlyOption, setSelectedMonthlyOption] = useState('Last 12 months');
  const [selectedMerchantsOption, setSelectedMerchantsOption] = useState('This Week');
  const [selectedExpensesBreakdownOption, setSelectedExpensesBreakdownOption] = useState('This Week');
  const [selectedIncomeBreakdownOption, setSelectedIncomeBreakdownOption] = useState('This Week');
  const [totalIncomeAmount, setTotalIncomeAmount] = useState();
  const [totalIncomeFilterAmount, setTotalIncomeFilterAmount] = useState(0);
  const [totalExpenseAmount, setTotalExpenseAmount] = useState();
  const [totalExpenseFilterAmount, setTotalExpenseFilterAmount] = useState(0);
  const [topFiveExpense, setTopFiveExpense] = useState();
  const [topFiveExpenseFilter, setTopFiveExpenseFilter] = useState([]);
  const [topFiveMerchant, setTopFiveMerchant] = useState();
  const [topFiveMerchantFilter, setTopFiveMerchantFilter] = useState([]);
  const [lastFiveTransaction, setLastFiveTransaction] = useState([]);
  const [spendVsBudget, setSpendVsBudget] = useState();
  const [spendVsBudgetFilter, setSpendVsBudgetFilter] = useState([]);
  const [monthlyIncomeAndExpensesData, setMonthlyIncomeAndExpense] = useState();
  const [categorizedMerchantsCount, setCategorizedMerchantsCount] = useState(0);
  const [unCategorizedMerchantsCount, setUnCategorizedMerchantsCount] = useState(0);
  const [expensesBreakdown, setExpensesBreakdown] = useState();
  const [expensesBreakdownFilterData, setExpensesBreakdownFilterData] = useState();
  const [incomeBreakdown, setIncomeBreakdown] = useState();
  const [incomeBreakdownTitleFilterData, setIcomeBreakdownTitleFilterData] = useState([]);
  const [incomeBreakdownAmountFilterData, setIcomeBreakdownAmountFilterData] = useState([]);
  const [incomeBreakdownColorFilterData, setIcomeBreakdownColorFilterData] = useState([]);

  const [selectedExpensesBreakdown, setSelectedExpensesBreakdown] = useState({
    label: '',
    amount: 0,
  });
  const [cashFlowChartData, setCashFlowChartData] = useState([]);

  const [goals, setGoals] = useState([]);

  const [data, setData] = useState({});

  const togglePopupIncome = () => setPopupOpenIncome(!isPopupOpenIncome);
  const togglePopupInfo = () => setPopupOpenInfo(!isPopupOpenInfo);
  const togglePopupExpenses = () => setPopupOpenExpenses(!isPopupOpenExpenses);
  const togglePopupTopExpenses = () => setPopupOpenTopExpenses(!isPopupOpenTopExpenses);
  const togglePopupBudget = () => setPopupOpenBudget(!isPopupOpenBudget);
  const togglePopupMonthly = () => setPopupOpenMonthly(!isPopupOpenMonthly);
  const togglePopupMerchants = () => setPopupOpenMerchants(!isPopupOpenMerchants);
  const togglePopupExpensesBreakdown = () => setPopupOpenExpensesBreakdown(!isPopupOpenExpensesBreakdown);
  const togglePopupIncomeBreakdown = () => setPopupOpenIncomeBreakdown(!isPopupOpenIncomeBreakdown);
  const topFiveExpenseColor = ['#DD7802', '#469B88', '#377CC8', '#EED868', '#E78C9D'];

  const handleOptionClick = (option) => {
    setSelectedIncomeOption(option);
    let amount = 0;
    switch (option) {
      case 'This Week':
        amount = totalIncomeAmount.thisWeek ?? 0;
        break;
      case 'Last Week':
        amount = totalIncomeAmount.lastWeek ?? 0;
        break;
      case 'This Month':
        amount = totalIncomeAmount.thisMonth ?? 0;
        break;
      case 'Last Month':
        amount = totalIncomeAmount.lastMonth ?? 0;
        break;
      case 'This Year':
        amount = totalIncomeAmount.thisYear ?? 0;
        break;
      default:
        amount = 0;
        break;
    }
    setTotalIncomeFilterAmount(amount);
    setPopupOpenIncome(false);
  };

  const handleOptionExpensesClick = (option) => {
    setSelectedExpensesOption(option);
    let amount = 0;
    switch (option) {
      case 'This Week':
        amount = totalExpenseAmount.thisWeek ?? 0;
        break;
      case 'Last Week':
        amount = totalExpenseAmount.lastWeek ?? 0;
        break;
      case 'This Month':
        amount = totalExpenseAmount.thisMonth ?? 0;
        break;
      case 'Last Month':
        amount = totalExpenseAmount.lastMonth ?? 0;
        break;
      case 'This Year':
        amount = totalExpenseAmount.thisYear ?? 0;
        break;
      default:
        amount = 0;
        break;
    }
    setTotalExpenseFilterAmount(amount);
    setPopupOpenExpenses(false);
  };

  const handleOptionTopExpensesClick = (option) => {
    setSelectedTopExpensesOption(option);
    let data = [];
    switch (option) {
      case 'This Week':
        data = topFiveExpense.thisWeek ?? [];
        break;
      case 'Last Week':
        data = topFiveExpense.lastWeek ?? [];
        break;
      case 'This Month':
        data = topFiveExpense.thisMonth ?? [];
        break;
      case 'Last Month':
        data = topFiveExpense.lastMonth ?? [];
        break;
      case 'This Year':
        data = topFiveExpense.thisYear ?? [];
        break;
      default:
        data = [];
        break;
    }
    setTopFiveExpenseFilter(data);
    setPopupOpenTopExpenses(false);
  };

  const handleOptionMerchantsClick = (option) => {
    setSelectedMerchantsOption(option);
    let data = [];
    switch (option) {
      case 'This Week':
        data = topFiveMerchant.thisWeek ?? [];
        break;
      case 'Last Week':
        data = topFiveMerchant.lastWeek ?? [];
        break;
      case 'This Month':
        data = topFiveMerchant.thisMonth ?? [];
        break;
      case 'Last Month':
        data = topFiveMerchant.lastMonth ?? [];
        break;
      case 'This Year':
        data = topFiveMerchant.thisYear ?? [];
        break;
      default:
        data = [];
        break;
    }
    setTopFiveMerchantFilter(data);
    setPopupOpenMerchants(false);
  };

  const handleOptionExpensesBreakdownClick = async (option) => {
    setSelectedExpensesBreakdownOption(option);
    let data = [];
    switch (option) {
      case 'This Week':
        let totalThisweek = 0;
        await expensesBreakdown.thisWeek.map((item) => {
          totalThisweek += item.total_income_amount;
        });
        const thisWeekExpensesBreakdown = await expensesBreakdown.thisWeek.map((item) => {
          const totalAmount = parseFloat(totalThisweek.toFixed(2));
          const amount = 100 + (item.total_income_amount / totalAmount) * 100;
          return {
            label: item.name,
            value: parseFloat(amount.toFixed(2)),
            width: 40,
          };
        });
        if (thisWeekExpensesBreakdown.length > 0) {
          setSelectedExpensesBreakdown({
            label: thisWeekExpensesBreakdown[0].label,
            amount: thisWeekExpensesBreakdown[0].value,
          });
        }
        data = thisWeekExpensesBreakdown ?? [];
        break;
      case 'Last Week':
        let totalLastWeek = 0;
        await expensesBreakdown.lastWeek.map((item) => {
          totalLastWeek += item.total_income_amount;
        });
        const lastWeekExpensesBreakdown = await expensesBreakdown.lastWeek.map((item) => {
          const totalAmount = parseFloat(totalLastWeek.toFixed(2));
          const amount = 100 + (item.total_income_amount / totalAmount) * 100;
          return {
            label: item.name,
            value: parseFloat(amount.toFixed(2)),
            width: 40,
          };
        });
        if (lastWeekExpensesBreakdown.length > 0) {
          setSelectedExpensesBreakdown({
            label: lastWeekExpensesBreakdown[0].label,
            amount: lastWeekExpensesBreakdown[0].value,
          });
        }
        data = lastWeekExpensesBreakdown ?? [];
        break;
      case 'This Month':
        let totalThisMonth = 0;
        await expensesBreakdown.thisMonth.map((item) => {
          totalThisMonth += item.total_income_amount;
        });
        const thisMonthExpensesBreakdown = await expensesBreakdown.thisMonth.map((item) => {
          const totalAmount = parseFloat(totalThisMonth.toFixed(2));
          const amount = 100 + (item.total_income_amount / totalAmount) * 100;
          return {
            label: item.name,
            value: parseFloat(amount.toFixed(2)),
            width: 40,
          };
        });
        if (thisMonthExpensesBreakdown.length > 0) {
          setSelectedExpensesBreakdown({
            label: thisMonthExpensesBreakdown[0].label,
            amount: thisMonthExpensesBreakdown[0].value,
          });
        }
        data = thisMonthExpensesBreakdown ?? [];
        break;
      case 'Last Month':
        let totalLastMonth = 0;
        await expensesBreakdown.lastMonth.map((item) => {
          totalLastMonth += item.total_income_amount;
        });
        const lastMonthExpensesBreakdown = await expensesBreakdown.lastMonth.map((item) => {
          const totalAmount = parseFloat(totalLastMonth.toFixed(2));
          const amount = 100 + (item.total_income_amount / totalAmount) * 100;
          return {
            label: item.name,
            value: parseFloat(amount.toFixed(2)),
            width: 40,
          };
        });
        if (lastMonthExpensesBreakdown.length > 0) {
          setSelectedExpensesBreakdown({
            label: lastMonthExpensesBreakdown[0].label,
            amount: lastMonthExpensesBreakdown[0].value,
          });
        }
        data = lastMonthExpensesBreakdown ?? [];
        break;
      case 'This Year':
        let totalThisYear = 0;
        await expensesBreakdown.thisYear.map((item) => {
          totalThisYear += item.total_income_amount;
        });
        const thisYearExpensesBreakdown = await expensesBreakdown.thisYear.map((item) => {
          const totalAmount = parseFloat(totalThisYear.toFixed(2));
          const amount = 100 + (item.total_income_amount / totalAmount) * 100;
          return {
            label: item.name,
            value: parseFloat(amount.toFixed(2)),
            width: 40,
          };
        });
        if (thisYearExpensesBreakdown.length > 0) {
          setSelectedExpensesBreakdown({
            label: thisYearExpensesBreakdown[0].label,
            amount: thisYearExpensesBreakdown[0].value,
          });
        }
        data = thisYearExpensesBreakdown ?? [];
        break;
      default:
        data = [];
        break;
    }
    setExpensesBreakdownFilterData(data);
    setPopupOpenExpensesBreakdown(false);
  };

  const handleOptionIncomeBreakdownClick = async (option) => {
    setSelectedIncomeBreakdownOption(option);
    const labels = [];
    const amounts = [];
    const colors = [];
    switch (option) {
      case 'This Week':
        let incomeTotal = 0;
        await incomeBreakdown.thisWeek.map((item) => {
          incomeTotal += item.total_income_amount;
        });
        await incomeBreakdown.thisWeek.map((item) => {
          const totalAmount = parseFloat(incomeTotal.toFixed(2));
          const amount = 100 + (item.total_income_amount / totalAmount) * 100;
          labels.push(item.name);
          amounts.push(parseFloat(amount.toFixed(2)));
          colors.push(item.colour);
        });
        setIcomeBreakdownTitleFilterData(labels);
        setIcomeBreakdownAmountFilterData(amounts);
        setIcomeBreakdownColorFilterData(colors);
        break;
      case 'Last Week':
        let incomeLastWeekTotal = 0;
        await incomeBreakdown.lastWeek.map((item) => {
          incomeLastWeekTotal += item.total_income_amount;
        });
        await incomeBreakdown.lastWeek.map((item) => {
          const totalAmount = parseFloat(incomeLastWeekTotal.toFixed(2));
          const amount = 100 + (item.total_income_amount / totalAmount) * 100;
          labels.push(item.name);
          amounts.push(parseFloat(amount.toFixed(2)));
          colors.push(item.colour);
        });
        setIcomeBreakdownTitleFilterData(labels);
        setIcomeBreakdownAmountFilterData(amounts);
        setIcomeBreakdownColorFilterData(colors);
        break;
      case 'This Month':
        let incomeThisMonthTotal = 0;
        await incomeBreakdown.thisMonth.map((item) => {
          incomeThisMonthTotal += item.total_income_amount;
        });
        await incomeBreakdown.thisMonth.map((item) => {
          const totalAmount = parseFloat(incomeThisMonthTotal.toFixed(2));
          const amount = 100 + (item.total_income_amount / totalAmount) * 100;
          labels.push(item.name);
          amounts.push(parseFloat(amount.toFixed(2)));
          colors.push(item.colour);
        });
        setIcomeBreakdownTitleFilterData(labels);
        setIcomeBreakdownAmountFilterData(amounts);
        setIcomeBreakdownColorFilterData(colors);
        break;
      case 'Last Month':
        let incomeLastMonthTotal = 0;
        await incomeBreakdown.lastMonth.map((item) => {
          incomeLastMonthTotal += item.total_income_amount;
        });
        await incomeBreakdown.lastMonth.map((item) => {
          const totalAmount = parseFloat(incomeLastMonthTotal.toFixed(2));
          const amount = 100 + (item.total_income_amount / totalAmount) * 100;
          labels.push(item.name);
          amounts.push(parseFloat(amount.toFixed(2)));
          colors.push(item.colour);
        });
        setIcomeBreakdownTitleFilterData(labels);
        setIcomeBreakdownAmountFilterData(amounts);
        setIcomeBreakdownColorFilterData(colors);
        break;
      case 'This Year':
        let incomeThisYearTotal = 0;
        await incomeBreakdown.thisYear.map((item) => {
          incomeThisYearTotal += item.total_income_amount;
        });
        await incomeBreakdown.thisYear.map((item) => {
          const totalAmount = parseFloat(incomeThisYearTotal.toFixed(2));
          const amount = 100 + (item.total_income_amount / totalAmount) * 100;
          labels.push(item.name);
          amounts.push(parseFloat(amount.toFixed(2)));
          colors.push(item.colour);
        });
        setIcomeBreakdownTitleFilterData(labels);
        setIcomeBreakdownAmountFilterData(amounts);
        setIcomeBreakdownColorFilterData(colors);
        break;
      default:
        data = [];
        break;
    }
    setPopupOpenIncomeBreakdown(false);
  };

  const onHandleSelectedExpensesBreakdown = (label, value) => {
    setSelectedExpensesBreakdown({
      label: label,
      amount: value,
    });
  };

  const handleOptionBudgetClick = (option) => {
    setSelectedBudgetOption(option);
    let data = [];
    switch (option) {
      case 'This Month':
        data = spendVsBudget.monthly ?? [];
        break;
      case 'This Quater':
        data = spendVsBudget.quarter ?? [];
        break;
      default:
        data = [];
        break;
    }
    setSpendVsBudgetFilter(data);
    setPopupOpenBudget(false);
  };

  const handleOptionMonthlyClick = (option) => {
    setSelectedMonthlyOption(option);
    setPopupOpenMonthly(false);
  };

  const settingApi = useCallback(async () => {
    const response = await axios.get(weburls.Get_clients_by_uuid + `${uuid}`);
    if (response?.data) {
      setData(response?.data);
    } else {
      toast.error(response?.data?.errors?.detail || 'No Data Found', {
        position: 'top-right',
        autoClose: 2000,
      });
    }
  }, [uuid]);

  const dashboardApi = useCallback(async () => {
    const response = await axios.get(weburls.Dashboard_all_data + `${uuid}`);
    if (response?.data) {
      setTotalIncomeAmount(response?.data.data.income);
      setTotalIncomeFilterAmount(response?.data.data.income.thisWeek ?? 0);
      setTotalExpenseAmount(response?.data.data.expense);
      setTotalExpenseFilterAmount(response?.data.data.expense.thisWeek ?? 0);
      setTopFiveExpense(response?.data.topFiveExpense);
      setTopFiveExpenseFilter(response?.data.topFiveExpense.thisWeek ?? []);
      setTopFiveMerchant(response?.data.topFiveMerchants);
      setTopFiveMerchantFilter(response?.data.topFiveMerchants.thisWeek ?? []);
      setLastFiveTransaction(response?.data.data.transactions.thisMonth ?? []);
      setSpendVsBudget(response?.data.spendVsBudget);
      setSpendVsBudgetFilter(response?.data.spendVsBudget.monthly ?? []);
      setGoals(response?.data.data.goals ?? []);
      setCategorizedMerchantsCount(
        response?.data.data.totalMerchants.length > 0 ? response?.data.data.totalMerchants[1].merchant_count : 0,
      );
      setUnCategorizedMerchantsCount(
        response?.data.data.totalMerchants.length > 0 ? response?.data.data.totalMerchants[0].merchant_count : 0,
      );
      setExpensesBreakdown(response?.data.expenseBreakDown);
      let total = 0;
      await response?.data.expenseBreakDown.thisWeek.map((item) => {
        total += item.total_income_amount;
      });
      const thisWeekExpensesBreakdown = await response?.data.expenseBreakDown.thisWeek.map((item) => {
        const totalAmount = parseFloat(total.toFixed(2));
        const amount = 100 + (item.total_income_amount / totalAmount) * 100;
        return {
          label: item.name,
          value: parseFloat(amount.toFixed(2)),
          width: 40,
        };
      });
      if (thisWeekExpensesBreakdown.length > 0) {
        setSelectedExpensesBreakdown({
          label: thisWeekExpensesBreakdown[0].label,
          amount: thisWeekExpensesBreakdown[0].value,
        });
      }
      setExpensesBreakdownFilterData(thisWeekExpensesBreakdown ?? []);
      setCashFlowChartData(response?.data.cashFlowData ?? []);
      setIncomeBreakdown(response?.data.incomeBreakDown);
      let incomeTotal = 0;
      await response?.data.incomeBreakDown.thisWeek.map((item) => {
        incomeTotal += item.total_income_amount;
      });
      const labels = [];
      const amounts = [];
      const colors = [];
      await response?.data.incomeBreakDown.thisWeek.map((item) => {
        const totalAmount = parseFloat(incomeTotal.toFixed(2));
        const amount = 100 + (item.total_income_amount / totalAmount) * 100;
        labels.push(item.name);
        amounts.push(parseFloat(amount.toFixed(2)));
        colors.push(item.colour);
      });
      setIcomeBreakdownTitleFilterData(labels);
      setIcomeBreakdownAmountFilterData(amounts);
      setIcomeBreakdownColorFilterData(colors);
    } else {
      toast.error(response?.data?.errors?.detail || 'No Data Found', {
        position: 'top-right',
        autoClose: 2000,
      });
    }
  }, [uuid]);

  const getMonthlyIncomeAndExpense = useCallback(async () => {
    const response = await axios.get(weburls.Get_Monthly_Income_Expense + `${uuid}`);
    if (response?.data) {
      setMonthlyIncomeAndExpense(response?.data);
    } else {
      toast.error(response?.data?.errors?.detail || 'No Data Found', {
        position: 'top-right',
        autoClose: 2000,
      });
    }
  }, [uuid]);

  useEffect(() => {
    // dashboardApi();
    settingApi();
    getMonthlyIncomeAndExpense();
  }, [dashboardApi, settingApi, getMonthlyIncomeAndExpense]);

  function formatAmount(amount) {
    return amount.toLocaleString('en-US', {
      style: 'currency',
      currency: 'USD',
      minimumFractionDigits: 2,
    });
  }

  function truncateString(inputString, maxLength) {
    if (inputString.length <= maxLength) {
      return inputString;
    } else {
      return inputString.substring(0, maxLength) + '...';
    }
  }

  function progressBarPercantage(adjustedAmount, spendAmount) {
    const percantage = (spendAmount / adjustedAmount) * 100;
    return percantage.toString();
  }

  function colorSet(color, alpha = 1) {
    let colorCode = color;
    switch (color) {
      case 'Red':
        colorCode = '#E0533D';
        break;
      case 'Light green':
        colorCode = '#3DE0B9';
        break;
      case 'Blue':
        colorCode = '#3D6BE0';
        break;
      case 'Green':
        colorCode = '#3DE06B';
        break;
      case 'Light blue':
        colorCode = '#3DAFE0';
        break;
      case 'Orange':
        colorCode = '#E09F3D';
        break;
      case 'Yellow':
        colorCode = '#DDE03D';
        break;
      case 'Purple':
        colorCode = '#983DE0';
        break;
      case 'Pink':
        colorCode = '#DD3DE0';
        break;
      default:
        break;
    }

    colorCode = colorCode.replace(/^#/, '');
    const bigint = parseInt(colorCode, 16);
    const r = (bigint >> 16) & 255;
    const g = (bigint >> 8) & 255;
    const b = bigint & 255;
    const a = Math.min(1, Math.max(0, alpha));
    colorCode = `rgba(${r}, ${g}, ${b}, ${a})`;
    return colorCode;
  }

  function colorDarkSet(color, alpha = 1) {
    let colorCode = color;
    switch (color) {
      case 'Red':
        colorCode = '#B92F19';
        break;
      case 'Light green':
        colorCode = '#12A784';
        break;
      case 'Blue':
        colorCode = '#0D39A8';
        break;
      case 'Green':
        colorCode = '#0B9D34';
        break;
      case 'Light blue':
        colorCode = '#1282B2';
        break;
      case 'Orange':
        colorCode = '#AE7116';
        break;
      case 'Yellow':
        colorCode = '#B6B91A';
        break;
      case 'Purple':
        colorCode = '#5E0F9C';
        break;
      case 'Pink':
        colorCode = '#A510A8';
        break;
      default:
        break;
    }

    colorCode = colorCode.replace(/^#/, '');
    const bigint = parseInt(colorCode, 16);
    const r = (bigint >> 16) & 255;
    const g = (bigint >> 8) & 255;
    const b = bigint & 255;
    const a = Math.min(1, Math.max(0, alpha));
    colorCode = `rgba(${r}, ${g}, ${b}, ${a})`;
    return colorCode;
  }

  return (
    <>
      <div className="container-scroller">
        <div className="container-fluid">
          <div className="appmain mainsidebar main-wrapper">
            <MainSidebar uuid={uuid} />
            <div className="app-main__outer client-dashboard-main-wrapper">
              <div className="row align-items-center">
                <div className="col-md-6 top-head">
                  <div className="big-head-new">
                    Good Morning,<span className="text-jeremy"> {data?.firstName} 👋</span>
                  </div>
                  <div className="fst-normal light-grey">Here's an update on your financial position.</div>
                </div>
                <div className="col-md-6 right-section dashboard-inner-back-to-portal">
                  <div className="setting_background_color right-section main-dash-back">
                    <div className="profile-dashboard">
                      <button className="comn-btn">
                        Add Widgets <i class="fa-solid fa-plus"></i>
                      </button>
                      <button className="comn-btn add-expense">
                        Remove Widgets <i class="fa-solid fa-close"></i>
                      </button>
                      <img
                        src={Shape}
                        alt="Mortage_img"
                        onClick={togglePopupInfo}
                        style={{ width: '20px', marginLeft: '20px' }}
                      />
                    </div>

                    {isPopupOpenInfo && (
                      <PopupInfoModal
                        isOpen={isPopupOpenInfo}
                        onClose={togglePopupInfo}
                        title="Dashboard 📱"
                        description={[
                          'Your dashboard brings all the features of BudgetBuddie into one place, so you can get a single view of your financial life.',
                          'You can edit and reorder your dashboard by clicking and holding down on any widget. This will allow you toremove any widgets you’re not wanting and allow you to change the order as well.',
                          'You can add widgets by clicking the ‘Add widgets’ button, this will bring you to the add widgets screen',
                        ]}
                      />
                    )}

                    <div className="profile-dashboard">
                      <div className="profile-img">
                        <img src={Profile} alt="profile" />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="row mt-5 income-expenses">
                <div className="col-md-6 col-lg-6 left-section total-balances-card-wrp">
                  <div className="total-balance-wrp">
                    <h4 className="med-head">Total Balance</h4>
                    <h3 className="big-head">$25,000.40</h3>
                    <div className="row mt-5">
                      <div className="col-md-7 img-sec">
                        <div className="img">
                          <img src={Mortage} alt="Mortage_img" />
                        </div>
                        <div className="img">
                          <img src={Generate} alt="Mortage_img" />
                        </div>
                        <div className="img">
                          <img src={Sharesis} alt="Mortage_img" />
                        </div>
                      </div>
                      <div className="col-md-5 right">
                        <h4>
                          My Balances <Arrow />
                        </h4>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="col-lg-3  col-md-3 col-sm-12 dashboard-inner-income-expenses-wrp">
                  <div class="total-clients">
                    <div className="top-wrp">
                      <div class="fw-bold profile-dashboard ">Income</div>
                      <div className="profile-dashboard right-section">
                        <div className="dropdown">
                          <button
                            className="group-button green"
                            onClick={togglePopupIncome}
                            aria-haspopup="true"
                            aria-expanded={isPopupOpenIncome}
                          >
                            {selectedIncomeOption}
                          </button>
                          {isPopupOpenIncome && (
                            <PopupModal
                              isOpen={isPopupOpenIncome}
                              onClose={togglePopupIncome}
                              title="Date Range"
                              options={['This Week', 'Last Week', 'This Month', 'Last Month', 'This Year']}
                              selectedOption={selectedIncomeOption}
                              handleOptionClick={handleOptionClick}
                            />
                          )}
                        </div>
                      </div>
                    </div>
                    <div class="margin-nu d-flex">
                      <h3 class="number-heading">${totalIncomeFilterAmount}</h3>
                      <div class="percentage bold">+75.5%</div>
                    </div>
                    <div class="text-client">{selectedIncomeOption} Income</div>
                  </div>
                </div>
                <div class="col-lg-3  col-md-3 col-sm-12 dashboard-inner-income-expenses-wrp">
                  <div class="total-clients">
                    <div className="top-wrp">
                      <div class="fw-bold profile-dashboard ">Expenses</div>
                      <div className="profile-dashboard right-section">
                        <div className="dropdown">
                          <button
                            className="group-button green"
                            onClick={togglePopupExpenses}
                            aria-haspopup="true"
                            aria-expanded={isPopupOpenExpenses}
                          >
                            {selectedExpensesOption}
                          </button>
                          {isPopupOpenExpenses && (
                            <PopupModal
                              isOpen={isPopupOpenExpenses}
                              onClose={togglePopupExpenses}
                              title="Date Range"
                              options={['This Week', 'Last Week', 'This Month', 'Last Month', 'This Year']}
                              selectedOption={selectedExpensesOption}
                              handleOptionClick={handleOptionExpensesClick}
                            />
                          )}
                        </div>
                      </div>
                    </div>
                    <div class="margin-nu d-flex">
                      <h3 class="number-heading">${totalExpenseFilterAmount}</h3>
                      <div class="percentage bold red">+15.5%</div>
                    </div>
                    <div class="text-client"> {selectedExpensesOption} expenses</div>
                  </div>
                </div>
              </div>
              <div className="row mt-4 ">
                <div className="col-md-12 col-lg-6 bar-cart income-exp-mnth">
                  <div className="recent-see-all">
                    <div className="col-md-6 left">
                      <h5>Income vs Expenses - Monthly</h5>
                    </div>
                    <div className="col-md-6 right-section">
                      <div className="dropdown">
                        <button
                          className="group-button green"
                          onClick={togglePopupMonthly}
                          aria-haspopup="true"
                          aria-expanded={isPopupOpenMonthly}
                        >
                          {selectedMonthlyOption}
                        </button>
                        {isPopupOpenMonthly && (
                          <PopupModal
                            isOpen={isPopupOpenMonthly}
                            onClose={togglePopupMonthly}
                            title="Date Range"
                            options={[
                              'Last 12 months',
                              'Last 6 months',
                              'Last 3 months',
                              'Last month',
                              'This month',
                              'This week',
                            ]}
                            selectedOption={selectedMonthlyOption}
                            handleOptionClick={handleOptionMonthlyClick}
                          />
                        )}
                      </div>
                    </div>
                  </div>
                  <div className="income-exp-bar-chart">
                    <div className="col-md-12 col-sm-12">
                      <div className="main-barcart-wrapper">
                        <div className="title-legend-wrp col-xl-11 col-md-11">
                          <h3>April 2022</h3>
                          <div className="income-exp-legend">
                            <span className="pointer-green"></span>
                            <h5>Income</h5>
                            <span className="pointer-red"></span>
                            <h5>Expenses</h5>
                            <Link to="#">
                              <Edit />
                            </Link>
                          </div>
                        </div>

                        <div className="bar-cart-data-detail">
                          <h3 className="income-bar">
                            Income: <span>${monthlyIncomeAndExpensesData?.totalIncomeCurrentMonth ?? 0}</span>
                          </h3>
                          <h3 className="expense-bar">
                            Expenses: <span>${monthlyIncomeAndExpensesData?.totalExpenseCurrentMonth ?? 0}</span>
                          </h3>
                        </div>
                        <Cart
                          income={monthlyIncomeAndExpensesData?.income || []}
                          expense={monthlyIncomeAndExpensesData?.expense || []}
                        />
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-md-6 col-lg-3 bar-cart top-expenses top-5-expenses-wrapper">
                  <div className="recent-see-all">
                    <div className="col-md-6 left">
                      <h5>Top 5 Expenses</h5>
                    </div>
                    <div className="col-md-6 right-section">
                      <div className="dropdown">
                        <button
                          className="group-button green"
                          onClick={togglePopupTopExpenses}
                          aria-haspopup="true"
                          aria-expanded={isPopupOpenTopExpenses}
                        >
                          {selectedTopExpensesOption}
                        </button>
                        {isPopupOpenTopExpenses && (
                          <PopupModal
                            isOpen={isPopupOpenTopExpenses}
                            onClose={togglePopupTopExpenses}
                            title="Date Range"
                            options={['This Week', 'Last Week', 'This Month', 'Last Month', 'This Year']}
                            selectedOption={selectedTopExpensesOption}
                            handleOptionClick={handleOptionTopExpensesClick}
                          />
                        )}
                      </div>
                    </div>
                  </div>
                  <div className="main-blck row mt-4 ">
                    {topFiveExpenseFilter &&
                      topFiveExpenseFilter?.length > 0 &&
                      topFiveExpenseFilter.map((it, index) => (
                        <div
                          className="sml-blck col-sm-5 col-md-5"
                          style={{ backgroundColor: topFiveExpenseColor[index] }}
                        >
                          <>
                            <div className="number">{index + 1}</div>
                            <h4>{it.name}</h4>
                            <h3 className="number-heading">$ {it.total_income_amount ?? 0}</h3>
                          </>
                        </div>
                      ))}
                  </div>
                </div>
                <div className="col-md-6 col-lg-3 bar-cart top-expenses top-5-merchants-wrapper">
                  <div className="recent-see-all">
                    <div className="col-md-6 inline mb-4 left">
                      <h5>Top 5 Merchants</h5>
                    </div>
                    <div className="col-md-6 right-section right">
                      <div className="dropdown">
                        <button
                          className="group-button green"
                          onClick={togglePopupMerchants}
                          aria-haspopup="true"
                          aria-expanded={isPopupOpenMerchants}
                        >
                          {selectedMerchantsOption}
                        </button>
                        {isPopupOpenMerchants && (
                          <PopupModal
                            isOpen={isPopupOpenMerchants}
                            onClose={togglePopupMerchants}
                            title="Date Range"
                            options={['This Week', 'Last Week', 'This Month', 'Last Month', 'This Year']}
                            selectedOption={selectedMerchantsOption}
                            handleOptionClick={handleOptionMerchantsClick}
                          />
                        )}
                      </div>
                    </div>
                  </div>
                  <div className="col-md-12 main-blck">
                    {topFiveMerchantFilter &&
                      topFiveMerchantFilter?.length > 0 &&
                      topFiveMerchantFilter.map((it) => (
                        <div className="sideblck">
                          <div className="icons-mn d-flex">
                            <div className="merchnat-img">
                              <img src={it.logo === '' || it.logo === null ? Countdown : it.logo} alt="CountDown-img" />
                            </div>
                            <div className="name-merchant bar-chart">
                              <h5>{it.name}</h5>
                              <h6 className="text-client">{it.total_transactions} Transactions</h6>
                            </div>
                          </div>
                          <div
                            className={` ${it.total_transaction_amount < 0 ? 'light-red-trans' : 'light-green-trans'}`}
                          >
                            <h4 style={{ fontSize: '16px', fontWeight: 'bold' }}>
                              {formatAmount(it.total_transaction_amount ?? 0)}
                            </h4>
                          </div>
                        </div>
                      ))}
                  </div>
                </div>
              </div>
              <div className="row mt-4">
                <div className="col-md-12 bar-cart spend-budget">
                  <div className="col-md-6 inline mb-4 left">
                    <h5>Spend vs budget</h5>
                  </div>
                  <div className="col-md-6 right-section right">
                    <div className="dropdown">
                      <button
                        className="group-button green"
                        onClick={togglePopupBudget}
                        aria-haspopup="true"
                        aria-expanded={isPopupOpenBudget}
                      >
                        {selectedBudgetOption}
                      </button>
                      {isPopupOpenBudget && (
                        <PopupModal
                          isOpen={isPopupOpenBudget}
                          onClose={togglePopupBudget}
                          title="Date Range"
                          options={['This Month', 'This Quater']}
                          selectedOption={selectedBudgetOption}
                          handleOptionClick={handleOptionBudgetClick}
                        />
                      )}
                    </div>
                  </div>
                </div>
              </div>
              <div className="row monthly-wrp">
                <div className="col-md-12 col-lg-12 col-sm-12 col-xl-12 monthly-section">
                  {spendVsBudgetFilter &&
                    spendVsBudgetFilter.length > 0 &&
                    spendVsBudgetFilter.map((item) => {
                      return (
                        <div className="income-merchant col-lg-5 col-xl-5 col-sm-12 mb-3">
                          <div className="inner-blck">
                            <div className="merchant">
                              <h6>{item.expense_name}</h6>
                              <h5>{item.total_transaction_amount}</h5>
                            </div>
                            <div className="arrow">
                              <i class="fas fa-angle-right"></i>
                            </div>
                          </div>
                          <div className="left-block name-merchant mb-2">
                            <h6>
                              {formatAmount(Number(item.adjusted_expense_amount - item.total_transaction_amount))} left
                            </h6>
                          </div>
                          <div
                            className="progress"
                            style={{
                              backgroundColor: colorSet(item.colour, 0.3),
                              height: '10px',
                              borderRadius: '10px',
                            }}
                          >
                            <div
                              className="progress-bar"
                              style={{
                                backgroundColor: colorSet(item.colour),
                                width: `${progressBarPercantage(
                                  item.adjusted_expense_amount,
                                  item.total_transaction_amount,
                                )}%`,
                              }}
                              role="progressbar"
                              aria-valuemin="0"
                              aria-valuemax="100"
                            ></div>
                          </div>
                        </div>
                      );
                    })}
                </div>
              </div>
              <div className="row mt-4">
                <div className="col-md-12 bar-cart spend-budget">
                  <div className="col-md-6 inline mb-4 left">
                    <h5>Goals</h5>
                  </div>
                  <div className="col-md-6 right-section right see-all btn-hover">
                    <Link className="group-button green" to={`/Financial/${uuid}`} style={{ fontSize: '13px' }}>
                      See All
                    </Link>
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-md-12 col-lg-12 col-sm-12 col-xl-12 goal-section">
                  {goals && goals.length > 0 ? (
                    goals.map((it) => (
                      <div className="col-lg-3 col-md-3 m-1" key={it.goalUuid}>
                        <div
                          className={`financial-holiday financial cursor-pointer`}
                          style={{ backgroundColor: colorSet(it.colour) }}
                        >
                          <div className="d-flex justify-content-between">
                            <div className="d-flex justify-content-between">
                              <div className="d-flex align-items-center">
                                <div className="icon-holiday" style={{ backgroundColor: colorDarkSet('#FFFFFF', 0.7) }}>
                                  {it.icon}
                                </div>
                                <div className="ms-2 text-white category-text fw-bold inter">{it.goalName}</div>
                              </div>
                            </div>
                          </div>
                          <div className="d-flex justify-content-between mt-3 mb-2 align-items-center">
                            <div className="financial-text">Balance</div>
                            <div className="financial-text">
                              {((it.currentBalance / it.value).toFixed(2) * 100).toFixed(2)}%
                            </div>
                          </div>
                          <div
                            className="progress"
                            style={{
                              backgroundColor: colorDarkSet('#FFFFFF', 0.7),
                              height: '10px',
                              borderRadius: '10px',
                            }}
                          >
                            <div
                              className="progress-bar"
                              role="progressbar"
                              aria-valuenow={((it.currentBalance / it.value) * 100).toFixed(2)}
                              aria-valuemin="0"
                              aria-valuemax="100"
                              style={{
                                backgroundColor: colorDarkSet(it.colour),
                                width: `${((it.currentBalance / it.value) * 100).toFixed(2)}%`,
                                opacity: '2 !important',
                              }}
                            ></div>
                          </div>
                          <div className="d-flex justify-content-between align-items-center mt-3">
                            <div>
                              <span className="bold text-white">${it.currentBalance}</span>
                              <span className="financial-text">&nbsp; of &nbsp;${it.value}</span>
                              <Link
                                to={`/Financial/GoalDetails/${uuid}/${it.goalUuid}`}
                                style={{
                                  color: 'white',
                                }}
                              >
                                <div
                                  className="mt-3"
                                  style={{
                                    fontSize: '12px',
                                  }}
                                >
                                  See detail
                                </div>
                              </Link>
                            </div>
                            <div className="financial-text">
                              {it?.endDate ? moment(it?.endDate).format('Do MMMM YYYY') : ''}
                            </div>
                          </div>
                        </div>
                      </div>
                    ))
                  ) : (
                    <div className="col-lg-6 col-md-12 mt-4">
                      <div className="text-danger">No goal, please add your goal</div>
                    </div>
                  )}
                </div>
              </div>
              <div className="row mt-4 ">
                <div className="col-md-6 col-lg-3 bar-cart top-expenses top-5-expenses-wrapper">
                  {/* <div className="recent-see-all">
                    <div className="col-md-6 left">
                      <h5>Income vs Budget</h5>
                    </div>
                  </div> */}
                  <div className="col-md-12 main-blck">
                    <div className="right-top-blck">
                      <div className="new-blck">
                        <div className="content">
                          <h3 className="title">Total income vs budget</h3>
                          <p className="common-content">See how you’re tracking</p>
                        </div>
                        <div className="right">
                          <h3 className="per-head bold">12.5%</h3>
                        </div>
                      </div>
                      <div className="pie-blck first-circular-block mt-2">
                        <Piechart />
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-md-6 col-lg-3 bar-cart top-expenses top-5-expenses-wrapper">
                  <div className="col-md-12 main-blck">
                    <div className="right-top-blck">
                      <div className="new-blck">
                        <div className="content">
                          <h3 className="title">Total expenses vs budget</h3>
                          <p className="common-content">See how you’re tracking</p>
                        </div>
                        <div className="right">
                          <h3 className="per-head-red bold">12.5%</h3>
                        </div>
                      </div>
                      <div className="pie-blck first-circular-block mt-2">
                        <Piechart2 />
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-md-6 col-lg-3 bar-cart top-expenses top-5-expenses-wrapper">
                  <div className="recent-see-all">
                    <div className="col-md-6 inline mb-4 left">
                      <h5>Income breakdown</h5>
                    </div>
                    <div className="col-md-6 right-section right">
                      <div className="dropdown">
                        <button
                          className="group-button green"
                          onClick={togglePopupIncomeBreakdown}
                          aria-haspopup="true"
                          aria-expanded={isPopupOpenIncomeBreakdown}
                        >
                          {selectedIncomeBreakdownOption}
                        </button>
                        {isPopupOpenIncomeBreakdown && (
                          <PopupModal
                            isOpen={isPopupOpenIncomeBreakdown}
                            onClose={togglePopupIncomeBreakdown}
                            title="Date Range"
                            options={['This Week', 'Last Week', 'This Month', 'Last Month', 'This Year']}
                            selectedOption={selectedIncomeBreakdownOption}
                            handleOptionClick={handleOptionIncomeBreakdownClick}
                          />
                        )}
                      </div>
                    </div>
                  </div>
                  <div className="col-md-12 main-blck">
                    <div className="right-top-blck">
                      <div className="new-blck">
                        <div className="content">
                          <h3 className="title">Income breakdown</h3>
                        </div>
                        <div className="right">
                          <h3 className="per-head bold">$5600</h3>
                        </div>
                      </div>
                      <div className="pie-blck">
                        <Smallcart
                          label={incomeBreakdownTitleFilterData}
                          amount={incomeBreakdownAmountFilterData}
                          color={incomeBreakdownColorFilterData}
                        />
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-md-6 col-lg-3 bar-cart top-expenses top-5-merchants-wrapper">
                  <div className="recent-see-all">
                    <div className="col-md-6 inline mb-4 left">
                      <h5>Expenses breakdown</h5>
                    </div>
                    <div className="col-md-6 right-section right">
                      <div className="dropdown">
                        <button
                          className="group-button green"
                          onClick={togglePopupExpensesBreakdown}
                          aria-haspopup="true"
                          aria-expanded={isPopupOpenExpensesBreakdown}
                        >
                          {selectedExpensesBreakdownOption}
                        </button>
                        {isPopupOpenExpensesBreakdown && (
                          <PopupModal
                            isOpen={isPopupOpenExpensesBreakdown}
                            onClose={togglePopupExpensesBreakdown}
                            title="Date Range"
                            options={['This Week', 'Last Week', 'This Month', 'Last Month', 'This Year']}
                            selectedOption={selectedExpensesBreakdownOption}
                            handleOptionClick={handleOptionExpensesBreakdownClick}
                          />
                        )}
                      </div>
                    </div>
                  </div>
                  <div className="col-md-12 main-blck">
                    <div className="right-top-blck ">
                      <div className="new-blck">
                        <div className="content">
                          <h3 className="title">Expenses breakdown</h3>
                        </div>
                        <div className="right">
                          <h3 className="per-head bold dark-blue">${selectedExpensesBreakdown.amount}</h3>
                        </div>
                      </div>
                      <div className="chart-section-vertical">
                        <ul className="bar-chart-budget">
                          {expensesBreakdownFilterData &&
                            expensesBreakdownFilterData?.length > 0 &&
                            expensesBreakdownFilterData.map((item, index) => (
                              <li
                                key={index}
                                className={
                                  item.label === selectedExpensesBreakdown.label ? 'power-wifi-bar' : 'bar-budget'
                                }
                                style={{ height: `${item.value}px`, width: `${item.width}px` }}
                                onClick={() => {
                                  onHandleSelectedExpensesBreakdown(item.label, item.value);
                                }}
                              >
                                <span className="label-budget">{item.label}</span>
                              </li>
                            ))}
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className="row mt-4">
                <div className="col-md-12 col-lg-6 bar-cart recent-transaction-wrapper">
                  <div className="recent-see-all">
                    <div className="col-md-6 mb-4 left">
                      <h5>Recent Transactions</h5>
                    </div>
                    <div className="col-md-6 right-section right see-all btn-hover">
                      <Link className="group-button green" to={`/Transactions/${uuid}`} style={{ fontSize: '13px' }}>
                        See All
                      </Link>
                    </div>
                  </div>
                  {lastFiveTransaction &&
                    lastFiveTransaction?.length > 0 &&
                    lastFiveTransaction.map((it) => (
                      <div className="sideblck">
                        <div className="merchnat-img">
                          <img
                            src={it.merchantLogo === '' || it.merchantLogo === null ? Countdown : it.merchantLogo}
                            alt="CountDown-img"
                          />
                          <div className="name-merchant bar-chart">
                            <h5>{truncateString(it.transactionName, 18)}</h5>
                            <h6>{it.TransactionCategory}</h6>
                          </div>
                        </div>
                        <div className="date-time-blck">
                          <h4>{moment(it.dateTime).format('DD/MM/YYYY')}</h4>
                          {/* <span>|</span>
                          <h4>{moment(it.dateTime).format('hh:mma')}</h4> */}
                        </div>
                        <div className="status-btn">
                          <div className="button-blck">
                            <div className="icon-tag">
                              {it.masterCategoryColour !== null && (
                                <div
                                  className="icon-transaction-img"
                                  style={{ backgroundColor: it.masterCategoryColour }}
                                >
                                  {it.masterCategoryEmoji}
                                </div>
                              )}
                              <div className="content">
                                <h6 className="icon-content ">{it.masterCategoryName}</h6>
                              </div>
                            </div>
                          </div>
                        </div>

                        <div
                          className={`transaction-amt inter ${
                            it.transactionCategory.toLowerCase() === 'dr' ? 'light-red-trans' : 'light-green-trans'
                          }`}
                        >
                          {it.transactionCategory.toLowerCase() === 'dr'
                            ? `${formatAmount(Number(it.amount) ?? 0)}`
                            : `${formatAmount(Number(it.amount) ?? 0)}`}
                        </div>
                      </div>
                    ))}
                </div>
                <div className="col-md-12 col-lg-6 bar-cart cashflocart weekly-cahflowcart">
                  <div className="col-md-6 mb-4 left">
                    <h5>Weekly Cashflow Forecast</h5>
                  </div>

                  <div className="line-cart-net">
                    <div className="bar-cart-heading-wrp col-lg-12 col-md-12">
                      <div className="top-heading-canvas-price">
                        <h4 className="bold black">Weekly Cashflow</h4>
                        <h5>$370</h5>
                      </div>
                      <h6>Week 5 - 12/11/2022</h6>
                    </div>
                    <CashFlowChart cashFlow={cashFlowChartData} />
                  </div>
                </div>
              </div>
              <Networthprogress uuid={uuid} data={data} />
              <div className="row mt-4">
                <div className="recent-see-all">
                  <div className="col-md-6 inline left">
                    <h5 className="bold">Merchants</h5>
                  </div>
                </div>
                <div className="col-md-2 col-sm-2 col-lg-2 categories-block mt-4 dashbord-merchant-box">
                  <div className="categorised dashbord-merchant-box ml-4">
                    <div className="categorised-icon">
                      <People />
                    </div>
                    <div className="text-light text-center category-text">Categorised</div>
                    <div className="fs-1 fw-bold text-light text-center">{categorizedMerchantsCount}</div>
                  </div>
                </div>
                <div className="col-md-2 col-sm-2 col-lg-2 categories-block m-4 dashbord-merchant-box">
                  <div className="uncategorised dashbord-merchant-box">
                    <div className="uncategorised-icon">
                      <People />
                    </div>
                    <div className="text-light text-center category-text">Uncategorised</div>
                    <div className="fs-1 fw-bold text-light text-center">{unCategorizedMerchantsCount}</div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
export default Dashboardinner;
