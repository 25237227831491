import { useState } from "react";
import { isEmpty } from "lodash";
import Amount from "../Amount.jsx";
import Logo from "../../assests/images/icons/Black Logo 1.png";
import "./Moneyinsights.css";

export const AccordionMoneyInsights = ({
  avatar,
  accountName,
  value,
  accountNumber,
  details,
  isOpen,
  onToggle,
}) => {
  return (
    <div
      className={`accordion-item ${isOpen ? "open" : ""}`}
      onClick={onToggle}
    >
      <div className="accordion-header">
        <div>
          <img
            src={!isEmpty(avatar) ? avatar : Logo}
            alt=""
            className="account-img"
          />
        </div>
        <div className="account-text">
          <div className="account-name">{accountName}</div>
          <div className="account-subtext">{accountNumber}</div>
        </div>
        <div className="account-value">
          <Amount amount={value} />
        </div>
      </div>

      {/* Accordion content */}
      <div
        className={`accordion-content ${isOpen ? "expanded" : ""}`}
      >
        <div className="accordion-inner">
          {details.map((detail, idx) => (
            <div key={idx} className="detail-item">
              <span>{detail.label}</span>
              <span>${detail.value.toFixed(2)}</span>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};
