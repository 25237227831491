import EmojiPicker from "emoji-picker-react";
import React, { useState } from "react";
import { useForm, Controller } from "react-hook-form";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import Cinput from "../Cinput";
import SectionTitle from "../SectionTitle.jsx";
import InfoCard from "../InfoCard/index.jsx";

const schema = yup.object().shape({
  assetsName: yup.string().required("Name is required"),
  value: yup
    .number()
    .positive("Value must be positive")
    .required("Value is required"),
});

const AssetLiabilityDetail = ({
  amount,
  name,
  value,
  emoji,
  elementType,
  setIsLinkedToAccount,
  isLinkedToAccount,
  handleEmojiClick,
  showEmojiPicker,
  setShowEmojiPicker,
  chosenEmoji,
  handleOnSubmitForUpdating,
  onSubmitHandler,
  deletAssetOrLiability,
}) => {
  const {
    control,
    handleSubmit,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
    defaultValues: {
      assetsName: name,
      value: value,
    },
  });

  const onSubmit = (data) => {
    console.log(elementType, "elementytoo");
    if (elementType === "asset" || elementType === "liability") {
      onSubmitHandler(data);
    } else handleOnSubmitForUpdating(data);
  };
  return (
    <div className="col-xl-6">
      <form onSubmit={handleSubmit(onSubmit)}>
        <SectionTitle title={"Enter details"} />
        {(elementType==="editAsset" || elementType==="editLiability") && (
          <InfoCard
          amount={amount}
          avatar={emoji}
          name={name}
          isEmoji={true}
          />
        )}
        <div className="edit-goal-box d-flex justify-content-between align-items-center details-mt">
          <div className="fw-bold inter">Link to an account</div>
          <div className="account-input">
            {isLinkedToAccount ? (
              <div
                className="link-account cursor-pointer"
                onClick={() => setIsLinkedToAccount(!isLinkedToAccount)}
              >
                Yes
              </div>
            ) : (
              <div
                className="not-link-account cursor-pointer"
                onClick={() => setIsLinkedToAccount(!isLinkedToAccount)}
              >
                No
              </div>
            )}
          </div>
        </div>
        <div className="edit-goal-box">
          <div className="d-flex align-items-center justify-content-between">
            <div className="fw-bold inter">Name</div>
            <Controller
              name="assetsName"
              control={control}
              render={({ field }) => (
                <Cinput {...field} type="text" width={"226px"} />
              )}
            />
          </div>
          {errors.assetsName && (
            <p className="error-message">{errors.assetsName.message}</p>
          )}
          <div className="d-flex align-items-center justify-content-between mt-3">
            <div className="fw-bold inter mt-1">Value</div>
            <Controller
              name="value"
              control={control}
              render={({ field }) => (
                <Cinput {...field} type="number" width={"226px"} />
              )}
            />
          </div>
          {errors.value && (
            <p className="error-message">{errors.value.message}</p>
          )}
          <div className="d-flex align-items-center justify-content-between mt-3">
            <div className="fw-bold inter mt-1">Emoji</div>
            <div
              className="py-2 inter add-goal emoji-input-width show-emoji"
              onClick={() => setShowEmojiPicker(!showEmojiPicker)}
            >
              {chosenEmoji}
            </div>
          </div>
          {showEmojiPicker && (
            <div className="emoji-container">
              <EmojiPicker
                onEmojiClick={handleEmojiClick}
                style={{ height: "340px", width: "369px" }}
              />
            </div>
          )}
        </div>
        {elementType === "asset" || elementType === "liability" ? (
          <div className="profile-dashboard mt-3 ms-0 add-asset-btn-first">
            <button
              className={`add-submit-btn ${
                elementType === "asset" ? "bg-green" : "bg-red"
              }`}
            >
              Add {elementType}
              <i class="fa-solid fa-plus"></i>
            </button>
          </div>
        ) : (
          <div className="d-flex mt-3">
            <button
              type="submit"
              className="edit-submit-btn cursor-pointer bg-green"
            >
              Save&nbsp;changes
            </button>
            <button
              type="submit"
              className="edit-submit-btn cursor-pointer bg-red me-4"
              onClick={deletAssetOrLiability}
            >
              Delete&nbsp;{elementType === "editAsset" ? "asset" : "liability"}
            </button>
          </div>
        )}
      </form>
    </div>
  );
};

export default AssetLiabilityDetail;
