import React from 'react';
import Amount from "../Amount.jsx/index"
import Logo from "../../assests/images/icons/Black Logo 1.png";
import { useNavigate, useParams } from 'react-router-dom';
import { isEmpty } from 'lodash';
export const AkahuAccountCard = ({ avatar, accountName, value, accountNumber ,accountId}) => {
  const navigate = useNavigate();
  const { uuid } = useParams();

  
  const handleClick = () => {
    navigate(`/AccountDetails/${uuid}`, {
      state: { avatar, accountName, value, accountNumber, accountId }
    });
  };
  return (
    <div className="merchant-box account-box" onClick={handleClick} style={{width:"49%"}}>
      <div className="d-flex align-items-center" style={{ gap: '12px' }}>
        <div>
          <img
            src={!isEmpty(avatar) ? avatar : Logo}
            alt=""
            className='account-img'
          />
        </div>
        <div className="lcs-inner account-text ">
          <div className="live-connection">{accountName}</div>
          <div className="setting_text_color-2">
            {accountNumber}
            </div>
        </div>
      </div>
      <div className="account-number"><Amount amount={value}/></div>
    </div>
  );
};
