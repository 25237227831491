import React from 'react'

const NoDataFound = () => {
  return (
    <div className="d-flex justify-content-center w-100">
    No data found
  </div>
  )
}

export default NoDataFound