import React from "react";
import { Popover, PopoverBody } from "reactstrap";

const IncomeExpenseCategories = ({
  togglePopover,
  isOpen,
  mainHead,
  handleCategoryClick,
  defaultCategory,
  yourCreatedCategory,
  handleChange
}) => {
  return (
    <Popover
      placement="bottom"
      isOpen={isOpen}
      target="PopUpoverTarget"
      toggle={togglePopover}
      className="custom-popoverr"
    >
      <PopoverBody>
        <div className="transaction-categories mt-3">
          <div className="bold popover-live">{mainHead} Categories</div>
          <button onClick={() => togglePopover()} className="close-pass-button">
            <i class="fa fa-times"></i>
          </button>
          <div
            className="bold popover-live mt-3 mb-2"
            style={{ fontSize: "14px" }}
          >
            Default categories
          </div>

          <div className="categories group-btn green-btn">
            {defaultCategory?.map((category) => (
              <div
                key={category.categoryId}
                className="button-blck"
                onClick={() => handleCategoryClick(category)}
              >
                <div className="icon-tag">
                  <div
                    className="icon-transaction-img"
                    style={{ backgroundColor: category.categoryColour }}
                  >
                    {category.categoryEmoji}
                  </div>
                  <div className="content">
                    <h6 className="icon-content">{category.categoryName}</h6>
                  </div>
                </div>
              </div>
            ))}
          </div>
          <div className="bold popover-live mb-2" style={{ fontSize: "14px" }}>
            Your created categories
          </div>
          <div className="categories group-btn green-btn">
            {yourCreatedCategory?.map((category) => (
              <div
                key={category.categoryId}
                className="button-blck"
                onClick={() => handleCategoryClick(category)}
              >
                <div className="icon-tag">
                  <div
                    className="icon-transaction-img"
                    style={{ backgroundColor: category.categoryColour }}
                  >
                    {category.categoryEmoji}
                  </div>
                  <div className="content">
                    <h6 className="icon-content">{category.categoryName}</h6>
                  </div>
                </div>
              </div>
            ))}
            <button
              className="new-category-btn green"
              onClick={handleChange}
            >
              + New category
            </button>
          </div>
        </div>
      </PopoverBody>
    </Popover>
  );
};

export default IncomeExpenseCategories;
