import { yupResolver } from "@hookform/resolvers/yup";
import axios from "axios";
import React, { useState } from "react";
import "react-datepicker/dist/react-datepicker.css";
import { useForm } from "react-hook-form";
import { toast } from "react-toastify";
import * as yup from "yup";
import weburls from "../../Weburls/weburls";
import "../AddIncome/addIncome.css";
import EmojiPicker from "emoji-picker-react";

import { ConnectedAccounts } from "../../ConnectedAccountsCard";
import { isEmpty } from "lodash";
const schema = yup.object().shape({
  assetsName: yup.string().required("*Please enter assets name"),
  value: yup
    .string()
    .required("*Please enter asset value")
    .matches(/^[0-9]+$/, "*Please enter number only"),
});

const AddAssets = ({
  modalOpen,
  closeModal,
  fetchAssets,
  uuid,
  modalTitle,
  modalName,
  fetchLiability,
  accountData,
}) => {
  const [assetData, setAssetData] = useState({
    userUuid: "",
    assetsName: "",
    value: "",
    amount: "",
    assetImage: "",
    assetAvatar: "🏡",
  });
  const [selectedAccounts, setSelectedAccounts] = useState([]);
  const [isLinkedToAccount, setIsLinkedToAccount] = useState(true);
  const [showEmojiPicker, setShowEmojiPicker] = useState(false);

  const [chosenEmoji, setChosenEmoji] = useState("😁");
  const handleChange = (e) => {
    const { name, value } = e.target;
    setAssetData({ ...assetData, userUuid: uuid, [name]: value });
  };

  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm({
    resolver: yupResolver(schema),
  });

  const handleEmojiClick = (event) => {
    const { emoji } = event;
    setChosenEmoji(emoji);
    setShowEmojiPicker(false);
  };

  const onSubmitHandler = () => {
    if (modalTitle === "asset") {
      addNewAsset();
    } else {
      addNewLiability();
    }
  };

  const handleToggleSwitch = (data) => {
    const { accountId } = data;
    setSelectedAccounts((prevAccounts) => {
      if (prevAccounts.includes(accountId)) {
        return prevAccounts.filter((id) => id !== accountId);
      } else {
        return [...prevAccounts, accountId];
      }
    });
  };

  const addNewAsset = async () => {
    try {
      const data = {
        userUuid: uuid,
        assetsName: assetData.assetsName,
        value: assetData.value,
        emoji: chosenEmoji,
        isConnected: isEmpty(selectedAccounts) ? false : true,
        accounts: [...selectedAccounts],
      };
      const response = await axios.post(weburls.Add_Assets, data);
      if (response.status === 200) {
        toast.success(response.data.message, {
          position: "top-right",
          autoClose: 2000,
        });
        closeModal(modalName);

        fetchAssets();
      } else if (response.status === 404) {
        toast.error(`Something went wrong`, {
          position: "top-right",
          autoClose: 2000,
        });
      }
    } catch (error) {
      closeModal(modalName);
      toast.error("Something went wrong please try again.", {
        position: "top-right",
        autoClose: 2000,
      });
    }

    reset();
  };
  const addNewLiability = async () => {
    try {
      const data = {
        userUuid: uuid,
        liabilityName: assetData.assetsName,
        value: assetData.value,
        emoji: chosenEmoji,
        isConnected: isEmpty(selectedAccounts) ? false : true,
        accounts: [...selectedAccounts],
      };
      const response = await axios.post(weburls.Add_Liability, data);
      if (response.status === 200) {
        toast.success(response.data.message, {
          position: "top-right",
          autoClose: 2000,
        });
        closeModal(modalName);

        fetchLiability();
      } else if (response.status === 404) {
        toast.error(`Something went wrong`, {
          position: "top-right",
          autoClose: 2000,
        });
      }
    } catch (error) {
      closeModal(modalName);
      toast.error("Something went wrong please try again.", {
        position: "top-right",
        autoClose: 2000,
      });
    }

    reset();
  };

  return (
    <div className={`modal-background ${modalOpen ? "open-modal" : ""} `}>
      <div className="modal-content networth-modal-width">
        <button
          onClick={() => closeModal(modalName)}
          className="close-pass-button"
        >
          <i className="fa fa-times"></i>
        </button>
        <form onSubmit={handleSubmit(onSubmitHandler)}>
          <>
            <div className="asset-detail">
              <div className="d-flex justify-content-between">
                <div className="fs-3">
                  {modalName === "addAsset" ? (
                    <span className="bold black password-heading">
                      Add an {modalTitle}
                    </span>
                  ) : (
                    <span className="bold black password-heading">
                      Add a {modalTitle}
                    </span>
                  )}
                  <p className="trans-modal-para">
                    Enter in all your {modalTitle} details below.
                  </p>
                </div>
                <button
                  type="submit"
                  className="add-transaction-btn cursor-pointer me-4"
                >
                  Add&nbsp;{modalTitle}
                </button>
              </div>
              <div className="black account-bal-title mt-4">Enter Details</div>
              <div className="edit-goal-box d-flex justify-content-between align-items-center">
                <div className="fw-bold inter">Link to an account</div>
                <div className="account-input">
                  {isLinkedToAccount ? (
                    <div
                      className="link-account cursor-pointer"
                      onClick={() => setIsLinkedToAccount(!isLinkedToAccount)}
                    >
                      Yes
                    </div>
                  ) : (
                    <div
                      className="not-link-account cursor-pointer"
                      onClick={() => setIsLinkedToAccount(!isLinkedToAccount)}
                    >
                      No
                    </div>
                  )}
                </div>
              </div>
              <div className="edit-goal-box">
                <div className="d-flex align-items-center justify-content-between">
                  <div className="fw-bold inter">Name</div>
                  <div className="d-flex flex-column justify-content-center">
                    <input
                      type="text"
                      name="contribution"
                      onChange={handleChange}
                      {...register("assetsName", {
                        onChange: (e) => {
                          handleChange(e);
                        },
                      })}
                      className="edit-goal-house py-2 inter add-goal"
                      style={{ border: "none", width: "266px" }}
                    />
                    <span className="error-message">
                      {errors.assetsName?.message}
                    </span>
                  </div>
                </div>

                <div className="d-flex align-items-center justify-content-between mt-3">
                  <div className="fw-bold inter mt-1">Value</div>
                  <div className="d-flex flex-column justify-content-center">
                    <input
                      type="number"
                      name="contribution"
                      onChange={handleChange}
                      {...register("value", {
                        onChange: (e) => {
                          handleChange(e);
                        },
                      })}
                      className="edit-goal-house py-2 inter add-goal"
                      style={{ border: "none", width: "266px" }}
                    />
                    <span className="error-message">
                      {errors.value?.message}
                    </span>
                  </div>
                </div>

                <div className="d-flex align-items-center justify-content-between mt-3">
                  <div className="fw-bold inter mt-1">Emoji</div>
                  <div
                    className="py-2 inter add-goal w-50 show-emoji"
                    onClick={() => setShowEmojiPicker(!showEmojiPicker)}
                  >
                    {chosenEmoji}
                  </div>
                </div>
              </div>
              {showEmojiPicker && (
                <div className="emoji-container">
                  <EmojiPicker
                    onEmojiClick={handleEmojiClick}
                    style={{ height: "340px", width: "369px" }}
                  />
                </div>
              )}
            </div>
            {isLinkedToAccount && (
              <div>
                <div
                  className="bold black sub-head-text inter mt-3"
                  style={{ maginTop: "10px", paddingLeft: "33px" }}
                >
                  Link to an account
                </div>
                <div className="asset-accounts-container">
                  <div className="d-flex flex-wrap link-account-gap">
                    {accountData?.map((ele, index) => (
                      <ConnectedAccounts
                        key={index}
                        avatar={ele?.avatar}
                        accountName={ele?.accountName}
                        value={ele?.value}
                        accountId={ele?.accountId}
                        handleToggleSwitch={handleToggleSwitch}
                        id={ele?.accountId}
                        selectedAccount={selectedAccounts}
                      />
                    ))}
                  </div>
                </div>
              </div>
            )}
          </>
        </form>
      </div>
    </div>
  );
};

export default AddAssets;
